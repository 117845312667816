import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Pin from "../Components/OTP/Pin";
import { ThreeDots } from "react-loader-spinner";

const LoginInput = (props) => {
  const [show, setShow] = useState(false);

  const Type = (Type) => {
    switch (Type) {
      case "Login":
        return (
          <>
            {" "}
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-md space-y-8">
                <div>
                  <img
                    className="mx-auto h-12 w-auto"
                    src="../assets/Stockone_indigo.svg"
                    alt="StockOne"
                  />
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Sign in to your account
                  </h2>
                </div>
                <form
                  className="mt-8 space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.mobilenumValidator()
                      ? props.loginApi()
                      : console.log("Something went wrong!");
                  }}
                >
                  <div className="-space-y-px rounded-md ">
                    <input
                      type="text"
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder={props.placeholder}
                      onChange={props.onChangeMobile}
                      value={props.value}
                      maxLength="10"
                    />
                    <span className=" p-1 flex items-center font-medium tracking-wide text-red-500 text-xs m-2">
                      {props.error.mobile}
                    </span>

                    <div className="flex items-center relative">

                      <input
                        type={show ? "text" : "password"}
                        className=" block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Password"
                        onChange={props.onChangePassword}
                        value={props.password}
                      />
                      {
                        show ?
                          <span onClick={() => setShow(!show)}
                            className="absolute right-0 cursor-pointer inline-flex z-10 items-center px-3 text-sm text-gray-900 ">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 16 16"
                              className="h-[20px] w-[23px]  "
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"></path><path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"></path><path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"></path></svg>

                          </span>
                          :
                          <span onClick={() => setShow(!show)} className="absolute right-0 z-10 cursor-pointer inline-flex items-center px-3 text-sm text-gray-900  ">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"

                              className="h-[20px] w-[23px]"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg>

                          </span>
                      }

                    </div>

                    <div className=" p-1 flex items-center font-medium tracking-wide text-red-500 text-xs m-2">
                      {props.error.password}
                    </div>


                  </div>

                  {props.nodata && (
                    <div
                      className="p-2 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                      role="alert"
                    >
                      <span className="font-medium"> {props.nodata}!</span>
                    </div>
                  )}
                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {/* <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockClosedIcon
                          className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                          aria-hidden="true"
                        />
                      </span> */}
                      {props.loading ? (
                        <ThreeDots
                          height="20"
                          width="40"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                        />
                      ) : (
                        <span>Sign in</span>
                      )}
                    </button>
                  </div>

                  <div className="text-sm text-right">
                    <Link
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      to="/forget-password"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </>
        );

      case "forgot password":
        return (
          <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-md space-y-8">
                <div>
                  <img
                    className="mx-auto h-12 w-auto"
                    src="../assets/Stockone_indigo.svg"
                    alt="StockOne"
                  />
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Forgot Password
                  </h2>
                </div>
                <form
                  className="mt-8 space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.mobilenumValidator()
                      ? props.forgotApi()
                      : console.log("Something went wrong");
                  }}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm   font-bold text-gray-700"
                    >
                      Enter your Mobile number
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={props.onChangeMobileNum}
                        className="block w-full rounded-md border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Enter your 10 digit valid mobile number"
                        maxLength={10}
                        value={props.mobileNum}
                      />
                    </div>
                    <span className=" flex text=start   tracking-wide text-red-600  m-1">
                      {props.error.mobileNum}
                    </span>
                  </div>

                  {props.successMsg && (
                    <div
                      className="p-2 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                      role="alert"
                    >
                      <span className="font-medium"> {props.successMsg}</span>
                    </div>
                  )}

                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        );
      case "Verification code":
        return (
          <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-md space-y-8 border-solid border-2 w-50 rounded p-6 text-center outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md">
                <div>
                  <img
                    className="mx-auto h-12 w-auto"
                    src="../assets/Stockone_indigo.svg"
                    alt="StockOne"
                  />
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Forgot Password?
                  </h2>
                </div>
                <form
                  className="mt-8"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.formValidation()
                      ? props.ResetPassword()
                      : console.log("Something went wrong");
                  }}
                >
                  <div>
                    <label className="block text-sm   font-bold text-gray-700">
                      Enter OTP sent on your mobile number
                    </label>
                    <div className="mt-1">
                      <Pin
                        inputLength={4}
                        setOtpHandler={props.setOtpVerification}
                        setSuccessMsg={props.setSuccessMsg}
                        errorOtpVerification={props.errorOtpVerification}
                        error={props.error}
                      /*  setOtpHandler={(value) => {
                                               setOtp(value);
                                               otpVerification
                                               props.OtpVerification()
                                           }} */
                      ></Pin>
                    </div>
                  </div>
                  {props.errorOtpVerification && (
                    <span className="font-medium text-red-500 text-xs">
                      {props.errorOtpVerification}
                    </span>
                  )}

                  <label
                    className="block text-[12px]  mt-6 text-end cursor-pointer font-bold text-gray-700"
                    onClick={() => props.resendOtp()}
                  >
                    <a className="hover:underline  hover:text-blue-600">
                      Resend Code
                    </a>
                  </label>

                  <label
                    className="block text-[12px]   text-end cursor-pointer font-bold text-gray-700"
                    onClick={() => props.resendOtp()}
                  >
                    <a className="hover:underline  hover:text-blue-600">
                      {props.otpSuccessMessage()}
                    </a>
                  </label>

                  <div>
                    <label className="block text-sm  mt-6 text-start font-bold text-gray-700">
                      Create a new Password
                    </label>
                    <div className="mt-1">
                      <input
                        className="block w-full rounded-md border-gray-300 p-2 border outline-none   shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={props.newPassword}
                        onChange={props.onChangeNewPass}
                      />
                      {props.errorNewPassword && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {props.errorNewPassword}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm  mt-6 text-start font-bold text-gray-700">
                      Confirm new Password
                    </label>
                    <div className="mt-1">
                      <input
                        className="block w-full rounded-md border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={props.confirmNewPassword}
                        onChange={props.onChangeConfirmPass}
                      />
                      {props.errorConfirmNewPassword && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {props.errorConfirmNewPassword}
                        </span>
                      )}
                    </div>

                    {props.successMsg && (
                      <div
                        className="p-2 mb-4 mt-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                        role="alert"
                      >
                        <span className="font-medium"> {props.successMsg}</span>
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center mt-8 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          </>
        );



      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{Type(props.Type)}</div>;
};

export default LoginInput;
