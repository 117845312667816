import React, { useState } from 'react';
import Button from '../tailwindUI/Button';

const DateFilter = ({setStart,setEnd,setTabType,page}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearchClick = () => {
if(page==="allocationawaited"){
  setTabType("AllToday's Booking")
}
setStart(startDate)
setEnd(endDate)
  };

  return (
    <div className='flex w-full flex-col justify-between sm:flex-row gap-4 border p-2 rounded'>
      <div className='flex gap-4'>
        <div>
          <label className='text-gray-600'>Start Date</label>
          <input
            type='date'
            className='mt-1 sm:mt-0 ml-2 border rounded text-sm text-gray-500 px-2 py-1'
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div>
          <label className='text-gray-600'>End Date</label>
          <input
            type='date'
            className='mt-1 sm:mt-0 ml-2 border rounded text-sm text-gray-500 px-2 py-1'
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <Button
        type={"smallButton"}
        buttonText={"Search"}
        onClick={handleSearchClick}
      />
    </div>
  );
}

export default DateFilter;
