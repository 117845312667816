import React from 'react'
import Button from '../tailwindUI/Button'
import Modal from '../tailwindUI/Modal'

const ConfermationModal = (props) => {
  return (
    <Modal setOpen={props.setOpen} open={props.open} type="modal theme">
      <div className="mt-3 p-6 font-semibold text-base"> Are you sure, you want to remove? </div>
      <div className=" mt-4 flex  flex-row-reverse">
        <Button buttonText={"Continue"} loading={props.loading === "remove" ? true : false} onClick={props.onClick} type={"redFilled"} />
        <div className="mr-4">
          <Button buttonText={"Cancel"} onClick={props.onClose} type={"whiteButton"} />
        </div>
      </div>
    </Modal>
  )
}

export default ConfermationModal