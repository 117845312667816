import React from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/StmAllocationStatus.module.css";
import AllocationStatusGrid from "../../tailwindUI/AllocationStatusGrid";
const StmAllocationStatusReport = () => {

    const BreadCrumb = {
        pagename: "Allocation Status",
        pagePath: "/stm-allocation-status-report",
        pagename1: "",
        pagePath1: "",
    };
    return (
        <>
            <Navbar navbarType={"black navbar"} />
            <div className={classes.maincontainer}>
                <Heading
                    headingType={"Page Headings"}
                    headingText={"Allocation Status Report"}
                    // subheadingText={localStorage.getItem("Location")}
                    BreadCrumb={BreadCrumb}
                    BreadCrumHomeLink={"/stm-dashboard"}
                />
                <div className={classes.background}>
                    <AllocationStatusGrid />
                </div>
            </div>
        </>
    )
}

export default StmAllocationStatusReport