import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import LoginInput from "../tailwindUI/LoginInput";

const ForgetPassword = () => {
  const [mobileNum, setMobileNum] = useState("");
  const [error, setError] = useState({});
  const [successMsg, setSuccessMsg] = useState('');

  let navigate = useNavigate();

  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setMobileNum(e.target.value)
      : console.log("Please enter correct mobile number");
  };

  const mobilenumValidator = () => {
    let isValid = true;
    let errors = {};
    if (mobileNum.length < 10) {
      errors.mobileNum = "Please enter correct mobile number";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const forgotApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Mobile: mobileNum,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/common/ResendOtp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "SUCCESS" &&
          result.message === "OTP sent successfully"
        ) {
          navigate("/create-password");
          localStorage.setItem('Phone', mobileNum);
        } else if (
          result.status_code === 300
        ) {
          setSuccessMsg(result.Remark)
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>

      <LoginInput
        Type="forgot password"
        onChangeMobileNum={(e) => { onlyNumericMobile(e); error.mobileNum = ""; setSuccessMsg('') }

        }
        forgotApi={forgotApi}
        error={error}
        mobilenumValidator={mobilenumValidator}
        mobileNum={mobileNum}
        successMsg={successMsg}
      />
    </div>
  );
};

export default ForgetPassword;
