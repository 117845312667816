import React, { useState, useEffect } from "react";
import SmWrapper from "../../Components/SmWrapper";
import classes from "../../module.css/UpdateBooking.module.css";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import Button from "../../tailwindUI/Button";
import Modal from "../../tailwindUI/Modal";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import { useNavigate, useParams } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
const UpdateBooking = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [physicalDate, setPhysicalDate] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [message, setMessage] = useState("");
  const [cdd, setCdd] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [filteredList1, setFilteredList1] = useState([]);
  const [filteredList2, setFilteredList2] = useState([]);
  const [yom, setYom] = useState("");
  const [selectedModel1, setSelectedModel1] = useState("");
  const [selectedVariant1, setSelectedVariant1] = useState("");
  const [selectedColor1, setSelectedColor1] = useState("");
  const [selectedModel2, setSelectedModel2] = useState("");
  const [selectedVariant2, setSelectedVariant2] = useState("");
  const [selectedColor2, setSelectedColor2] = useState("");
  const [allocatedPref, setAllocatedPref] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [commitedDate, setCommitedDate] = useState("");
  const [amount, setAmount] = useState("");
  const [recieptNo, setRecieptNo] = useState("");
  const [reciept, setReciept] = useState("");
  const [asm, setAsm] = useState("");
  const [executive, setExecutive] = useState("");
  const [status, setStatus] = useState("");
  const [statusDate, setStatusDate] = useState("");
  const [vinNumber, setVinNumber] = useState("");
  const [remark, setRemark] = useState("");
  const [followUpOne, setFollowUpOne] = useState("");
  const [followUpTwo, setFollowUpTwo] = useState("");
  const [followUpThree, setFollowUpThree] = useState("");
  const [executiveData, setExecutiveData] = useState([]);
  const [asmData, setAsmData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [markDelivery, setMarkDelivery] = useState("");
  const [carStatus, setCarStatus] = useState("");
  const [defaultAsm, setDefaultAsm] = useState("");
  const [defaultExecutive, setDefaultExecutive] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [markError, setMarkError] = useState({});
  const [exchange, setExchange] = useState("");
  const [checkedPriority, setCheckedPriority] = useState([]);
  const [gatePassNumber, setGatePassNumber] = useState("");
  const [wings, setWings] = useState("");
  const [srmRemark, setSrmRemark] = useState("")
  const checkedPriorityInString = checkedPriority
    .map((item) => item.name)
    .toString();


  useEffect(
    () => FilterList(selectedModel1, setFilteredList1),
    [selectedModel1]
  );
  useEffect(
    () => FilterList(selectedModel2, setFilteredList2),
    [selectedModel2]
  );
  useEffect(() => {
    ExecutiveList();
    AsmList();
    BookingDetail();
  }, [asm]);
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const ExecutiveList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      AsmId: asm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMExicutivesList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setExecutiveData(result.data);
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };
  const AsmList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMASMList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status == "Success") {
          setAsmData(result.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };
  const FilterList = (model, setFilteredList) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Model: model,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/common/GetFilterList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setFilteredList(result.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };
  const BookingDetail = () => {
    setLoading("update");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: bookingId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setSelectedModel1(result.data.car_model);
          setSelectedVariant1(result.data.car_variant);
          setSelectedColor1(result.data.car_colour);
          setSelectedModel2(result.data.CarModel2);
          setSelectedVariant2(result.data.CarVariant2);
          setSelectedColor2(result.data.CarColour2);
          setCarStatus(result.data.carstatus);
          setFirstname(result.data.customer_name);
          setLastName(result.data.LastName);
          setStatusDate(result.data.Statusdate?.slice(0, 10));
          setMobile(result.data.customer_mobile);
          setCommitedDate(result.data.CommittedDeliveryDate);
          setCdd(result.data.CommittedDeliveryDate);
          setAmount(result.data.booking_amount);
          setBookingDate(result.data.booking_date);
          setReciept(result.data.UploadReceipt);
          setRecieptNo(result.data.receipt_num);
          setVinNumber(result.data.win_num);
          setStatus(result.data.status);
          setYom(result.data.yom);
          setWings(result.data.WingsId);
          setExchange(result.data.exchange);
          setDefaultAsm(result.data.AsmName);
          setRemark(result.data.Remark);
          setFollowUpOne(result.data.followup1);
          setFollowUpTwo(result.data.followup2);
          setFollowUpThree(result.data.followup3);
          setDefaultExecutive(result.data.ExecutiveName);
          setPhysicalDate(result.data.PhysicalDate);
          setCheckedPriority(
            result.data.CustomerSpecialNeed.split(",")?.map((item, index) => {
              return {
                name: item,
              };
            })
          );
          if (
            result.data.car_model === result.data.CSModel &&
            result.data.car_colour === result.data.CSColour &&
            result.data.car_variant === result.data.CSVarient
          ) {
            setAllocatedPref(1);
          } else if (
            result.data.CarModel2 === result.data.CSModel &&
            result.data.CarColour2 === result.data.CSColour &&
            result.data.CarVariant2 === result.data.CSVarient
          ) {
            setAllocatedPref(2);
          } else if (result.status_code === 400) {
            setErrorMessage("Something went wrong please contact support !");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const deliveryDateValidation = () => {
    let error = {};
    let isValid = true;
    if (markDelivery === "") {
      error.markDelivery = "Please select date";
      isValid = false;
    }
    if (gatePassNumber.trim() === "") {
      error.gatePassNumber = "Please enter Gate Pass";
      isValid = false;
    }
    setMarkError(error);
    return isValid;
  };
  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!reason || reason === "") {
      errors.reason = "Please select  reason of cancellation";
      isValid = false;
    } else if (reason === "Other" && otherReason === "") {
      errors.otherReason = "Please enter reason of cancellation";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const validation = () => {
    const yomregex = /^[0-9\b]+$/;
    let regPhone = /^[6789][0-9]{9}$/;
    let errors = {};
    let isValid = true;
    if (firstname.trim() === "") {
      isValid = false;
      errors.firstname = "Please enter first name";
    }
    if (lastname.trim() === "") {
      isValid = false;
      errors.lastname = "Please enter last name";
    }
    if (!commitedDate || commitedDate === "") {
      isValid = false;
      errors.commitedDate = "Please enter commited delivery date";
    }
    if (!regPhone.test(mobile)) {
      isValid = false;
      errors.mobile = "Please enter valid mobile number";
    }
    if (mobile.trim() === "") {
      isValid = false;
      errors.mobile = "Please enter mobile number";
    }
    var days = 7;
    var commitDate = new Date(commitedDate);
    var currentDate = new Date(cdd);
    var bookingdate = new Date(bookingDate);

    var last = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000);

    if (commitDate < bookingdate) {
      errors.commitedDate =
        "Committed date cannot be earlier than Booking Date";
      window.scrollTo(0, 150);
      isValid = false;
    }
    if ((asm === "" || !asm) && (defaultAsm === "" || !defaultAsm)) {
      errors.asm = "Please select ASM";
      isValid = false;
    }
    if (!yom || yom === "") {
      isValid = false;
      errors.yom = "Please enter year of manufacturing";
    } else if (!yomregex.test(yom) || yom.length < 4) {
      isValid = false;
      errors.yom = "Please enter correct year of manufacturing";
    } else if (yom > new Date().getFullYear() + 1) {
      isValid = false;
      errors.yom = "Year of manufacture should be valid";
    }
    // if (wings === "") {
    //   isValid = false;
    //   errors.wings = "Please enter wings id";
    // }
    // if (checkedPriority.length < 1) {
    //   isValid = false;
    //   errors.checkedPriority = "Please select booking priority";
    //   window.scrollTo(0, 150);
    // }
    if (
      (executive === "" || !executive) &&
      (defaultExecutive === "" || !defaultExecutive)
    ) {
      errors.executive = "Please select Executive";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const markDeliveredApi = () => {
    if (deliveryDateValidation()) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserToken: UserToken,
        BookingID: bookingId,
        DeliveredOn: markDelivery,
        GetPassNumber: gatePassNumber,
        SRMRemark: srmRemark
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingMarkDelivered",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
            result.message === "Please login with a different account"
          ) {
            LogOut();
          }
          if (result.status_code == 200 && result.status == "Success") {
            setMarkDelivery("");
            setModalShow(false);
            setMessage("Your Mark Delivered request has been sent successfully.");
          } else if (result.status_code === 400) {
            setErrorMessage("Something went wrong please contact support !");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setErrorMessage("Something went wrong please contact support !");
        });
    }
  };
  const markDeliveredButtonShow = () => {
    var commitDate = new Date(commitedDate);
    var PhysicalDate = new Date(physicalDate);
    var lastCommitedDate = new Date(
      PhysicalDate.getTime() + 5 * 24 * 60 * 60 * 1000
    );
    var lastPhysicalDate = new Date(
      commitDate.getTime() + 2 * 24 * 60 * 60 * 1000
    );
    var currentDate = new Date();
    if (currentDate > lastCommitedDate && currentDate > lastPhysicalDate) {
      return true;
    } else {
      return false;
    }
  };
  const markDeliveredShow = markDeliveredButtonShow();
  const bookingPriorityList = [
    {
      name: "Full Payment",
    },

    {
      name: "Special Occasion",
    },

    {
      name: "Committed Date",
    },
    {
      name: "Owner Reference",
    },
    {
      name: "Other Reference",
    },
  ];
  const priorityCheckboxHandler = (option) => {
    const isChecked = checkedPriority.some((item) => item.name === option.name);
    if (isChecked) {
      setCheckedPriority(
        checkedPriority.filter((item) => item.name !== option.name)
      );
    } else {
      setCheckedPriority(checkedPriority.concat(option));
    }
  };
  const cancelReasons = [
    { id: 1, name: "Color not available" },
    { id: 2, name: "Bought from other dealer" },
    { id: 3, name: "Not interested" },
    { id: 4, name: "Other" },
  ];
  const CancelBooking = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      BookingID: bookingId,
      CancelledReason: reason === "Other" ? otherReason : reason,
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMMarkedCancel",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status_code == 200 && result.status == "Success" && result.message !== "Data not  Updated ") {
          setOpen(false);
          setApiFailled(false);
          setMessage("Booking cancelled successfully");
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setOpen(false);
          setApiFailled(true);
          setMessage("Data not updated");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };
  const UpdateBooking = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      BookingID: bookingId,
      FirstName: firstname,
      LastName: lastname,
      LastName: lastname,
      MobileNumber: mobile,
      Model: selectedModel1,
      Variant: selectedVariant1,
      Colour: selectedColor1,
      Model2: selectedModel2,
      Variant2: selectedVariant2,
      Colour2: selectedColor2,
      YOM: yom,
      DateOfBooking: bookingDate,
      CommittedDeliveryDate: commitedDate,
      BookingPriority: checkedPriorityInString,
      // Status: status,
      // VinNo: vinNumber,
      Exchange: "",
      ASM: asm,
      Executive: executive,
      // StatusDate: statusDate,
      Followup1: followUpOne,
      Remark: remark,
      Followup2: followUpTwo,
      Followup3: followUpThree,
      UserToken: UserToken,
      WingsId: wings,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingUpdated",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setApiFailled(false);
          window.scrollTo(0, 0);
          setMessage("Data updated successfully");
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          window.scrollTo(0, 0);
          setMessage("Something went wrong please contact support.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
      {
        !apiFailled && navigate("/sm-search-update");
      }
    }, 2000);
    return message;
  };
  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;

    reg.test(e.target.value) || e.target.value === ""
      ? setMobile(e.target.value)
      : console.log("");
    error.mobile = "";
  };
  const BreadCrumb = {
    pagename1: "Update booking",
    pagePath1: "/sm-update-booking/" + bookingId,
    pagename: "Bookings",
    pagePath: "/sm-search-update",
  };

  const checkStatus1 = () => {
    return (
      status === "Allocated" ||
      status === "AutoAllocationCancelled" ||
      status === "CarAllocated" ||
      status === "DeliveryRequested" ||
      status === "AllocatedButVinAwaited"
    );
  };
  const notificationMethods = [
    { id: "Yes", title: "Yes" },
    { id: "No", title: "No" },
  ];
  const checkStatus2 = () => {
    return status === "Delivered" || status === "Cancelled";
  };


  return (
    <SmWrapper>
      {message && (
        <SuccessMessage
          onClose={() =>
            !apiFailled ? navigate("/sm-search-update") : setMessage("")
          }
          apiFailled={apiFailled}
          message={successMessage()}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {open && (
        <Modal
          setOpen={setOpen}
          desc={"Are you sure you want to cancel the booking"}
          heading={"Cancel booking"}
          open={open}
          onCancel={() => setOpen(false)}
          onClick={() =>
            formValidation() ? CancelBooking() : console.log("err")
          }
          type="Simple alert"
        >
          <div className={classes.modalInputContainer}>
            <Input
              type={"Select Menus Simple native low height"}
              dropdownType="cancelreason"
              data={cancelReasons}
              label={"Reason"}
              error={error.reason}
              onInput={() => (error.reason = "")}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
            <p class="peer-invalid:visible text-red-500 font-light">
              {error.reason}
            </p>
            <div className={classes.selectInput}>
              {reason === "Other" && (
                <Input
                  type={"textarea"}
                  error={error.otherReason}
                  placeholder={"Explain the reason of cancellation in detail"}
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                />
              )}
              <p class="peer-invalid:visible text-red-500 font-light">
                {error.otherReason}
              </p>
            </div>
          </div>
        </Modal>
      )}
      {modalShow && (
        <Modal
          setOpen={setModalShow}
          open={modalShow}
          type="Simple alert"
          heading={"Mark Delivered"}
          onCancel={() => setModalShow(false)}
          desc={"Are you sure you want mark this car as delivered ?"}
          onClick={() => markDeliveredApi()}
        >
          <div className={classes.modalInput}>
            <div className="my-2">
              <Input
                type={"input with label low height"}
                inputType={"date"}
                label={"Date of Delivery"}
                onChange={(e) => setMarkDelivery(e.target.value)}
              />
              <span className="text-red-500"> {markError.markDelivery}</span>
            </div>

            <div className="my-2">
              <Input
                type={"input with label low height"}
                inputType={"text"}
                label={"Gate Pass Number"}
                onChange={(e) => {
                  setGatePassNumber(e.target.value);
                  markError.gatePassNumber = "";
                }}
                value={gatePassNumber}
              />
              <span className="text-red-500"> {markError.gatePassNumber}</span>
            </div>
            <div className="my-2">
              <Input
                type={"input with label low height"}
                inputType={"text"}
                label={"SRM Remark (Optional)"}
                onChange={(e) =>
                  setSrmRemark(e.target.value)
                }
                value={srmRemark}
              />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.HeadingWrapper}>
        <div className={classes.innerWrapper}>
          <Heading
            BreadCrumb={BreadCrumb}
            BreadCrumHomeLink={"/sm-dashboard"}
            headingType={"Page Headings"}
            headingText={"Update Booking"}
          />
        </div>
        <div className={classes.buttonWrapper}>
          <div
            className={
              status === "Allocated" ||
                status === "CarAllocated" ||
                // status === "DeliveryRequested" ||
                status === "AllocatedButVinAwaited"
                ? classes.deliver
                : classes.deliveredButton
            }
          >
            {carStatus === "Physical" ? (
              <Button
                type={"greenLong"}
                onClick={() => setModalShow(true)}
                buttonText={"Mark Delivered"}
              />
            ) : (
              <div className={classes.inputDisable}>
                {/* <Button
                  type={"greenLong"}
                  // onClick={() => setModalShow(true)}
                  buttonText={carStatus}
                /> */}
              </div>
            )}
          </div>

          <div
            className={
              checkStatus2()
                ? classes.cancelButtonDisable
                : classes.cancelButton
            }
          >
            <Button
              type={"pinkLong"}
              onClick={() => setOpen(true)}
              buttonText={"Booking Cancellation"}
            />
          </div>
        </div>
      </div>
      {loading === "update" ? (
        <div className={classes.loader}>
          {" "}
          <img src="../assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          {status === "Delivered" ? (
            <div className="my-2 mb-4 text-red-500">
              Bookings that are delivered cannot be modified. Please request
              Delivery cancel first.{" "}
            </div>
          ) : (
            <></>
          )}
          <div
            className={
              checkStatus2()
                ? classes.searchContainerDisabled
                : classes.searchContainer
            }
          >
            <div className={classes.headingFormContainer}>
              <Heading
                headingType={"sub heading small"}
                headingText={"Customer Details"}
              />

              <div className={classes.inputRow}>
                <div className={checkStatus1() && classes.inputDisable}>
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => {
                      setFirstname(e.target.value);
                      error.firstname = "";
                    }}
                    value={firstname}
                    error={error.firstname}
                    label={"First Name"}
                  />
                  <p class="peer-invalid:visible text-red-500 font-light">
                    {error.firstname}
                  </p>
                </div>
                <div className={checkStatus1() && classes.inputDisable}>
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      error.lastname = "";
                    }}
                    value={lastname}
                    error={error.lastname}
                    label={"Last Name"}
                  />
                  <p class="peer-invalid:visible text-red-500 font-light">
                    {error.lastname}
                  </p>
                </div>
                <div className={checkStatus1() && classes.inputDisable}>
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => onlyNumericMobile(e)}
                    value={mobile}
                    maxLength={10}
                    label={"Mobile Number"}
                    error={error.mobile}
                  />
                  <p class="peer-invalid:visible textcheckStatus1-red-500 font-light">
                    {error.mobile}
                  </p>
                </div>
                <div
                  className={
                    checkStatus2()
                      ? classes.inputWrapper
                      : classes.inputWrapperDisable
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    label={"Booking Amount"}
                  />
                </div>
                <div>
                  <Input
                    type={"Select Menus Simple native low height"}
                    asmData={asmData}
                    dropdownType={"AsmList"}
                    onChange={(e) => {
                      setAsm(e.target.value);
                      error.asm = "";
                    }}
                    value={asm}
                    selected={defaultAsm}
                    error={error.asm}
                    label={"ASM"}
                  />
                  <p class="peer-invalid:visible text-red-500 font-light">
                    {error.asm}
                  </p>
                </div>

                <div>
                  <Input
                    type={"Select Menus Simple native low height"}
                    executiveData={executiveData}
                    dropdownType={"ExecutiveList"}
                    onChange={(e) => {
                      setExecutive(e.target.value);
                      error.executive = "";
                    }}
                    value={executive}
                    selected={defaultExecutive}
                    error={error.executive}
                    label={"Executive"}
                  />
                  <p class="peer-invalid:visible text-red-500 font-light">
                    {error.executive}
                  </p>
                </div>
              </div>
              <div className={classes.inputRow}>
                <div
                  className={
                    checkStatus2() ? classes.input : classes.inputDisable
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"date"}
                    onChange={(e) => setBookingDate(e.target.value)}
                    value={bookingDate}
                    label={"Date of Booking"}
                    onKeyDown={(e) => e.preventDefault()}

                  />
                </div>
                <div
                  className={
                    checkStatus1() ? classes.inputDisable : classes.input
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"date"}
                    onChange={(e) => {
                      setCommitedDate(e.target.value);
                      error.commitedDate = "";
                    }}
                    value={commitedDate}
                    error={error.commitedDate}
                    label={"Commited Delivery Date"}
                    onKeyDown={(e) => e.preventDefault()}

                  />
                  <p class="peer-invalid:visible text-red-500 font-light">
                    {error.commitedDate}
                  </p>
                </div>
                <div>
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    label={"WINGS ID"}
                    onChange={(e) => {
                      setWings(e.target.value);
                      error.wings = "";
                    }}
                    value={wings}
                    error={error.wings}
                  />
                  <span className={classes.error}>{error.wings}</span>
                </div>
                <div>
                  <div className="block text-sm font-medium text-gray-700">
                    Booking priority
                  </div>
                  <Input
                    type={"Select Menus with checkbox"}
                    label={"Booking Priority *"}
                    // disable={true}
                    data={bookingPriorityList}
                    error={error.checkedPriority}
                    priorityCheckboxHandler={priorityCheckboxHandler}
                    checkedPriority={checkedPriority}
                  // errors={errors}
                  />
                  <p class="peer-invalid:visible text-red-500 font-light">
                    {error.checkedPriority}
                  </p>
                </div>

                <div
                  className={
                    checkStatus2()
                      ? classes.inputWrapper
                      : classes.inputWrapperDisable
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => setRecieptNo(e.target.value)}
                    value={recieptNo}
                    label={"Receipt No"}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  {/* <Input
                      type={"input with label low height"}
                      inputType={"file"}
                      onChange={(e) => setReciept(e.target.files[0])}
                      // value={reciept}
                      label={"Upload Receipt"}
                    /> */}
                  <label
                    htmlFor={"Upload Receipt"}
                    className="block text-sm font-medium text-gray-700 "
                  >
                    Upload Receipt
                  </label>
                  <div
                    className={` w-full mt-1 flex rounded-md border-gray-300 px-2 py-1 border  sm:text-sm  focus:border-indigo-500 focus:ring-indigo-500`}
                  >
                    <a
                      className={classes.recieptUrl}
                      target="_blank"
                      href={reciept}
                    >
                      Receipt
                    </a>
                    {/* <img src={reciept} className="h-5 w-auto" /><span className={classes.recieptUrl}>{reciept}</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.headingFormContainer}>
              <Heading
                headingType={"sub heading small"}
                headingText={"Vehicle preference"}
              />
              <div>
                <div className={classes.preferenceWrapper}>
                  <div>
                    <div className={classes.preference}>
                      Preference 1
                      {allocatedPref === 1 ? (
                        <BsCheckLg className={classes.checkIcon} />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className={
                        checkStatus1()
                          ? `${classes.preferenceRow} ${classes.dropDownWrapperDisable}`
                          : classes.preferenceRow
                      }
                    >
                      <div>
                        <Input
                          type={"Select Menus Simple native low height"}
                          label={"Model"}
                          dropdownType={"Model"}
                          filteredData={filteredList1}
                          // model={selectedModel1}
                          value={selectedModel1}
                          onChange={(e) => setSelectedModel1(e.target.value)}
                        />
                      </div>
                      <div>
                        <Input
                          type={"Select Menus Simple native low height"}
                          label={"Variant"}
                          value={selectedVariant1}
                          dropdownType={"Variant"}
                          filteredData={filteredList1}
                          onChange={(e) => setSelectedVariant1(e.target.value)}
                        />
                      </div>
                      <div>
                        <Input
                          type={"Select Menus Simple native low height"}
                          label={"Color"}
                          dropdownType={"Color"}
                          value={selectedColor1}
                          filteredData={filteredList1}
                          onChange={(e) => setSelectedColor1(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={classes.preference}>
                      Preference 2
                      {allocatedPref === 2 ? (
                        <BsCheckLg className={classes.checkIcon} />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className={
                        checkStatus1()
                          ? `${classes.preferenceRow} ${classes.dropDownWrapperDisable}`
                          : classes.preferenceRow
                      }
                    >
                      <div>
                        <Input
                          type={"Select Menus Simple native low height"}
                          label={"Model "}
                          dropdownType={"Model"}
                          filteredData={filteredList2}
                          value={selectedModel2}
                          onChange={(e) => {
                            setSelectedModel2(e.target.value);
                            error.selectedModel1 = "";
                          }}
                        />
                      </div>
                      <div>
                        <Input
                          type={"Select Menus Simple native low height"}
                          label={"Variant"}
                          dropdownType={"Variant"}
                          value={selectedVariant2}
                          filteredData={filteredList2}
                          onChange={(e) => setSelectedVariant2(e.target.value)}
                        />
                      </div>
                      <div>
                        <Input
                          type={"Select Menus Simple native low height"}
                          label={"Color"}
                          dropdownType={"Color"}
                          value={selectedColor2}
                          filteredData={filteredList2}
                          onChange={(e) => setSelectedColor2(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Heading
                headingType={"sub heading small"}
                headingText={"Other Details"}
              />
              <div className={classes.inputRow}>
                <div
                  className={
                    checkStatus1()
                      ? classes.inputWrapperDisable
                      : classes.inputWrapper
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => {
                      setYom(e.target.value);
                      error.yom = "";
                    }}
                    value={yom}
                    error={error.yom}
                    maxLength="4"
                    min="4"
                    label={"YOM"}
                  />
                  <span className="text-red-500"> {error.yom}</span>
                </div>
                {/* <div>
                  <div
                    className={
                      checkStatus2()
                        ? classes.inputWrapper
                        : classes.inputWrapperDisable
                    }
                  >
                    <Input
                      type={"Select Menus Simple native low height"}
                      type={"input with label low height"}
                      inputType={"text"}
                      onChange={(e) => setStatus(e.target.value)}
                      dropdownType={"status"}
                      statusData={statusData}
                      value={status}
                      label={"Status"}
                    />
                  </div>
                </div>
                <div
                  className={
                    checkStatus2()
                      ? classes.inputWrapper
                      : classes.inputWrapperDisable
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"date"}
                    onChange={(e) => setStatusDate(e.target.value)}
                    value={statusDate}
                    label={"Status Date"}
                  />
                </div>
                <div
                  className={
                    checkStatus2()
                      ? classes.inputWrapper
                      : classes.inputWrapperDisable
                  }
                >
                  <Input
                    type={"input with label low height"}
                    inputType={"text"}
                    onChange={(e) => setVinNumber(e.target.value)}
                    value={vinNumber}
                    label={"Vin No."}
                  />
                </div> */}
                <div
                >
                  <div>
                    <Input
                      type={"radio group"}
                      label="Exchange "
                      value={exchange}
                      setState={setExchange}
                      data={notificationMethods}
                    />
                  </div>
                </div>
                <div className={`${classes.inputWrapper} col-span-4`}>
                  <Input
                    type={"textarea"}
                    inputType={"text"}
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                    placeholder={" "}
                    label={"Remark"}
                    rows={1}
                  />
                </div>
              </div>

              <div className={classes.rigthSide}>

                <div className={classes.inputWrapper}>
                  <Input
                    type={"textarea"}
                    inputType={"text"}
                    onChange={(e) => setFollowUpOne(e.target.value)}
                    value={followUpOne}
                    placeholder={" "}
                    label={"Follow Up 1"}
                    rows={1}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Input
                    type={"textarea"}
                    inputType={"text"}
                    onChange={(e) => setFollowUpTwo(e.target.value)}
                    value={followUpTwo}
                    placeholder={" "}
                    rows={1}
                    label={"Follow Up 2"}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Input
                    type={"textarea"}
                    inputType={"text"}
                    onChange={(e) => setFollowUpThree(e.target.value)}
                    value={followUpThree}
                    rows={1}
                    placeholder={" "}
                    label={"Follow Up 3"}
                  />
                </div>
              </div>
              <div className={classes.submitButtonWrapper}>
                <Button
                  type={"whiteButton"}
                  onClick={() => navigate("/sm-search-update")}
                  buttonText={"Cancel"}
                />
                <div className={classes.submitButton}>
                  <Button
                    type={"smallButton"}
                    onClick={() =>
                      validation() ? UpdateBooking() : console.log("err")
                    }
                    buttonText={"Save"}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </SmWrapper>
  );
};

export default UpdateBooking;
