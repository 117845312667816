import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/StmAllocationStatus.module.css";
import StackedList from "../../tailwindUI/StackedList";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Pagination from "../../tailwindUI/Pagination";
import DataNotFound from "../../Components/DataNotFound";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import TableHeader from "../../Components/TableHeader";

const StmAllocationHistory = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const pathname = window.location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiFailled, setApiFailled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [dataCount, setDataCount] = useState("");
    const [followUpModal, setFollowUpModal] = useState(false);
    const [carDatail, setCarDetail] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
    const maxPages = Math.ceil(dataCount / 20);
    const user = localStorage.getItem("UserToken");

    useEffect(() => {
        AllocationHistoryListApi();
    }, [searchTerm === "", page]);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/login", { replace: true });
    };
    const AllocationHistoryListApi = () => {
        setLoading(true);

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            SearchTerm: searchTerm,
            UserToken: user,
            page: page,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/am/AllocationHistoryList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.message === "data found successfully"
                ) {
                    if (searchTerm && result.data.length < 2) {
                        navigate(pathname);
                    }
                    setData(result.data);
                    setDataCount(result.datacount);
                }
                else {
                    setApiFailled(true);
                    setMessage(result.Reason);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            })
            .finally(() => setLoading(""));
    };

    const BreadCrumb = {
        pagename: "Allocation History",
        pagePath: "/stm-allocation-history",
        pagename1: "",
        pagePath1: "",
    };
    const successMessage = () => {
        setTimeout(() => {
            setMessage("");
        }, 3000);
        return message;
    };

    const followUpHandler = (carDetail) => {
        setFollowUpModal(true);
        setCarDetail(carDetail);
    };

    return (
        <>
            {message && (
                <SuccessMessage
                    onClose={() => setMessage("")}
                    message={successMessage()}
                    apiFailled={apiFailled}
                />
            )}
            {errorMessage && <ErrorMessage message={errorMessage} />}


            {followUpModal && (
                <FollowUpRemarks
                    bookingData={carDatail}
                    setOpen={setFollowUpModal}
                    open={followUpModal}
                // typeAllocation="Auto Allocation Cancelled"
                />
            )}
            <Navbar navbarType={"black navbar"} />
            <div className={classes.maincontainer}>
                <Heading
                    headingType={"Page Headings"}
                    headingText={"Allocation History"}
                    // subheadingText={localStorage.getItem("Location")}
                    BreadCrumb={BreadCrumb}
                    BreadCrumHomeLink={"/stm-dashboard"}
                />
                <div className={classes.background}>
                    <TableHeader
                        buttonText="Search"
                        tableHeading="Search Criteria"
                        subHeading="subHeading"
                        totalResult={dataCount}
                        pageResult={data.length}
                        placeholderType="Enter Model,Variant,Color,Outlet,Vin (For Example:-Grand,i10,Fiery red,Telibagh)"
                        apicall={AllocationHistoryListApi}
                        setSearchTerm={setSearchTerm}
                        searchTerm={searchTerm}
                    />
                    {loading ? (
                        <div className={"w-full bg-white  "}>
                            <img
                                className="m-auto"
                                src="../assets/loadingGif.gif"
                                alt="loading"
                            />
                        </div>
                    ) : (
                        <>
                            {data?.length > 0 ? (
                                <>
                                    {data?.map((item) => {
                                        return (
                                            <div className={classes.list}>
                                                <div className={classes.stackListContainer}>
                                                    <div role="list" className={classes.stackListWrapper}>
                                                        <StackedList
                                                            type="Stm Allocation History"
                                                            data={item}
                                                            followUpHandler={followUpHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <DataNotFound />
                            )}
                        </>
                    )}

                    <div className="bg-white">
                        {maxPages > 1 && <Pagination maxPages={maxPages} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StmAllocationHistory