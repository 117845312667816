import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import classes from "../../module.css/StmBookingCancellationByShowroomManager.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import Pagination from "../../tailwindUI/Pagination";
import StackedList from "../../tailwindUI/StackedList";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import TableHeader from "../../Components/TableHeader";
import AddRemarkInput from "../../Components/AddRemarkInput";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
const RecentlyAllocatedCars = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [dataCount, setDataCount] = useState("");
  const [followUpModal, setFollowUpModal] = useState(false);
  const [carDatail, setCarDetail] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [viewText, setViewText] = useState(false)
  const [error, setError] = useState({});
  const [addRemark, setAddRemark] = useState("")
  const [allExcelData, setAllExcelData] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(dataCount / 20);

  useEffect(() => {
    ShowroomBookingcancledAPi();
  }, [searchTerm === "", page]);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const ShowroomBookingcancledAPi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      page: page,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/CBTRecentlyAllocatedbutnotDelivered",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setData(result.data);
          setDataCount(result.count);
          setAllExcelData(result.data);
        }
        else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const BreadCrumb = {
    pagename: "Booking Cancellation By Showroom Manager",
    pagePath: "/stm-booking-cancellation-by-showroom-manager",
    pagename1: "",
    pagePath1: "",
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };
  return (
    <div>
         {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
        <Navbar navbarType={"CBT"} />
      <div className={classes.maincontainer}>
        <Heading
          headingType={"Page Headings"}
          headingText={"Recently Allocated Cars"}
          // subheadingText={localStorage.getItem("Location")}
        //   BreadCrumb={BreadCrumb}
        //   BreadCrumHomeLink={"/stm-dashboard"}
        />
        </div>
        <div className={classes.background}>
          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={dataCount}
            pageResult={data.length}
            placeholderType="Enter Model,Variant,Color,Outlet (For Example:-Grand,i10,Fiery red,Telibagh)"
            apicall={ShowroomBookingcancledAPi}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div>
            <div className="my-2 text-end">
              <ExcelDownload
                csvData={allExcelData}
                fileName="Recently Allocated Cars List"
              />
            </div>
          </div>
          {loading ? (
            <div className={"w-full bg-white  "}>
              <img
                className="m-auto"
                src="../assets/loadingGif.gif"
                alt="loading"
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item) => {

                    return (
                      <div className={classes.list}>
                        <div className={classes.stackListContainer}>
                          <div role="list" className={classes.stackListWrapper}>
                            <StackedList
                              type="Recently Allocated Cars"
                              data={item}
                              // followUpHandler={followUpHandler}
                              buttonHide={"Hide"}
                              Type="CBT"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}

          <div className="bg-white">
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
    </div>
  )
}

export default RecentlyAllocatedCars