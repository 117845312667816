import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import StackedList from "../../tailwindUI/StackedList";
import classes from "../../module.css/AllocationAwaited.module.css";
import Modal from "../../tailwindUI/Modal";
import Input from "../../tailwindUI/Input";
import { IoCloseSharp } from "react-icons/io5";
import Button from "../../tailwindUI/Button";
import PreferenceCard from "../../tailwindUI/PreferenceCard";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../tailwindUI/Pagination";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import DataNotFound from "../../Components/DataNotFound";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import TableHeader from "../../Components/TableHeader";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";

const StmAllocationAwaited = () => {
  const [open, setOpen] = useState(false);
  const [committeddate, setCommitteddate] = useState("");
  const [customerRemark, setCustomerRemark] = useState("");
  const [prefModal, setPrefModal] = useState("");
  const [listdata, setListdata] = useState([]);
  const [preference1, setPreference1] = useState([]);
  const [preference2, setPreference2] = useState([]);
  const [carId, setCarId] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({});
  const [carDatail, setCarDetail] = useState({});
  const [totalCarCount, setTotalCarCount] = useState("");
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [followUpModal, setFollowUpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [carRemarkOpen, setCarRemarkOpen] = useState(false);
  const [allExcelData, setAllExcelData] = useState([]);

  const UserToken = localStorage.getItem("UserToken");
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const navigate = useNavigate();

  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(totalCarCount / 20);

  useEffect(() => {
    allocatedAwaitApi();
  }, [searchTerm === "", page]);

  const validation = () => {
    let isValid = true;
    const error = {};
    if (committeddate === "") {
      isValid = false;
      error.committeddate = "Please select EDD";
    }
    setErrors(error);
    return isValid;
  };

  const allocatedAwaitApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      page: page,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AllocationAwaitedList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data === "You dont have permission to access data" &&
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data found successfully"
        ) {
          if (searchTerm && result.data.length < 2) {
            navigate(pathname);
          }
          setListdata(result.data);
          setTotalCarCount(result.datacount);
          setAllExcelData(result.data);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data not found "
        ) {
          if (searchTerm) {
            navigate(pathname);
            setTotalCarCount(0);
          }
          setListdata([]);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const carDetailHandler = (detail) => {
    setCarDetail(detail);
    setPrefModal(true);
    getPrefernceApi(detail);
  };
  const carBookingHandler = (BookingDetail) => {
    setCarDetail(BookingDetail);
    setCarRemarkOpen(true);
  };

  const getPrefernceApi = (detail) => {
    const { BookingID } = detail;
    setModalLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: BookingID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMGetPreferenceDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message !== "data not found "
        ) {
          setPreference1(result.preference1);
          setPreference2(result.preference2);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setModalLoading(false));
  };

  const AllocateApi = (selectedPrefrence, carDetail) => {
    const { CarStockID } = selectedPrefrence;
    const { BookingID } = carDetail;
    if (validation()) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserToken: UserToken,
        BookingID: BookingID,
        CarStockID: CarStockID,
        AmEdd: committeddate,
        StockManagerRemark: customerRemark,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://promote.onecorp.co.in/api/v1/stockone/am/AMAllocateStock",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result.status_code === 200 &&
            result.status === "Success" &&
            result.message === "Car Allocated Successfully"
          ) {
            setPrefModal(false);
            setOpen(false);
            setMessage("Car Allocated Successfully");
            setCommitteddate("");
            setApiFailled(false);
            allocatedAwaitApi();
          } else if (
            result.status_code === 200 &&
            result.status === "Success" &&
            result.message === "Prefrences not matched"
          ) {
            setApiFailled(true);
            setMessage(result.Reason);
          } else if (result.status_code === 300) {
            setApiFailled(true);
            setMessage(result.Reason);
          } else if (
            result.status_code === 200 &&
            result.status === "Success"
          ) {
            setApiFailled(true);
            setMessage(result.message);
          } else if (result.status_code === 400) {
            setErrorMessage("Something went wrong please contact support !");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setErrorMessage("Something went wrong please contact support !");
        });
    }
  };

  const AMStockManagerRemarkUpdate = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: carDatail.BookingID,
      StockManagerRemark: customerRemark,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMStockManagerRemarkUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.message === "Data Updated Successfully"
        ) {
          setMessage(result.message);
          setCarRemarkOpen(false);
          setCustomerRemark("");
          setApiFailled(false);
          allocatedAwaitApi();
        } else if (
          result.status_code === 200 &&
          result.message === "Data Updated Successfully"
        ) {
          setApiFailled(true);
          setMessage(result.message);
          setCarRemarkOpen(false);
          setCustomerRemark("");
        } else if (result.status_code === 300) {
          setCarRemarkOpen(false);
          setApiFailled(true);
          setMessage("Allocation Manager Remark cannot be empty");
        } else {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const followUpHandler = (carDetail) => {
    setFollowUpModal(true);
    setCarDetail(carDetail);
  };

  const onClosePrefrenceModal = () => {
    setSelectedPreference({});

    setPrefModal(false);
    setCarId("");
    setPreference2("");
    setPreference1("");
    setErrors({});
  };

  const BreadCrumb = {
    pagename: "Allocation Awaited",
    pagePath: "/stm-allocation-awaited",
    pagename1: "",
    pagePath1: "",
  };
  const BreadCrumHomeLink = "/stm-dashboard";
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2500);
    return message;
  };

  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };

  const filteredPhysicalCount =
    preference1 &&
    preference1.filter((item) => {
      return item.carstatus === "Physical";
    });

  const filteredTransitCount =
    preference1 &&
    preference1.filter((item) => {
      return item.carstatus === "InTransit";
    });

  const filteredPendingCount =
    preference1 &&
    preference1.filter((item) => {
      return item.carstatus === "Pending";
    });

  const filteredPhysicalCountPref2 =
    preference2 &&
    preference2.filter((item) => {
      return item.carstatus === "Physical";
    });

  const filteredTransitCountPref2 =
    preference2 &&
    preference2.filter((item) => {
      return item.carstatus === "InTransit";
    });

  const filteredPendingCountPref2 =
    preference2 &&
    preference2.filter((item) => {
      return item.carstatus === "Pending";
    });
  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {followUpModal && (
        <FollowUpRemarks
          bookingData={carDatail}
          setOpen={setFollowUpModal}
          open={followUpModal}
        />
      )}
      {carRemarkOpen && (
        <Modal
          setOpen={setCarRemarkOpen}
          heading={"Allocation Manager Remark"}
          open={carRemarkOpen}
          onCancel={() => {
            setCarRemarkOpen(false);
            setCustomerRemark("");
          }}
          onClick={() => AMStockManagerRemarkUpdate()}
          type="Simple alert"
        >
          <div className="mt-2">
            <Input
              type={"textarea"}
              label="Allocation Manager Remark"
              placeholder="Enter Allocation Manager Remark"
              value={customerRemark}
              onChange={(e) => {
                setCustomerRemark(e.target.value);
              }}
            />
          </div>
        </Modal>
      )}
      {open && (
        <Modal
          type="Allocation Awaited"
          setOpen={() => {
            setOpen(false);
            setCommitteddate("");
            setErrors({});
            setCustomerRemark("");
          }}
          open={open}
          onCancel={() => {
            setOpen(false);
            setCommitteddate("");
            setErrors({});
            setCustomerRemark("");
          }}
          loading={loading}
          onClick={() => AllocateApi(selectedPreference, carDatail)}
        >
          <div className="mt-3 mb-6 px-16">
            <div className="mb-4">
              <PreferenceCard
                item={selectedPreference}
                carId={carId}
                cardType="Allocation Awaited without cursor"
              />
            </div>
            <div>
              <Input
                type={"input with label"}
                label="Stock Manager EDD"
                inputType="date"
                value={committeddate}
                onChange={(e) => {
                  setCommitteddate(e.target.value);
                  errors.committeddate = "";
                }}
                error={errors.committeddate}
                onKeyDown={(e) => e.preventDefault()}
              />
              <span className="error">{errors.committeddate}</span>
            </div>
            <div className="mt-2">
              <Input
                type={"textarea"}
                label="Allocation Manager Remark"
                placeholder="Enter Allocation Manager Remark"
                value={customerRemark}
                onChange={(e) => {
                  setCustomerRemark(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal>
      )}

      {prefModal && (
        <Modal
          type="Preference Modal"
          setOpen={setPrefModal}
          open={prefModal}
          onClose={() => {
            // onClosePrefrenceModal();
          }}
        >
          <div className="bg-indigo-700 text-white p-6">
            <div className="flex justify-between items-center">
              <div className="font-bold text-xl">{`${carDatail.customer_name} ${carDatail.LastName}`}</div>
              <div>
                <IoCloseSharp
                  className="text-white cursor-pointer text-xl"
                  onClick={() => onClosePrefrenceModal()}
                />
              </div>
            </div>
            <div className="text-blue-200 text-xl font-medium my-1">
              {dateHandler(carDatail.CommittedDeliveryDate)}
            </div>

            <div className="text-gray-400 text-xl mb-2">{`${carDatail.car_model} ${carDatail.car_variant} ${carDatail.car_colour}`}</div>
            <div className="flex  font-bold ">
              <div className="pr-8">{`1.${carDatail.car_model} ${carDatail.car_variant} ${carDatail.car_colour}`}</div>
              {carDatail.CarModel2 ? (
                <div>{`2.${carDatail.CarModel2} ${carDatail.CarVariant2} ${carDatail.CarColour2}`}</div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {modalLoading ? (
            <div className={"w-full bg-white d-flex m-auto justify-center "}>
              <img
                className="m-auto"
                src="../assets/loadingGif.gif"
                alt="loading"
              />
            </div>
          ) : (
            <div className="">
              <div className="text-center mt-1 text-red-500">
                {errors.preference}
              </div>
              <div className="flex justify-between  items-center p-6">
                <div className="font-bold text-lg">
                  Preference1 (Total {preference1.length}, Pending{" "}
                  {filteredPendingCount.length}, InTransit{" "}
                  {filteredTransitCount.length}, Physical{" "}
                  {filteredPhysicalCount.length})
                </div>
                <div>
                  {preference1.length === 0 && preference2.length === 0 ? (
                    <></>
                  ) : (
                    <Button
                      buttonText={"Allocate"}
                      onClick={() => {
                        if (
                          JSON.stringify(selectedPreference) ===
                          JSON.stringify({})
                        ) {
                          setErrors({
                            preference: "Preference not selected",
                          });
                        } else {
                          setOpen(true);
                        }
                      }}
                      type={"greenFilled"}
                    />
                  )}
                </div>
              </div>
              <div className="px-6">
                <div className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {preference1.length > 0 ? (
                    preference1?.map((item) => {
                      return (
                        <PreferenceCard
                          key={item.CarStockID}
                          item={item}
                          onClick={(e) => {
                            setCarId(item.CarStockID);
                            setSelectedPreference(item);
                            setErrors({});
                          }}
                          carId={carId}
                        />
                      );
                    })
                  ) : (
                    <div>Preference 1 is not available</div>
                  )}
                </div>
              </div>
              <div className="font-bold text-lg p-6">
                Preference2 (Total {preference2.length}, Pending{" "}
                {filteredPendingCountPref2.length}, InTransit{" "}
                {filteredTransitCountPref2.length}, Physical{" "}
                {filteredPhysicalCountPref2.length})
              </div>
              <div className="px-6 mb-16">
                <div className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {preference2.length > 0 ? (
                    preference2?.map((item) => {
                      return (
                        <PreferenceCard
                          key={item.CarStockID}
                          item={item}
                          onClick={(e) => {
                            setCarId(item.CarStockID);
                            setSelectedPreference(item);
                            setErrors({});
                          }}
                          carId={carId}
                        />
                      );
                    })
                  ) : (
                    <div>Preference 2 is not available</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
      <Navbar navbarType={"black navbar"} />
      <Heading
        headingType={"Page Headings"}
        headingText={"Allocation Awaited"}
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={BreadCrumHomeLink}
      />
      <div className={classes.maincontainter}>
        <div className="sm:flex sm:justify-between sm:items center bg-white p-4">
          <div className="sm:flex pl-4 sm:pl-0">
            <div className="mr-2 text-xs">
              <span className="bg-lime-600 w-2 h-2 inline-block mr-1"></span>
              Available In Stock
            </div>
            <div className="mr-2 text-xs">
              <span className="bg-blue-600 w-2 h-2 inline-block mr-1"></span>
              InStock But Allocated
            </div>
            <div className="mr-2 text-xs">
              <span className="bg-red-600 w-2 h-2 inline-block mr-1"></span>Out
              Of Stock
            </div>
          </div>
        </div>

        <TableHeader
          buttonText="Search"
          tableHeading="Search Criteria"
          subHeading="subHeading"
          totalResult={totalCarCount}
          pageResult={listdata.length}
          placeholderType="Enter Model,Variant,Color,Outlet (For Example:-Grand,i10,Fiery red,Telibagh)"
          apicall={allocatedAwaitApi}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
        {/* #ExportExcel */}
        <div>
          <div className="my-2 text-end">
            <ExcelDownload
              csvData={allExcelData}
              fileName="Stock Manager Allocation Awaited"
            />
          </div>
        </div>
        {loading ? (
          <div className={"w-full bg-white  "}>
            <img
              className="m-auto"
              src="../assets/loadingGif.gif"
              alt="loading"
            />
          </div>
        ) : (
          <div className={classes.list}>
            <div className={classes.stackListContainer}>
              <div role="list" className={classes.stackListWrapper}>
                {listdata?.length > 0 ? (
                  listdata?.map((item) => {
                    return (
                      <StackedList
                        type="AllocationAwaited"
                        setOpen={setPrefModal}
                        item={item}
                        carDetailHandler={carDetailHandler}
                        followUpHandler={followUpHandler}
                        setCarRemarkOpen={setCarRemarkOpen}
                        carBookingHandler={carBookingHandler}
                        setCustomerRemark={setCustomerRemark}
                      />
                    );
                  })
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>

            <div className="bg-white">
              {maxPages > 1 && <Pagination maxPages={maxPages} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StmAllocationAwaited;
