import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'


const clients = [
  {
    id: 1,
    name: `Today's Booking`,
    reportUrl:"/cbt-dashboard-report/todays-booking",
    imageUrl: '/assets/schedule.png',
    lastInvoice: { date: 'December 13, 2022', dateTime: '2022-12-13', amount: '$2,000.00', status: 'Overdue' },
  },
  {
    id: 2,
    name: `Today's Allocation`,
    reportUrl:"/cbt-dashboard-report/todays-allocation",
    imageUrl: '/assets/asset-management.png',
    lastInvoice: { date: 'January 22, 2023', dateTime: '2023-01-22', amount: '$14,000.00', status: 'Paid' },
  },
  {
    id: 3,
    name: `Today's Booking Cancellations`,
    reportUrl:"/cbt-dashboard-report/todays-booking-cancellations",
    imageUrl: '/assets/no-car.png',
    lastInvoice: { date: 'January 23, 2023', dateTime: '2023-01-23', amount: '$7,600.00', status: 'Paid' },
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CBTDashboarCard=({data})=> {

 const CountFunc=(type)=>{
  if(type==="Today's Booking"){
    return data.todays_bookings_count ||0
  }
  if(type==="Today's Allocation"){
    return data.todays_allocations_count ||0
  }
  if(type==="Today's Booking Cancellations"){
    return data.todays_cancellations_count||0
  }
 }
  return (
    <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      {clients.map((client) => (
        <li key={client.id} className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <img
              src={client.imageUrl}
              alt={client.name}
              className="h-12 w-12 flex-none rounded-lg bg-white object-cover p-1 ring-1 ring-gray-900/10"
            />
            <div className="text-lg font-bold leading-6 text-gray-900">{client.name}</div>
            <Menu as="div" className="relative ml-auto">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={client.reportUrl}
                        target='_blank'
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        View Report<span className="sr-only">, {client.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-indigo-600 text-lg font-bold">Total</dt>
              <dd className="text-indigo-600 text-lg font-bold">
             { data?  <div>{CountFunc(client.name)}</div>:0}
              </dd>
            </div>
       
          </dl>
        </li>
      ))}
    </ul>
  )
}
export default CBTDashboarCard