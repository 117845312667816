import React, { useEffect, useState } from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/ViewStockDetails.module.css";
import { useNavigate } from "react-router-dom";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import "../../index.css";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Resize,
    Group,
    Sort,
    ColumnMenu,
    Filter,
    Toolbar,
    ExcelExport,
    PdfExport,
} from "@syncfusion/ej2-react-grids";
import { registerLicense } from "@syncfusion/ej2-base";

const AdminDeletedCar = () => {
    const getUsersData = JSON.parse(localStorage.getItem("userdata"));
    const edpFilter = getUsersData?.filter((edp) => edp.UserRole === "EDP");
    const edpToken = edpFilter[0].UserToken;
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [apiFailled, setApiFailled] = useState(false);
    const [deletedCarsData, setDeletedCarsData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        EDPStockdeleteDetailListApi();
    }, []);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/creta", { replace: true });
    };

    const EDPStockdeleteDetailListApi = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: edpToken,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockdeleteDetailList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {

                    LogOut();
                } else if (
                    result.status_code === 200 &&
                    result.message === "data found successfully"
                ) {
                    setDeletedCarsData(result.data);
                } else {
                    setApiFailled(true);
                    setMessage(result.Reason);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            })
            .finally(() => setLoading(false));
    };

    const successMessage = () => {
        setTimeout(() => {
            setMessage("");
        }, 3000);
        return message;
    };

    const BreadCrumb = {
        pagename: "Car Delete",
        pagePath: "/creta-delete-car",
        pagename1: "",
        pagePath1: "",
    };

    const BreadCrumHomeLink = "/creta-dashboard";

    const groupOptions = { showGroupedColumn: true };
    const filterSettings = { type: "CheckBox" };

    registerLicense(
        "Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9"
    );

    const toolbarOptions = ["ExcelExport", "PdfExport", "CsvExport", "Search"];
    let gridInstance;
    let flag = true;
    function dataBound() {
        if (flag) {
            gridInstance.toolbarModule.toolbar.hideItem(2, true);
            flag = false;
        }
    }

    function toolbarClick(args) {
        switch (args.item.text) {
            case "PDF Export":
                gridInstance.pdfExport();
                break;
            case "Excel Export":
                gridInstance.excelExport();
                break;
            case "CSV Export":
                gridInstance.csvExport();
                break;
        }
    }
    return (
        <>
            {message && (
                <SuccessMessage
                    onClose={() => setMessage("")}
                    message={successMessage()}
                    apiFailled={apiFailled}
                />
            )}

            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Navbar navbarType={"admin navbar"} />
            <div className={classes.maincontainer}>
                <div className="mb-4">
                    <Heading
                        headingType={"Page Headings"}
                        headingText={"Car Delete"}
                        BreadCrumb={BreadCrumb}
                        BreadCrumHomeLink={BreadCrumHomeLink}
                    />
                </div>
                <div className="w-[85%] bg-white mx-auto mt-[20px] p-2">
                    <h3 className="text-base font-bold leading-6 text-gray-900 mt-2">
                        Deleted Cars List
                    </h3>
                    {loading ? (
                        <img
                            src="../assets/loadingGif.gif"
                            alt="loading"
                            className={classes.loader}
                        />
                    ) : (
                        <div className="control-pane">
                            <div className="control-section">
                                <GridComponent
                                    dataSource={deletedCarsData}
                                    height="500"
                                    allowPaging={true}
                                    allowResizing={true}
                                    allowGrouping={true}
                                    allowSorting={true}
                                    allowFiltering={true}
                                    showColumnMenu={true}
                                    groupSettings={groupOptions}
                                    filterSettings={filterSettings}
                                    toolbar={toolbarOptions}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbarClick={toolbarClick.bind(this)}
                                    ref={(grid) => (gridInstance = grid)}
                                    dataBound={dataBound.bind(this)}
                                    pageSettings={{ pageCount: 4, pageSizes: true }}
                                    autoFit={true}

                                >
                                    <ColumnsDirective>
                                        <ColumnDirective
                                            field="win_num"
                                            headerText="VIN"
                                            width="130"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="OrderId"
                                            headerText="Order"
                                            width="130"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="model_name"
                                            headerText="Model"
                                            width="120"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="variant"
                                            headerText="Variant"
                                            width="140"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="fueltype"
                                            headerText="Fuel Type"
                                            width="120"
                                            textAlign="center"
                                        />
                                        <ColumnDirective
                                            field="colour"
                                            headerText="Colour"
                                            width="130"
                                            textAlign="center"
                                        />
                                        <ColumnDirective
                                            field="availabilitystatus"
                                            headerText="Status"
                                            width="120"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="carstatus"
                                            headerText="Car Status"
                                            width="120"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="CarRemark"
                                            headerText="Car Remark"
                                            width="130"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="updated_at"
                                            headerText="Deleteion Date"
                                            width="120"
                                            textAlign="center"
                                        ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject
                                        services={[
                                            Resize,
                                            Group,
                                            Sort,
                                            ColumnMenu,
                                            Filter,
                                            Page,
                                            Toolbar,
                                            ExcelExport,
                                            PdfExport,
                                        ]}
                                    />
                                </GridComponent>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AdminDeletedCar;
