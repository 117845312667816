import React, { useState } from "react";
import classes from "../../module.css/ManageTransit.module.css";
import { useEffect } from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import Card from "../../tailwindUI/Card";
import Pagination from "../../tailwindUI/Pagination";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Modal from "../../tailwindUI/Modal";
import Input from "../../tailwindUI/Input";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import Table from "../../tailwindUI/Table";
import ErrorMessage from "../../tailwindUI/ErrorMessage";

const ManageTransit = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [truckData, setTruckData] = useState([]);
  const [trainData, setTrainData] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [eta, setEta] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNo, setDriverNo] = useState("");
  const [truckRailNo, setTruckRailNo] = useState("");
  const [lastLocation, setLastLocation] = useState("");
  const [carid, setCarid] = useState("");
  const [count, setCount] = useState("");
  const [allTruckData, setAllTruckData] = useState([]);
  const [allTrainData, setAllTrainData] = useState([]);

  const [apiFailled, setApiFailled] = useState(false);
  const [opentransitinfor, setOpentransitinfor] = useState(false);
  const [opentransitinforTrain, setOpentransitinforTrain] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [getvalueids, setGetvalueids] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  let UserToken = localStorage.getItem("UserToken");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);
  const maxpages = Math.ceil(count / 20);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    getTrainList();
    getTruckList();
    updateTransit();
  }, [page]);

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };



  const getTrainList = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      page: page,

    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockTrainList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setTrainData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const getTruckList = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockTruckList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setTruckData(result.data);
          setCount(result.total_count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const markAsPhyscialApi = (data) => {
    const { truck_num, etaDate } = data;
    // console.log(truck_num, "truck_num");
    // console.log(etaDate, "etaDate");

    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      TruckNumber: truck_num,
      ETADate: etaDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockMarkPhysical",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Status Updated Successfully"
        ) {
          setConfirmation(false);

          setMessage(result.message);
          // getTrainList();
          setApiFailled(false);

          getTruckList();
        } else if (result.status_code === 300 && result.status === "Fail") {
          setConfirmation(false);
          setApiFailled(true);

          setMessage("Truck Number cannot be blank");
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const updateTransit = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      TruckNumber: truckRailNo,
      ETADate: eta,
      DriverName: driverName,
      ContactDetails: driverNo,
      LastLocation: lastLocation,
      CarStockID: carid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockSaveTransit",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setMessage(result.message);
          setApiFailled(false);

          setOpen(false);
          // getTrainList();
          getTruckList();
          setTruckRailNo(truckRailNo);
          setEta(eta);
          setDriverName(driverName);
          setDriverNo(driverNo);

          setLastLocation(lastLocation);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const formValidationOne = () => {
    // const drivernumber = /^[0-9\b]+$/;
    // const drivernameregex = /^[a-zA-Z ]*$/;
    let errors = {};
    let isValid = true;
    if (!eta || eta === "") {
      isValid = false;
      errors.eta = "Please select ETA";
    }
    // if (!driverName || driverName === "") {
    //   isValid = false;
    //   errors.driverName = "Please select driver name";
    // }
    // if (!drivernameregex.test(driverName)) {
    //   isValid = false;
    //   errors.driverName = "Please enter correct driver name";
    // }
    // if (!driverNo || driverNo === "") {
    //   isValid = false;
    //   errors.driverNo = "Please select driver number";
    // } else if (!drivernumber.test(driverNo) || driverNo.length < 10) {
    //   isValid = false;
    //   errors.driverNo = "Please enter correct driver number";
    // }

    if (!truckRailNo || truckRailNo === "") {
      isValid = false;
      errors.truckRailNo = "Please select truck rail no";
    }

    // if (!lastLocation || lastLocation === "") {
    //   isValid = false;
    //   errors.lastLocation = "Please select last location";
    // } else if (!drivernameregex.test(lastLocation)) {
    //   isValid = false;
    //   errors.lastLocation = "Please enter correct last location";
    // }
    setError(errors);
    return isValid;
  };
  // console.log(opentransitinfor, "opentransitinfor");
  // console.log(confirmation, "confirmation");

  const BreadCrumb = {
    pagename: "Manage Transit",
    pagePath: "/edp-manage-transit",
    pagename1: "",
    pagePath1: "",
  };

  const BreadCrumHomeLink = "/edp-dashboard";
  const getids = (value) => {
    setGetvalueids(value);
  };
  const tablecolumns = [
    {
      name: "Model ",
    },

    {
      name: "Variant",
    },
    {
      name: "Colour",
    },

    {
      name: "VIN",
    },
  ];

  return (
    <div>
      {confirmation && (
        <Modal
          setOpen={setConfirmation}
          desc={"Are you sure you want to mark physical?"}
          heading={"Mark Physical"}
          open={confirmation}
          onCancel={() => {
            setConfirmation(false);
          }}
          onClick={() => markAsPhyscialApi(getvalueids)}
          // onClick={() => {
          //   modalProcess();
          // }}
          type="Simple alert"
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {opentransitinfor && (
        <Modal
          type="manage transit info"
          setOpen={setOpentransitinfor}
          open={opentransitinfor}
          onCancel={() => setOpentransitinfor(false)}
        // onClick={() =>
        //   formValidationOne() ? updateTransit() : console.log("err")
        // }
        >
          <Table
            type="stripped rows"
            TableData={allTruckData}
            tableHeading={tablecolumns}
            tabledataType="Manage transit modal data"
          />
        </Modal>
      )}

      {opentransitinforTrain && (
        <Modal
          type="manage transit info"
          setOpen={setOpentransitinforTrain}
          open={opentransitinforTrain}
          onCancel={() => opentransitinforTrain(false)}
        // onClick={() =>
        //   formValidationOne() ? updateTransit() : console.log("err")
        // }
        >
          <Table
            type="stripped rows"
            TableData={allTrainData}
            tableHeading={tablecolumns}
            tabledataType="Manage transit modal data"
          />
        </Modal>
      )}

      {open && (
        <Modal
          type="Manage transit"
          setOpen={setOpen}
          open={open}
          onCancel={() => { setOpen(false); setTruckRailNo(""); setEta("") }}
          onClick={() =>
            formValidationOne() ? updateTransit() : console.log("err")
          }
        >
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left   ">
            <Input
              type={"input with label"}
              label="ETA"
              inputType="date"
              onInput={() => (error.eta = "")}
              value={eta}
              onChange={(e) => setEta(e.target.value)}
              error={error.eta}
              onKeyDown={(e) => e.preventDefault()}

            />
            <span className="error">{error.eta}</span>
          </div>
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left ">
            <Input
              type={"input with label"}
              label="Truck/ Rail Number"
              inputType="text"
              placeholder="Enter truck/ rail number"
              onInput={() => (error.truckRailNo = "")}
              value={truckRailNo}
              onChange={(e) => setTruckRailNo(e.target.value)}
              error={error.truckRailNo}
            />
            <span className="error">{error.truckRailNo}</span>
          </div>
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left ">
            <Input
              type={"input with label"}
              label="Driver Name"
              inputType="text"
              placeholder="Enter driver name"
              // onInput={() => (error.driverName = "")}
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)}
            // error={error.driverName}
            />
            {/* <span className="error">{error.driverName}</span> */}
          </div>
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left ">
            <Input
              type={"input with label"}
              inputType="text"
              maxLength="10"
              label="Driver Number"
              placeholder="Enter driver number"
              // onInput={() => (error.driverNo = "")}
              value={driverNo}
              onChange={(e) => setDriverNo(e.target.value)}
            // error={error.driverNo}
            />
            {/* <span className="error">{error.driverNo}</span> */}
          </div>
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left ">
            <Input
              type={"input with label"}
              inputType="text"
              label="Last Location"
              placeholder="Enter last location"
              // onInput={() => (error.lastLocation = "")}
              value={lastLocation}
              onChange={(e) => setLastLocation(e.target.value)}
            // error={error.lastLocation}
            />
            {/* <span className="error">{error.lastLocation}</span> */}
          </div>
        </Modal>
      )}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      <Navbar navbarType="blue navbar" />

      <div className={classes.maincontainer}>
        <Heading
          headingType="Page Headings"
          headingText={"Manage Transit"}
          BreadCrumb={BreadCrumb}
          BreadCrumHomeLink={BreadCrumHomeLink}
        />

        {loading ? (
          <img
            src="../assets/loadingGif.gif"
            alt="loading"
            className={classes.loader}
          />
        ) : (
          <>

            {truckData?.length > 0 ? (
              <>
                <div className="text-center my-4 font-semibold text-xl">Truck Transit</div>
                <div className={classes.heading}>
                  <Card
                    setOpen={setOpen}
                    truckData={truckData}
                    markAsPhyscialApi={markAsPhyscialApi}
                    // updateTransit={updateTransit}
                    setAllTruckData={setAllTruckData}
                    setEta={setEta}
                    setDriverName={setDriverName}
                    setDriverNo={setDriverNo}
                    setTruckRailNo={setTruckRailNo}
                    setLastLocation={setLastLocation}
                    setCarid={setCarid}
                    setOpentransitinfor={setOpentransitinfor}
                    setConfirmation={setConfirmation}
                    getids={getids}
                  />
                </div>

                {maxpages > 1 && (
                  <div className={classes.pagination}>
                    <Pagination maxPages={maxpages} />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="text-center my-4 font-semibold text-xl">Truck Transit</div>

                <div className={classes.DataNotFound}>
                  <DataNotFound />
                </div>
              </>
            )}

            {trainData?.length > 0 ? (
              <>
                <div className="text-center my-4 font-semibold text-xl">Rail Transit</div>

                <div className={classes.heading}>
                  <Card
                    setOpen={setOpen}
                    cardType="Transit Train List"
                    trainData={trainData}
                    setAllTrainData={setAllTrainData}
                    setOpentransitinforTrain={setOpentransitinforTrain}
                  />
                </div>

                {maxpages > 1 && (
                  <div className={classes.pagination}>
                    <Pagination maxPages={maxpages} />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="text-center my-4 font-semibold text-xl">Rail Transit</div>
                <div className={classes.DataNotFound}>
                  <DataNotFound />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ManageTransit;
