import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import UILibrary from "./Screen/UILibrary";
import PendingBookings from "./Screen/ShowroomManager/PendingBookings";
import ManageTransit from "./Screen/EDP/ManageTransit";
import Login from "./Screen/Login";
import AddStock from "./Screen/EDP/AddStock";
import UpdateStock from "./Screen/EDP/UpdateStock";
import CarInfoPending from "./Screen/EDP/CarInfoPending";
import EdpDashboard from "./Screen/EDP/EdpDashboard";
import ViewStockDetails from "./Screen/EDP/ViewStockDetails";
import ManageModel from "./Screen/EDP/ManageModel";
import ManageDelivery from "./Screen/EDP/ManageDelivery";
import TodaysActivitylog from "./Screen/EDP/TodaysActivitylog";
import ForgetPassword from "./Screen/ForgetPassword";
import ASM from "./Screen/ShowroomManager/ASM";
import RealTimeStockTwo from "./Screen/ShowroomManager/RealTimeStockTwo";
import Dashboard from "./Screen/ShowroomManager/Dashboard";
import ManageExecutive from "./Screen/ShowroomManager/ManageExecutive";
import RealTimeStockOne from "./Screen/ShowroomManager/RealTimeStockOne";
import RealTimeStockThree from "./Screen/ShowroomManager/RealTimeStockThree";
import SearchUpdate from "./Screen/ShowroomManager/SearchUpdate";
import CreatePassword from "./Screen/CreatePassword";
import NewBooking from "./Screen/ShowroomManager/NewBooking";
import UpdateBooking from "./Screen/ShowroomManager/UpdateBooking";
import AllocatedBookings from "./Screen/ShowroomManager/AllocatedBookings";
import DeliveryDueAllotmentPending from "./Screen/ShowroomManager/DeliveryDueAllotmentPending";
import WaitingPeriodAnalysis from "./Screen/ShowroomManager/WaitingPeriodAnalysis";
import CancelledBookings from "./Screen/ShowroomManager/CancelledBookings";
import DeliveredCars from "./Screen/ShowroomManager/DeliveredCars";
import StmDashboard from "./Screen/StockManager/StmDashboard";
import StmAllocationAwaited from "./Screen/StockManager/StmAllocationAwaited";
import EDPRealTimeStockOne from "./Screen/EDP/EDPRealTimeStockOne";
import EDPRealTimeStockTwo from "./Screen/EDP/EDPRealTimeStockTwo";
import EDPRealTimeStockThree from "./Screen/EDP/EDPRealTimeStockThree";
import StmRealTimeStockOne from "./Screen/StockManager/StmRealTimeStockOne";
import StmRealTimeStockTwo from "./Screen/StockManager/StmRealTimeStockTwo";
import StmEDPRealTimeStockThree from "./Screen/StockManager/StmEDPRealTimeStockThree";
import AutoAllocation from "./Screen/StockManager/AutoAllocation";
import ReallocationRequest from "./Screen/StockManager/ReallocationRequest";
import AllocatedVINAwaited from "./Screen/StockManager/AllocatedVINAwaited";
import AutoAllocationCancelled from "./Screen/ShowroomManager/AutoAllocationCancelled";
import ReallocationRequests from "./Screen/ShowroomManager/ReallocationRequests";
import { CountContext } from "./Context/DataCount";
import { useContext } from "react";
import StmAllocationStatus from "./Screen/StockManager/StmAllocationStatus";
import StmBookingCancellationByShowroomManager from "./Screen/StockManager/StmBookingCancellationByShowroomManager";
import StmAutoSuggestOrders from "./Screen/StockManager/StmAutoSuggestOrders";
import StmNotifications from "./Screen/StockManager/StmNotifications";
import Notification from "./Screen/ShowroomManager/Notification";
import EDPNotification from "./Screen/EDP/EDPNotification";
import StmAllocationHistory from "./Screen/StockManager/StmAllocationHistory";
import EDPDeleteCar from "./Screen/EDP/EDPDeleteCar";
import Report1 from "./Screen/ShowroomManager/Report1";
import Report2 from "./Screen/ShowroomManager/Report2";
import Report3 from "./Screen/ShowroomManager/Report3";
import Report4 from "./Screen/ShowroomManager/Report4";
import Report5 from "./Screen/ShowroomManager/Report5";
import Report6 from "./Screen/ShowroomManager/Report6";
import Report7 from "./Screen/ShowroomManager/Report7";
import DashBoardReports from "./Screen/ShowroomManager/DashBoardReports";
import StmTodaysAllocation from "./Screen/StockManager/StmTodaysAllocation";
import StmAllocationStatusReport from "./Screen/StockManager/StmAllocationStatusReport";
import StmDeliveredCars from "./Screen/StockManager/StmDeliveredCars";
import ManageExecutiveEdit from "./Screen/ShowroomManager/ManageExecutiveEdit";
import AsmDashboard from "./Screen/ASM/AsmDashboard";
import AsmRealTimeStockOne from "./Screen/ASM/AsmRealTimeStockOne";
import AsmRealTimeStockTwo from "./Screen/ASM/AsmRealTimeStockTwo";
import ASMUpdate from "./Screen/ShowroomManager/ASMUpdate";
import AdminDashboard from "./Screen/SuperAdmin/AdminDashboard";
import AdminLogin from "./Screen/AdminLogin";
import AdminOtpVerify from "./Screen/AdminOtpVerify";
import AdminViewStockDetail from "./Screen/SuperAdmin/AdminViewStockDetail";
import AdminEdpActivityLog from "./Screen/SuperAdmin/AdminEdpActivityLog";
import AdminSMDeliveredCars from "./Screen/SuperAdmin/AdminSMDeliveredCars";
import AdminSMTodaysAllocation from "./Screen/SuperAdmin/AdminSMTodaysAllocation";
import AdminSMAllStock from "./Screen/SuperAdmin/AdminSMAllStock";
import AdminSRMBookingReport from "./Screen/SuperAdmin/AdminSRMBookingReport";
import AdminSRMBookingMonthly from "./Screen/SuperAdmin/AdminSRMBookingMonthly";
import AdminSRMBookingCancelYear from "./Screen/SuperAdmin/AdminSRMBookingCancelYear";
import AdminSRMBookingCancelMonthly from "./Screen/SuperAdmin/AdminSRMBookingCancelMonthly";
import AdminSRMDeliveryYearly from "./Screen/SuperAdmin/AdminSRMDeliveryYearly";
import AdminSRMReportMonthly from "./Screen/SuperAdmin/AdminSRMReportMonthly";
import AdminSRMDetailBookingReport from "./Screen/SuperAdmin/AdminSRMDetailBookingReport";
import AdminRTSPageOne from "./Screen/SuperAdmin/AdminRTSPageOne";
import AdminRTSPageTwo from "./Screen/SuperAdmin/AdminRTSPageTwo";
import AdminRTSPageThree from "./Screen/SuperAdmin/AdminRTSPageThree";
import AdminDeletedCar from "./Screen/SuperAdmin/AdminDeletedCar";
import AdminAllocationStatus from "./Screen/SuperAdmin/AdminAllocationStatus";
import EDPDeliveredCarsReport from "./Screen/EDP/EDPDeliveredCarsReport";
import FullAllocationReport from "./Screen/StockManager/FullAllocationReport";
import StmHiddenStockReport from "./Screen/StockManager/StmHiddenStockReport";
import AllocationAwaited from "./Screen/CBT/AllocationAwaited";
import BookingCancellation from "./Screen/CBT/BookingCancellation";
import RecentlyAllocatedCars from "./Screen/CBT/RecentlyAllocatedCars";
import ConfirmedBookingCancellations from "./Screen/CBT/ConfirmedBookingCancellations";
import ConfirmBookingCancellation from "./Screen/StockManager/ConfirmBookingCancellation";
import CBTDashboard from "./Screen/CBT/CBTDashboard";
import CBTDashboardReports from "./Screen/CBT/CBTDashboardReports";
import GMDashboard from "./Screen/GeneralManager/GMDashboard";
import GMBookingCancellation from "./Screen/GeneralManager/GMBookingCancellation";
import AsmExecWiseAllocation from "./Screen/GeneralManager/AsmExecWiseAllocation";

const App = () => {
  const UserRole = localStorage.getItem("UserRole");
  const UserToken = localStorage.getItem("UserToken");
  const { getCountHandler, getAllocationCountHandler } =
    useContext(CountContext);
  // const navigate = useNavigate();
  useEffect(() => {
    countData();
  }, []);

  const PublicRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if (!localStorage.getItem("UserLoggedin")) {
        navigate("/login");
      }
    }, []);
    return <></>;
  };

  const navigateHandler = () => {
    switch (UserRole) {
      case "ShowroomManager":
        return "/sm-dashboard";
      case "EDP":
        return "/edp-dashboard";
      case "StockManager":
        return "/stm-dashboard";
      case "ASM":
        return "/asm-dashboard";
      case "GeneralManager":
        return "/gm-dashboard";
      case "CBT":
        return "/cbt-dashboard";
      default:
        console.log("UserRole not found");
    }
  };

  useEffect(() => {
    if (UserRole === "StockManager") {
      allocationCountApi();
    }
  }, []);

  const countData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/BookingCountApi",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status == "Success") {
          getCountHandler(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const allocationCountApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMMenuCount",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          getAllocationCountHandler(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Router>
      <Routes>
        <Route path="/ui-library" element={<UILibrary />} />
        <Route
          path="/"
          element={
            <Navigate to={UserRole ? navigateHandler() : "/login"} replace />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/creta" element={<AdminLogin />} />
        <Route path="/creta-verify-otp" element={<AdminOtpVerify />} />
        {/* place all EDP routes here  */}

        {/* <Route path="/edp-manage-model/:modelid" element={<ModelUpdate />} /> */}
        <Route
          path="/edp-dashboard"
          element={
            <>
              <PublicRedirect />
              <EdpDashboard />
            </>
          }
        />
        <Route
          path="/edp-notification"
          element={
            <>
              <PublicRedirect />
              <EDPNotification />
            </>
          }
        />
        <Route
          path="/edp-add-stock"
          element={
            <>
              <PublicRedirect />
              <AddStock />
            </>
          }
        />
        <Route
          path="/edp-update-stock/:carstockid"
          element={
            <>
              <PublicRedirect />
              <UpdateStock />
            </>
          }
        />
        <Route
          path="/edp-car-info-pending"
          element={
            <>
              <PublicRedirect />
              <CarInfoPending />
            </>
          }
        />
        <Route
          path="/edp-stock-details"
          element={
            <>
              <PublicRedirect />
              <ViewStockDetails />
            </>
          }
        />
        {/* <Route
          path="/edp-all-stocks"
          element={
            <>
              <PublicRedirect />
              <AllStocks />
            </>
          }
        /> */}
        <Route
          path="/edp-manage-model"
          element={
            <>
              <PublicRedirect />
              <ManageModel />
            </>
          }
        />
        <Route
          path="/edp-manage-delivery"
          element={
            <>
              <PublicRedirect />
              <ManageDelivery />
            </>
          }
        />
        <Route
          path="/edp-manage-transit"
          element={
            <>
              <PublicRedirect />
              <ManageTransit />
            </>
          }
        />
        <Route
          path="/edp-today-activity-log"
          element={
            <>
              <PublicRedirect />
              <TodaysActivitylog />
            </>
          }
        />
        <Route
          path="/edp-real-time-stock-one"
          element={
            <>
              <PublicRedirect />
              <EDPRealTimeStockOne />
            </>
          }
        />
        <Route
          path="/edp-real-time-stock-two/:modelName"
          element={
            <>
              <PublicRedirect />
              <EDPRealTimeStockTwo />
            </>
          }
        />
        <Route
          path="/edp-real-time-stock-three/:modelName/:variant"
          element={
            <>
              <PublicRedirect />
              <EDPRealTimeStockThree />
            </>
          }
        />
        <Route
          path="/edp-delete-car"
          element={
            <>
              <PublicRedirect />
              <EDPDeleteCar />
            </>
          }
        />

        <Route
          path="/edp-delivered-cars"
          element={
            <>
              <PublicRedirect />
              <EDPDeliveredCarsReport />
            </>
          }
        />

        {/* place all EDP routes here  */}

        {/* place all showroom manager routes here  */}
        <Route
          path="/asm"
          element={
            <>
              <PublicRedirect />
              <ASM />
            </>
          }
        />
        <Route
          path="/asm/:AsmID"
          element={
            <>
              <PublicRedirect />
              <ASMUpdate />
            </>
          }
        />
        <Route
          path="/sm-real-time-stock-two/:modelName"
          element={
            <>
              <PublicRedirect />
              <RealTimeStockTwo />
            </>
          }
        />
        <Route
          path="/sm-auto-allocation-cancelled"
          element={
            <>
              <PublicRedirect />
              <AutoAllocationCancelled />
            </>
          }
        />
        <Route
          path="/sm-reallocation-requests"
          element={
            <>
              <PublicRedirect />
              <ReallocationRequests />
            </>
          }
        />
        <Route
          path="/sm-dashboard"
          element={
            <>
              <PublicRedirect />
              <Dashboard />
            </>
          }
        />
        <Route
          path="/sm-dashboard-report/:reportname"
          element={
            <>
              <PublicRedirect />
              <DashBoardReports />
            </>
          }
        />
        <Route
          path="/sm-notifications"
          element={
            <>
              <PublicRedirect />
              <Notification />
            </>
          }
        />
        <Route
          path="/sm-manage-executive"
          element={
            <>
              <PublicRedirect />
              <ManageExecutive />
            </>
          }
        />
        <Route
          path="/sm-manage-executive/:asmid"
          element={
            <>
              <PublicRedirect />
              <ManageExecutiveEdit />
            </>
          }
        />
        <Route
          path="/sm-real-time-stock-one"
          element={
            <>
              <PublicRedirect />
              <RealTimeStockOne />
            </>
          }
        />
        <Route
          path="/sm-delievred-cars"
          element={
            <>
              <PublicRedirect />
              <DeliveredCars />
            </>
          }
        />

        <Route
          path="/sm-real-time-stock-three/:modelName/:variant"
          element={
            <>
              <PublicRedirect />
              <RealTimeStockThree />
            </>
          }
        />
        <Route
          path="/sm-search-update"
          element={
            <>
              <PublicRedirect />
              <SearchUpdate />
            </>
          }
        />
        <Route
          path="/sm-pending-bookings"
          element={
            <>
              <PublicRedirect />
              <PendingBookings />
            </>
          }
        />
        <Route
          path="/sm-new-booking"
          element={
            <>
              <PublicRedirect />
              <NewBooking />
            </>
          }
        />

        <Route
          path="/sm-cancelled-bookings"
          element={
            <>
              <PublicRedirect />
              <CancelledBookings />
            </>
          }
        />
        <Route
          path="/sm-allocated-bookings"
          element={
            <>
              <PublicRedirect />
              <AllocatedBookings />
            </>
          }
        />
        <Route
          path="/sm-allotment-pending"
          element={
            <>
              <PublicRedirect />
              <DeliveryDueAllotmentPending />
            </>
          }
        />
        <Route
          path="/sm-waiting-period-analysis"
          element={
            <>
              <PublicRedirect />
              <WaitingPeriodAnalysis />
            </>
          }
        />
        <Route
          path="/sm-update-booking/:bookingId"
          element={
            <>
              <PublicRedirect />
              <UpdateBooking />
            </>
          }
        />
        <Route
          path="/sm-booking-report-cumulative"
          element={
            <>
              <PublicRedirect />
              <Report1 />
            </>
          }
        />
        <Route
          path="/sm-delivery-report-yearly"
          element={
            <>
              <PublicRedirect />
              <Report2 />
            </>
          }
        />
        <Route
          path="/sm-delivery-report-monthly"
          element={
            <>
              <PublicRedirect />
              <Report3 />
            </>
          }
        />
        <Route
          path="/sm-booking-report-monthly"
          element={
            <>
              <PublicRedirect />
              <Report4 />
            </>
          }
        />
        <Route
          path="/sm-booking-cancel-yearly"
          element={
            <>
              <PublicRedirect />
              <Report5 />
            </>
          }
        />
        <Route
          path="/sm-booking-cancel-monthly"
          element={
            <>
              <PublicRedirect />
              <Report6 />
            </>
          }
        />
        <Route
          path="/sm-detailed-booking-report"
          element={
            <>
              <PublicRedirect />
              <Report7 />
            </>
          }
        />
        {/* place all showroom manager routes here */}

        {/* place all allocation manager routes here */}
        <Route
          path="/stm-dashboard"
          element={
            <>
              <PublicRedirect />
              <StmDashboard />
            </>
          }
        />
        <Route
          path="/gm-dashboard"
          element={
            <>
              <PublicRedirect />
              <GMDashboard />
            </>
          }
        />
        <Route
          path="/booking-cancellations-by-showroom-manager"
          element={
            <>
              <PublicRedirect />
              <GMBookingCancellation />
            </>
          }
        />
           <Route
          path="/gm-detailed-allocation-report"
          element={
            <>
              <PublicRedirect />
              <AsmExecWiseAllocation />
            </>
          }
        />
        <Route
          path="/stm-hidden-stock-report"
          element={
            <>
              <PublicRedirect />
              <StmHiddenStockReport />
            </>
          }
        />
        <Route
          path="/stm-notifications"
          element={
            <>
              <PublicRedirect />
              <StmNotifications />
            </>
          }
        />
        <Route
          path="/stm-allocation-awaited"
          element={
            <>
              <PublicRedirect />
              <StmAllocationAwaited />
            </>
          }
        />
        <Route
          path="/confirm-booking-cancellation-by-cbt"
          element={
            <>
              <PublicRedirect />
              <ConfirmBookingCancellation />
            </>
          }
        />
        <Route
          path="/stm-auto-allocation"
          element={
            <>
              <PublicRedirect />
              <AutoAllocation />
            </>
          }
        />
        <Route
          path="/stm-re-allocation-request"
          element={
            <>
              <PublicRedirect />
              <ReallocationRequest />
            </>
          }
        />
        <Route
          path="/stm-allocated-vin-awaited"
          element={
            <>
              <PublicRedirect />
              <AllocatedVINAwaited />
            </>
          }
        />
        <Route
          path="/stm-real-time-stock-one"
          element={
            <>
              <PublicRedirect />
              <StmRealTimeStockOne />
            </>
          }
        />
        <Route
          path="/stm-real-time-stock-two/:modelName"
          element={
            <>
              <PublicRedirect />
              <StmRealTimeStockTwo />
            </>
          }
        />
        <Route
          path="/stm-real-time-stock-three/:modelName/:variant"
          element={
            <>
              <PublicRedirect />
              <StmEDPRealTimeStockThree />
            </>
          }
        />
        <Route
          path="/stm-allocation-status"
          element={
            <>
              <PublicRedirect />
              <StmAllocationStatus />
            </>
          }
        />
        <Route
          path="/stm-booking-cancellation-by-showroom-manager"
          element={
            <>
              <PublicRedirect />
              <StmBookingCancellationByShowroomManager />
            </>
          }
        />
        <Route
          path="/stm-auto-suggest-orders"
          element={
            <>
              <PublicRedirect />
              <StmAutoSuggestOrders />
            </>
          }
        />
        <Route
          path="/stm-allocation-history"
          element={
            <>
              <PublicRedirect />
              <StmAllocationHistory />
            </>
          }
        />
        <Route
          path="/stm-todays-allocation"
          element={
            <>
              <PublicRedirect />
              <StmTodaysAllocation />
            </>
          }
        />
        <Route
          path="/stm-allocation-status-report"
          element={
            <>
              <PublicRedirect />
              <StmAllocationStatusReport />
            </>
          }
        />
        <Route
          path="/stm-delivered-cars"
          element={
            <>
              <PublicRedirect />
              <StmDeliveredCars />
            </>
          }
        />

        <Route
          path="/stm-detailed-allocation-report"
          element={
            <>
              <PublicRedirect />
              <FullAllocationReport />
            </>
          }
        />

        {/* place all allocation manager routes here */}

        {/* place all asm  routes here */}

        <Route
          path="/asm-dashboard"
          element={
            <>
              <PublicRedirect />
              <AsmDashboard />
            </>
          }
        />
        <Route
          path="/asm-real-time-stock-one/:modelName"
          element={
            <>
              <PublicRedirect />
              <AsmRealTimeStockOne />
            </>
          }
        />
        <Route
          path="/asm-real-time-stock-three/:modelName/:variant"
          element={
            <>
              <PublicRedirect />
              <AsmRealTimeStockTwo />
            </>
          }
        />

        <Route
          path="/creta-dashboard"
          element={
            <>
              <PublicRedirect />
              <AdminDashboard />
            </>
          }
        />
        <Route
          path="/creta-view-stock-detail"
          element={
            <>
              <PublicRedirect />
              <AdminViewStockDetail />
            </>
          }
        />
        <Route
          path="/creta-activity-log"
          element={
            <>
              <PublicRedirect />
              <AdminEdpActivityLog />
            </>
          }
        />
        <Route
          path="/creta-delivered-cars"
          element={
            <>
              <PublicRedirect />
              <AdminSMDeliveredCars />
            </>
          }
        />
        <Route
          path="/creta-todays-allocation"
          element={
            <>
              <PublicRedirect />
              <AdminSMTodaysAllocation />
            </>
          }
        />
        <Route
          path="/creta-all-stock-details"
          element={
            <>
              <PublicRedirect />
              <AdminSMAllStock />
            </>
          }
        />
        <Route
          path="/creta-booking-report-cumulative"
          element={
            <>
              <PublicRedirect />
              <AdminSRMBookingReport />
            </>
          }
        />
        <Route
          path="/creta-booking-report-monthly"
          element={
            <>
              <PublicRedirect />
              <AdminSRMBookingMonthly />
            </>
          }
        />
        <Route
          path="/creta-booking-cancel-yearly"
          element={
            <>
              <PublicRedirect />
              <AdminSRMBookingCancelYear />
            </>
          }
        />
        <Route
          path="/creta-booking-cancel-monthly"
          element={
            <>
              <PublicRedirect />
              <AdminSRMBookingCancelMonthly />
            </>
          }
        />
        <Route
          path="/creta-delivery-report-yearly"
          element={
            <>
              <PublicRedirect />
              <AdminSRMDeliveryYearly />
            </>
          }
        />
        <Route
          path="/creta-delivery-report-monthly"
          element={
            <>
              <PublicRedirect />
              <AdminSRMReportMonthly />
            </>
          }
        />
        <Route
          path="/creta-detailed-booking-report"
          element={
            <>
              <PublicRedirect />
              <AdminSRMDetailBookingReport />
            </>
          }
        />
        <Route
          path="/creta-real-time-stock-one"
          element={
            <>
              <PublicRedirect />
              <AdminRTSPageOne />
            </>
          }
        />
        <Route
          path="/creta-real-time-stock-two/:modelName"
          element={
            <>
              <PublicRedirect />
              <AdminRTSPageTwo />
            </>
          }
        />
        <Route
          path="/creta-real-time-stock-three/:modelName/:variant"
          element={
            <>
              <PublicRedirect />
              <AdminRTSPageThree />
            </>
          }
        />
        <Route
          path="/creta-delete-car"
          element={
            <>
              <PublicRedirect />
              <AdminDeletedCar />
            </>
          }
        />
        <Route
          path="/creta-allocation-status"
          element={
            <>
              <PublicRedirect />
              <AdminAllocationStatus />
            </>
          }
        />
        <Route
          path="/cbt-dashboard"
          element={
            <>
              <PublicRedirect />
              <CBTDashboard />
            </>
          }
        />
        <Route
          path="/cbt-dashboard-report/:reportname"
          element={
            <>
              <PublicRedirect />
              <CBTDashboardReports />
            </>
          }
        />
        <Route
          path="/cbt-allocation-awaited"
          element={
            <>
              <PublicRedirect />
              <AllocationAwaited />
            </>
          }
        />
        <Route
          path="/cbt-booking-cancellation"
          element={
            <>
              <PublicRedirect />
              <BookingCancellation />
            </>
          }
        />
        <Route
          path="/cbt-recently-allocatedCars"
          element={
            <>
              <PublicRedirect />
              <RecentlyAllocatedCars />
            </>
          }
        />
        <Route
          path="/cbt-confirmed-booking-cancellations"
          element={
            <>
              <PublicRedirect />
              <ConfirmedBookingCancellations />
            </>
          }
        />
      </Routes>
    </Router>
  );
};
export default App;
