import React from "react";
import Button from "../tailwindUI/Button";
import DateFilter from "./DateFilter";

function CBTTAbleHeader(props) {
  const callApi = (e) => {
    if (e.key === "Enter" && props.searchTerm.length > 2) {
      props.apicall();
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-white sm:pb-6 w-full sm:pt-4 py-2 relative">
      
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl text-gray-900 mb-2 font-bold">
            {props.tableHeading}
          </h1>
     <div className="sm:flex w-full gap-4 mb-2">
     <div className="sm:w-1/2 flex mb-4 sm:mb-0 flex-row gap-4 border p-2 rounded">
            <input
              type="search"
              className={` w-full px-2 py-px sm:text-sm text-xs outline-none`}
              placeholder={
                props.placeholderType
                  ? props.placeholderType
                  : "Enter For Ex:-(Vin No, Order Id, Model, Variant, Fuel Type, Colour, Status)"
              }
              value={props.searchTerm}
              onChange={(e) => {
                props.setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => callApi(e)}
            />
            <Button
              type={"smallButton"}
              buttonText={props.buttonText}
              onClick={() => {
                props.apicall();
              }}
            />
          </div>
     <div className="flex sm:w-1/2">   <DateFilter   page={props.page}   setTabType={props.setTabType} setEnd={props.setEndDate}  setStart={props.setStartDate}  /></div>

     </div>
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"> */}
        <div className="sm:absolute sm:right-6 sm:bottom-2 mt-2">
          <p className="text-sm italic text-gray-500">
            {props.condition === "managedevlivery" ? "" : <>{props.pageResult} of {props.totalResult} results{" "}</>}
          </p>
        </div>
      </div>


    </div>
  );
}

export default CBTTAbleHeader;
