import React from 'react'
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const AddRemarkInput = (props) => {
    const cancelButtonRef = useRef(null);
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={props.setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden w-full lg:w-[400px] rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 max-w-lg p-4 ">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div>
                                        <label
                                            htmlFor="first-name"
                                            className=" text-sm font-raleway font-medium leading-6 text-gray-900 flex"
                                        >
                                            Add/Update Remark<span className="text-red-600 text-sm mt-1">*</span>
                                        </label>
                                        <div className="mt-2.5">
                                            <textarea
                                                type="text"
                                                value={props.value}
                                                onChange={props.onChange}
                                                rows={4}
                                                // cols={10}
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 px-3.5 py-2    shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#47535C] outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                        {props.error && (
                                            <div className="text-[#812B19] px-2 py-1 text-sm bg-red-50 mt-1 rounded-md flex gap-1 items-center">
                                                {/* <ExclamationTriangleIcon className="w-4 h-4" /> */}
                                                {props.error}
                                            </div>
                                        )}
                                    </div>
                                    <div className='pt-8 flex justify-end'>
                                        {props.loading?
                                        <div
                                      
                                        className="mt-3  w-full  rounded-md border border-indigo-500 bg-indigo-500 px-4 py-2 text-base font-medium text-white hover:text-indigo-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        
                                        ref={cancelButtonRef}
                                    >
                                       Loading...
                                    </div>
                                        :<button
                                            type="button"
                                            className="mt-3  w-full  rounded-md border border-indigo-500 bg-indigo-500 px-4 py-2 text-base font-medium text-white hover:text-indigo-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={props.onClick}
                                            ref={cancelButtonRef}
                                        >
                                            Submit
                                        </button>}
                                        <button
                                            type="button"
                                            className="mt-3  w-full  rounded-md hover:text-red-600 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={props.onClose}
                                            ref={cancelButtonRef}
                                        >
                                            Close
                                        </button>
                                    </div>
                                   {props.success&& <div className='text-base font-medium text-green-600 pt-8'>
                                         Booking Cancellation Successful.
                                    </div>}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AddRemarkInput