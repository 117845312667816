import React from "react";
import { useState } from "react";
import LoginInput from "../tailwindUI/LoginInput";
import { useNavigate } from "react-router-dom";

const CreatePassword = () => {
    // const [mobileNum, setMobileNum] = useState("");
    const [otpVerification, setOtpVerification] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState({});
    const [successMsg, setSuccessMsg] = useState('');
    const [resendOtpMsg, setResendOtpMsg] = useState('');

    let navigate = useNavigate();
    const mobileNum = localStorage.getItem('Phone');

    console.log("data", mobileNum)

    const otpVerify = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            Mobile: mobileNum,
            Otp: otpVerification,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/common/VerifyOtp",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "SUCCESS" &&
                    result.message === "OTP Verified successfully!"
                ) {
                } else if (
                    result.status === "fail" &&
                    result.status_code === 300 &&
                    result.message === "data not found "
                ) {
                    // setLoginFailed(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const resendOtp = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            Mobile: mobileNum,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/common/ResendOtp",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "SUCCESS" &&
                    result.message === "OTP sent successfully"
                ) {
                    setResendOtpMsg(result.message);

                } else if (
                    result.status === "FAILED" &&
                    result.message === "no user found"
                ) {
                    setResendOtpMsg(result.message);
                    // setLoginFailed(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const ResetPassword = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            Mobile: mobileNum,
            OTP: otpVerification,
            Password: newPassword,
            ConfirmPassword: confirmNewPassword,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/common/ResetPassword",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "SUCCESS" &&
                    result.message === "Password change successfully"
                ) {

                    setSuccessMsg(result.message)
                    setTimeout(() => { navigate("/login") }, 2000)

                } else if (
                    result.status === "FAILED"
                    // && result.message === "no user found"
                ) {

                    setSuccessMsg(result.message)
                    // setLoginFailed(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const formValidation = () => {
        let errors = {};
        let isValid = true;
        if (!otpVerification || otpVerification === "") {
            errors.otpVerification = "Enter otp send to your mobile number";
            isValid = false;
        }
        if (!newPassword || newPassword === "") {
            errors.newPassword = "Enter your new password";
            isValid = false;
        }
        if (!confirmNewPassword || confirmNewPassword === "") {
            errors.confirmNewPassword = "Confirm your new password";
            isValid = false;
        }
        if (newPassword !== confirmNewPassword) {
            errors.confirmNewPassword = "Please enter correct password";
            isValid = false;
        }
        else {
            console.log("true");
        }
        setError(errors);
        return isValid;
    };

    const otpSuccessMessage = () => {
        setTimeout(() => {
            setResendOtpMsg("");
        }, 4000);
        return resendOtpMsg;
    };

    return (
        <div>
            <LoginInput
                Type="Verification code"
                otpVerify={otpVerify}
                otpVerification={otpVerification}
                setOtpVerification={setOtpVerification}
                confirmNewPassword={confirmNewPassword}
                newPassword={newPassword}

                ResetPassword={ResetPassword}
                resendOtp={resendOtp}
                formValidation={formValidation}
                onChangeConfirmPass={(e) => {
                    setConfirmNewPassword(e.target.value);
                    error.confirmNewPassword = ""
                }}
                onChangeNewPass={(e) => {
                    setNewPassword(e.target.value);
                    error.newPassword = ""
                }}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
                errorOtpVerification={error.otpVerification}
                errorNewPassword={error.newPassword}
                errorConfirmNewPassword={error.confirmNewPassword}
                errorCnfmPassword={error.CnfmPassword}
                resendOtpMsg={resendOtpMsg}
                otpSuccessMessage={otpSuccessMessage}
                error={error}
            />
        </div>
    );
};

export default CreatePassword;
