import React, { useEffect, useState } from 'react'
import SmWrapper from '../../Components/SmWrapper'
import Heading from '../../tailwindUI/Heading'
import classes from "../../module.css/Dashboard.module.css";
import Button from '../../tailwindUI/Button';
import * as XLSX from "xlsx/xlsx";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../tailwindUI/ErrorMessage';

const Report4 = () => {
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        SRMBookingReportMonthlyApi();
    }, []);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/login", { replace: true });
    };

    const SRMBookingReportMonthlyApi = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
            'Authorization',
            'Token 8cb23c1e5efb27dbadefabe601226a4264f59daa'
        );
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
            UserToken: localStorage.getItem("UserToken"),
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(
            'https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingReportMonthaly',
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login" ||
                    result.message === "Please login with a different account"
                ) {
                    LogOut();
                }
                else if (result.status_code === 200 && result.status === "Success") {
                    setData(result.data)
                }
                else if (result.status_code === 400) {
                    setErrorMessage("Something went wrong please contact support !");
                }
            })
            .catch((error) => { console.log('error', error); setErrorMessage("Something went wrong please contact support !"); })
            .finally(() => setLoading(false))
    };

    const models = data?.map((item) => {
        return item.modelarray?.map(item => item.Model);
    })


    const handleExport = () => {
        const worksheet = XLSX.utils.table_to_sheet(tableRef.current);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'table.xlsx');
    }
    return (
        <SmWrapper>
            {errorMessage && <ErrorMessage message={errorMessage} />}

            <div className="mb-16">
                <div className={classes.heading}>
                    <Heading
                        headingType={"Section Headings"}
                        BreadCrumHomeLink={"/sm-dashboard"}
                        headingText={"Booking Report Monthly"}
                        subheadingText={localStorage.getItem("Location")}
                    />

                    {loading ?
                        <div className={classes.loader}>
                            <img src="assets/loadingGif.gif" alt="loading" />
                        </div> :
                        <>
                            <div className='flex justify-between items-center  bg-white p-2 my-2 border border-gray-300'>
                                <div>Total Result's ({data.length})</div>

                                <Button type="redFilled" buttonText="Export Excel" onClick={handleExport} />

                            </div>

                            <div className="overflow-x-auto relative">

                                <table className="min-w-full divide-y divide-gray-300 border-collapse border border-gray-300" ref={tableRef}>
                                    <thead className="bg-white">
                                        <tr className="divide-x divide-gray-200">
                                            <th className="py-2 pl-2 pr-2 text-sm font-semibold text-gray-900  text-center sticky-col first-col"
                                                rowspan="2">ASM</th>
                                            <th className="py-2 pl-2 pr-2 text-sm font-semibold text-gray-900  text-center sticky-col second-col"
                                                rowspan="2">Executive</th>
                                            {
                                                models[0]?.map((item, key) => {
                                                    return <th key={key} className="py-2 pl-2 pr-2 text-sm font-semibold text-gray-900  text-center" colspan="3" rowspan="1">{item}</th>

                                                })
                                            }
                                        </tr>
                                        <tr >
                                            {
                                                models[0]?.map((item, key) => {
                                                    return <>
                                                        <th key={key} className="py-2 pl-2 pr-2 text-sm font-semibold  border-l border-t text-gray-900  text-center"
                                                            rowspan="1">Booking</th>
                                                        <th className="py-2 pl-2 pr-2 text-sm font-semibold text-gray-900 border-t text-center"
                                                            rowspan="1">Allocated</th>
                                                        <th className="py-2 pl-2 pr-2 text-sm font-semibold  border-r border-t text-gray-900  text-center"
                                                            rowspan="1">Delivered</th>
                                                    </>

                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((item, key) => {
                                            return <>
                                                <tr className="bg-white" key={key}>
                                                    <td className="whitespace-nowrap p-1 text-center text-sm text-gray-900 sticky-col first-col">{item.AsmName}</td>
                                                    <td className="whitespace-nowrap p-1 text-center text-sm text-gray-900 sticky-col second-col">{item.Executive}</td>
                                                    {item.modelarray?.map((item1, key) => {
                                                        return <>
                                                            <td key={key} className="whitespace-nowrap p-1 text-center text-sm text-gray-900">{item1.Booking}</td>
                                                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-900">{item1.Allocated}</td>
                                                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-900">{item1.Delivered}</td>
                                                        </>
                                                    })}
                                                </tr>
                                            </>
                                        })}
                                    </tbody>
                                </table>

                            </div>

                        </>
                    }
                </div>
            </div>
        </SmWrapper >
    )
}

export default Report4