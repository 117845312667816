import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import classes from "./ExcelDownload.module.css"

const ExcelDownload = ({ csvData, fileName, className }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <button className={className ? className : 'px-2 py-1 border bg-gray-300 rounded'} title='click here to download excel' onClick={(e) => exportToCSV(csvData, fileName)}>Export excel</button>
    )
}
export default ExcelDownload