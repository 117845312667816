import React, { useState, useEffect } from "react";
import ManageForm from "../../Components/ManageForm";
import Heading from "../../tailwindUI/Heading";
import Table from "../../tailwindUI/Table";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/ManageModel.module.css";
import Modal from "../../tailwindUI/Modal";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import Pagination from "../../tailwindUI/Pagination";

function ManageModel(props) {
  let navigate = useNavigate();
  const columns = ["Model Name", "Variant", "Colour", "Fuel Type", ""];

  const [tabledata, setTabledata] = useState([]);
  const [error, setError] = useState({});
  const [updateData, setUpdateData] = useState("");
  const [action, setAction] = useState(0);
  const [message, setMessage] = useState("");
  const [maxpages, setMaxpages] = useState(1);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const getModalList = async () => {
    let fetchedData = await fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPModelList",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body: JSON.stringify({
          UserToken: localStorage.getItem("UserToken"),
          page: page,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status_code === 200 && parseData.status === "Success") {
      if (typeof parseData.data === "object") {
        if (parseData.data.length > 0) {
          setTabledata(parseData.data);
          setMaxpages(Math.ceil(parseData.total_count / 20));
        }
      }
    }
    if (
      parseData.status_code === 200 &&
      parseData.status === "Success" &&
      parseData.data ===
        "Invalid User Token, The token may have expired, You may need to re-login" &&
      parseData.message ===
        "Invalid User Token, The token may have expired, You may need to re-login"
    ) {
      LogOut();
    }
  };

  const addModel = async (
    modelName,
    colorGroup,
    variantGroup,
    fuelType,
    imgLink
  ) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );

    var formdata = new FormData();
    formdata.append("UserToken", localStorage.getItem("UserToken"));
    formdata.append("FuelType", fuelType);
    formdata.append("CarColour", colorGroup);
    formdata.append("CarModelName", modelName);
    formdata.append("CarVariant", variantGroup);
    formdata.append("CarImage", imgLink);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    let fetchedData = await fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPModelAdd",
      requestOptions
    );

    let parseData = await fetchedData.json();

    if (parseData.status_code === 200 && parseData.status === "Success") {
      setMessage(parseData.message);
      getModalList();
    } else if (parseData.status_code !== 200 && parseData.status === "Fail") {
    }
  };

  const updateModel = (
    modelName,
    colorGroup,
    variantGroup,
    fuelType,
    imgLink,
    modelId
  ) => {
    setUpdateData("");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );

    var formdata = new FormData();
    formdata.append("UserToken", localStorage.getItem("UserToken"));
    formdata.append("CarImage", imgLink);
    formdata.append("CarID", modelId);
    formdata.append("CarVariant", variantGroup);
    formdata.append("CarColour", colorGroup);
    formdata.append("CarModelName", modelName);
    formdata.append("FuelType", fuelType);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPModelUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setMessage(result.message);
          getModalList();
        } else {
        }
      })
      .catch((error) => console.log("error", error));
    setAction(0);
  };

  const getSoloModel = async (modelId) => {
    let fetchedData = await fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPModelGetDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body: JSON.stringify({
          CardbID: modelId,
          UserToken: localStorage.getItem("UserToken"),
        }),
      }
    );

    let parseData = await fetchedData.json();
    setError({});
    window.scrollTo(0, 150);
    setUpdateData(parseData.data);
    setAction(1);
  };

  const formValidation = (
    modelName,
    colorGroup,
    variantGroup,
    fuelType,
    imgLink
  ) => {
    let errors = {};
    let isValid = true;

    if (!modelName || modelName === "") {
      isValid = false;
      errors.modelName = "Please insert model name";
    }

    if (!colorGroup || colorGroup.length === 0) {
      isValid = false;
      errors.colorName = "Please insert colour names";
    }

    if (!variantGroup || variantGroup.length === 0) {
      isValid = false;
      errors.variantName = "Please insert variant name";
    }

    if (!fuelType || fuelType === "") {
      isValid = false;
      errors.fuelType = "Please select a fuel type";
    }

    if (!imgLink || imgLink.length === 0) {
      isValid = false;
      errors.imgLink = "Please upload model image";
    }

    setError(errors);
    return isValid;
  };

  useEffect(() => {
    getModalList();
  }, [page]);

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };
  const BreadCrumb = {
    pagename: "Manage Models",
    pagePath: "/edp-manage-model",
    pagename1: "",
    pagePath1: "",
  };

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
        />
      )}
      <Navbar navbarType={"blue navbar"} />
      <div className={classes.maincontainer}>
        <div className="mb-4">
          <Heading
            headingType={"Page Headings"}
            headingText={"Manage Models"}
            BreadCrumb={BreadCrumb}
            BreadCrumHomeLink={"/edp-dashboard"}
          />
        </div>
        <div className={classes.heading}>
          <div className={classes.formSpace}>
            <ManageForm
              formHeading={"Master Models Creation"}
              getModalList={getModalList}
              formValidation={formValidation}
              addModel={addModel}
              updateModel={updateModel}
              error={error}
              updateData={updateData}
              action={action}
              setAction={setAction}
              actionName={action === 0 ? "Save" : "Update"}
            />
            {tabledata.length > 0 ? (
              <>
                <div className={classes.tableCover}>
                  <Table
                    type="modalList"
                    TableData={tabledata}
                    columnList={columns}
                    getSoloModel={getSoloModel}
                  />
                </div>

                {maxpages > 1 && (
                  <div className={classes.pagination}>
                    <Pagination maxPages={maxpages} />
                  </div>
                )}
              </>
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageModel;
