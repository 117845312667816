import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfermationModal from "../../Components/ConfermationModal";
import SmWrapper from "../../Components/SmWrapper";
import Button from "../../tailwindUI/Button";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import Pagination from "../../tailwindUI/Pagination";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Table from "../../tailwindUI/Table";
import classes from "../../module.css/ASM.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";

const ASM = () => {
  const [asmData, setAsmData] = useState([]);
  const [name, setName] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [asmLocation, setLocation] = useState(localStorage.getItem("Location"));
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [loading, setLoading] = useState("");
  const [count, setCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const UserToken = localStorage.getItem("UserToken");
  const Location = localStorage.getItem('Location')
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(count / 20);
  const asmTableHeading = [
    { name: "Name" },
    { name: "Mobile" },
    { name: "Email" },
    { name: "Location" },
    { name: "Remove" },
    { name: "Password" }
  ];
  const emailRegex =
    /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let alphabetsRegex = /^[A-Za-z]+$/;

  const formValidation = () => {
    let errors = {};
    let isValid = true;
    // if (!email || email === "") {
    //   errors.email = "Please enter email address";
    //   isValid = false;
    // } 
    if (email && !emailRegex.test(email)) {
      isValid = false;
      errors.email = "Please enter valid email address";
    }
    if (!name || name === "") {
      errors.name = "Please enter Name";
      isValid = false;
    }
    //  else if (!alphabetsRegex.test(name)) {
    //   errors.name = "Please enter alphabets only";
    //   isValid = false;
    // }
    // if (!location || location === "") {
    //   errors.location = "Please Select your location";
    //   isValid = false;
    // }
    if (!password || password === "") {
      errors.password = "Please enter password";
      isValid = false;
    }
    if (phone.length < 10) {
      errors.phone = "Please enter valid phone no";
      isValid = false;
    }
    if (phone === "" || !phone) {
      errors.phone = "Please Enter phone number";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setPhone(e.target.value)
      : console.log("");
  };

  const onlyAlphabetsName = (e) => {
    var reg = /^[a-zA-Z][a-zA-Z ]*$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setName(e.target.value)
      : console.log("");
  };

  useEffect(() => {
    AsmList();
  }, [page]);
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate('/login', { replace: true })
  }
  const AsmList = () => {
    setLoading("getAsm");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      content: 20,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMASMList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        }
        if (result.status_code == 200 && result.status == "Success") {
          setAsmData(result.data);
          setCount(result.count);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const RemoveAsm = (asmId) => {
    setLoading("remove");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      ASMID: asmId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMASMDelete",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        } else if (result.status_code == 200 && result.status == "Success") {
          setOpen(false);
          setMessage("ASM removed successfully");
          AsmList();
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
        else {
          setOpen(false);
          setApiFailled(true);
          setMessage("Something went wrong please contact support.");
        }

      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const addASM = () => {
    setLoading("add");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      AsmLocation: Location,
      AsmPassword: password,
      AsmMobile: phone,
      AsmEmail: email,
      AsmName: name,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMASMAdd",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status == "Success") {
          setMessage("ASM added successfully");
          setName("");
          setPassword("");
          setEmail("");
          setError("");
          setPhone("");
          setLocation("");
          AsmList();
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        } else {
          setOpen(false);
          setApiFailled(true);
          setMessage("Something went wrong please contact support.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
    return message;
  };
  const BreadCrumb = {
    pagename: "ASM",
    pagePath: "/asm",
    pagename1: "",
    pagePath1: "",
  };
  return (
    <SmWrapper>
      <ConfermationModal
        onClick={() => RemoveAsm(bookingId)}
        loading={loading}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        open={open}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          apiFailled={apiFailled}
          message={successMessage()}
        />
      )}

      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"ASM"}
        subheadingText={localStorage.getItem("Location")}

      />
      {loading === "getAsm" ? (
        //  <div className={classes.loader}> <img src="assets/loadingGif.gif" alt="loading"/></div>
        <></>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="text"
                label="Name"
                error={error.name}
                value={name}
                onInput={() => (error.name = "")}
                onChange={(e) => {
                  onlyAlphabetsName(e);
                }}
              />
              <p class="peer-invalid:visible text-red-500 font-light">
                {error.name}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="email"
                label="Email"
                error={error.email}
                onInput={() => (error.email = "")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p class="peer-invalid:visible text-red-500 font-light">
                {error.email}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="tel"
                label="Mobile number"
                error={error.phone}
                onInput={() => (error.phone = "")}
                maxLength="10"
                onChange={(e) => {
                  onlyNumericMobile(e);
                  error.phone = "";
                }}
                value={phone}
              />
              <p class="peer-invalid:visible text-red-500 font-light">
                {error.phone}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="password"
                label="Password"
                error={error.password}
                value={password}
                onInput={() => (error.password = "")}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p class="peer-invalid:visible text-red-500 font-light">
                {error.password}
              </p>
            </div>
            <div className={classes.inputContainerDisable}>
              <Input
                type="input with label"
                inputType="text"
                error={error.location}
                label="Location"
                onInput={() => (error.location = "")}
                value={Location}
                dropdownType={"showroom"}
                onChange={(e) => {
                  setLocation(e.target.value);
                  error.location = "";
                }}
              />
              <p class="peer-invalid:visible text-red-500 font-light">
                {error.location}
              </p>
            </div>
            <div
              // className={classes.inputContainer}
              class="mt-8"
              onClick={() => {
                formValidation() ? addASM() : console.log("Request error");
              }}
            >
              <Button
                type={"redFilled"}
                loading={loading === "add" ? true : false}
                buttonText={"Submit"}
              />
            </div>
          </div>
          <div className="bg-white rounded">
            {asmData?.length ? <Table
              tableHeading={asmTableHeading}
              setBookingId={setBookingId}
              setOpen={setOpen}
              type="vertical lines"
              TableData={asmData}
            /> : <></>}
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
      )}
    </SmWrapper>
  );
};
export default ASM;
