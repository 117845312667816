import {
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
const Pagination = (props) => {
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);
  const term = queryParams.get("term");

  let maxPages = props.maxPages;
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (maxPages > 0) {
  //     if (page > 0 && page <= maxPages) {
  //       return;
  //     } else {
  //       navigate({
  //         pathname: pathname,
  //       });
  //     }
  //   }
  // }, [page]);

  const firstPostion = (pageNum) => {
    if (maxPages >= 5 && maxPages === pageNum) {
      return pageNum - 4;
    } else if (maxPages >= 5 && maxPages === pageNum + 1) {
      return page - 3;
    } else if (maxPages === 4 && maxPages === pageNum) {
      return page - 3;
    } else {
      if (pageNum === 1) {
        return page;
      } else if (pageNum === 2) {
        return page - 1;
      } else {
        return page - 2;
      }
    }
  };

  const secondPosition = (pageNum) => {
    if (maxPages >= 5 && maxPages === pageNum) {
      return pageNum - 3;
    } else if (maxPages >= 5 && maxPages === pageNum + 1) {
      return page - 2;
    } else if (maxPages === 4 && maxPages === pageNum) {
      return page - 2;
    } else {
      if (pageNum === 1) {
        return page + 1;
      } else if (pageNum === 2) {
        return page;
      } else {
        return page - 1;
      }
    }
  };

  const thirdPosition = (pageNum) => {
    if (maxPages >= 5 && maxPages === pageNum) {
      return pageNum - 2;
    } else if (maxPages >= 5 && maxPages === pageNum + 1) {
      return page - 1;
    } else if (maxPages === 4 && maxPages === pageNum) {
      return page - 1;
    } else {
      if (pageNum === 1) {
        return page + 2;
      } else if (pageNum === 2) {
        return page + 1;
      } else {
        return page;
      }
    }
  };
  const fourthPosition = (pageNum) => {
    if (maxPages >= 5 && maxPages === pageNum) {
      return pageNum - 1;
    } else if (maxPages >= 5 && maxPages === pageNum + 1) {
      return page;
    } else if (maxPages === 4 && maxPages === pageNum) {
      return page;
    } else {
      if (pageNum === 1) {
        return page + 3;
      } else if (pageNum === 2) {
        return page + 2;
      } else {
        return page + 1;
      }
    }
  };

  const fifthPosition = (pageNum) => {
    if (maxPages >= 5 && maxPages === pageNum) {
      return pageNum;
    } else if (maxPages >= 5 && maxPages === pageNum + 1) {
      return page + 1;
    } else {
      if (pageNum === 1) {
        return page + 4;
      } else if (pageNum === 2) {
        return page + 3;
      } else {
        return page + 2;
      }
    }
  };

  const pageChangeHandler = (e) => {
    const clickedOn = parseInt(e.target.innerText);
    if (clickedOn === 1) {
      navigate({
        pathname: pathname,
        search: term ? `?term=${term}` : "",
      });
    } else {
      navigate({
        pathname: pathname,
        search: term ? `?page=${clickedOn}&term=${term}` : `?page=${clickedOn}`,
      });
    }
  };

  const activeClassNameThird = () => {
    if (page >= 3 && page !== maxPages && page !== maxPages - 1) {
      return "border-indigo-500 text-indigo-600";
    } else if (page === 3) {
      return "border-indigo-500 text-indigo-600";
    } else {
      return "text-gray-500";
    }
  };
  const activeClassNameFourth = () => {
    if (page === 4 && page === maxPages) {
      return "border-indigo-500 text-indigo-600";
    } else if (page === 4 && maxPages === page + 1) {
      return "border-indigo-500 text-indigo-600";
    } else if (page !== 4 && page === maxPages - 1 && page !== 3) {
      return "border-indigo-500 text-indigo-600";
    } else {
      return "text-gray-500";
    }
  };

  const activeClassNameFifth = () => {
    if (maxPages === page) {
      return "border-indigo-500 text-indigo-600";
    } else {
      return "text-gray-500";
    }
  };

  return (
    <>
      <nav className="border-t border-gray-200 pb-2 px-2 flex items-center justify-between  ">
        <div className="-mt-px w-0 flex-1 flex">
          {page === 1 ? (
            <div></div>
          ) : (
            <div
              className="border-t-2 border-transparent  pt-4 pr-1 inline-flex items-center text-sm font-bold	  text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer "
              onClick={() => {
                if (page === 1) {
                  navigate({
                    pathname: pathname,
                    search: term ? `?term=${term}` : "",
                  });
                } else {
                  navigate({
                    pathname: pathname,
                    search: term
                      ? `?page=${page - 1}&term=${term}`
                      : `?page=${page - 1}`,
                  });
                }
              }}
            >
              <HiOutlineArrowNarrowLeft
                className="mr-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Previous
            </div>
          )}
        </div>
        <div className="hidden md:-mt-px md:flex">
          {/* first position */}
          <div
            onClick={(e) => pageChangeHandler(e)}
            className={`border-transparent   hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer ${
              page === 1 ? "border-indigo-500 text-indigo-600" : "text-gray-500"
            }`}
          >
            {firstPostion(page)}
          </div>

          {/* second position */}
          {maxPages > 1 ? (
            <div
              onClick={(e) => pageChangeHandler(e)}
              className={`border-transparent   hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer   ${
                page === 2
                  ? "border-indigo-500 text-indigo-600"
                  : "text-gray-500"
              }
                 
                 `}
            >
              {secondPosition(page)}
            </div>
          ) : null}

          {/* third position */}
          {maxPages <= 2 ? null : (
            <div
              onClick={(e) => pageChangeHandler(e)}
              className={`border-transparent  hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer  ${activeClassNameThird()}`}
            >
              {thirdPosition(page)}
            </div>
          )}

          {/* fourth position */}
          {maxPages <= 3 ? null : (
            <div
              onClick={(e) => pageChangeHandler(e)}
              className={`border-transparent hover:text-gray-700
                 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex 
                 items-center text-sm font-medium cursor-pointer 
                 ${activeClassNameFourth()}
                 `}
            >
              {fourthPosition(page)}
            </div>
          )}

          {/* fifth position */}
          {maxPages <= 4 ? null : (
            <div
              onClick={(e) => pageChangeHandler(e)}
              className={`border-transparent  hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer ${activeClassNameFifth()} `}
            >
              {fifthPosition(page)}
            </div>
          )}
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          {maxPages === page ? (
            <div></div>
          ) : (
            <div
              className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-bold text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
              onClick={() => {
                if (maxPages === page) {
                  return;
                } else {
                  navigate({
                    pathname: pathname,
                    search: term
                      ? `?page=${page + 1}&term=${term}`
                      : `?page=${page + 1}`,
                  });
                }
              }}
            >
              Next
              <HiOutlineArrowNarrowRight
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Pagination;

// search: `?term=vinay&?page=${page + 1}`,
