import React from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../Components/DataNotFound";
import classes from "../module.css/TodaysActivitylog.module.css";

const Table = (props) => {
  const pathname = window.location.pathname;
  const loginLocation = localStorage.getItem("Location");

  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };

  const navigate = useNavigate();
  const handleCheck = (pageId) => {
    if (!props.checked.includes(pageId)) {
      props.setChecked([...props.checked, pageId]);
    } else {
      props.setChecked(props.checked.filter((item) => item !== pageId));
    }
  };

  const availityCheckHandler = (availity, allocation) => {
    if ((!availity || availity === 0) && allocation >= 1) {
      return "Allocated";
    } else if (availity === null || !availity) {
      return "Unavailable";
    } else if (availity >= 0 && availity <= 5) {
      return "Low";
    } else if (availity >= 6 && availity <= 12) {
      return "Medium";
    } else {
      return "High";
    }
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const carStatusHandler = (status, eta) => {
    let etaFirstLetter;
    if (eta) {
      etaFirstLetter = eta?.split("-")[0].length;
    }
    if (status === "InTransit" && etaFirstLetter) {
      return `InTransit (${etaFirstLetter > 2 ? dateHandler(eta) : eta} )`;
    } else {
      return status;
    }
  };

  const sortingVin = (col) => {
    if (props.sort === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setSort("DSC");
    }
    if (props.sort === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setSort("ASC");
    }
  };

  const sortingOrder = (col) => {
    if (props.orderSort === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setOrderSort("DSC");
    }
    if (props.orderSort === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setOrderSort("ASC");
    }
  };

  const sortingModel = (col) => {
    if (props.modelSort === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setModelSort("DSC");
    }
    if (props.modelSort === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setModelSort("ASC");
    }
  };

  const sortingVariant = (col) => {
    if (props.variantSort === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setVariantSort("DSC");
    }
    if (props.variantSort === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setVariantSort("ASC");
    }
  };

  const sortingFuelType = (col) => {
    if (props.feulSort === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setFeulSort("DSC");
    }
    if (props.feulSort === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setFeulSort("ASC");
    }
  };

  const sortingColor = (col) => {
    if (props.colorSort === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setColorSort("DSC");
    }
    if (props.colorSort === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setColorSort("ASC");
    }
  };

  const sortingStatus = (col) => {
    if (props.carStatus === "ASC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setCarStatus("DSC");
    }
    if (props.carStatus === "DSC") {
      const sortedmobile = [...props.TableData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      props.setTableData(sortedmobile);
      props.setCarStatus("ASC");
    }
  };

  const getAgingDate = (dateGet) => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month.toString().length === 1 ? `0${month}` : month
      }-${day}`;
    let allocatedDate = dateGet;
    const diffTime = Math.abs(new Date(currentDate) - new Date(allocatedDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (dateGet !== "") return diffDays;
    else return;
  };

  const Tabledata = () => {
    switch (props.tabledataType) {
      case "executive":
        return (
          <>
            {props.TableData.map((person) => (
              <tr className="divide-x divide-gray-200">
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                  {person.user_name}
                </td>
                <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                  {person.user_mobile}
                </td>
                <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                  {person.AsmName ? person.AsmName : "N/A"}
                </td>
                <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                  {person.user_email}
                </td>
                <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                  {person.user_location}
                </td>
                <td className="whitespace-nowrap text-center text-sm text-gray-500 ">
                  {person.user_role}
                </td>
                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                  <div
                    className="text-red-600 cursor-pointer hover:text-red-900"
                    onClick={() => {
                      props.setExecutiveId(person.ExecutiveId);
                      props.setOpen(true);
                    }}
                  >
                    Remove
                  </div>
                </td>
                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                  <div
                    className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                    onClick={() =>
                      navigate(`/sm-manage-executive/${person.ExecutiveId}`)
                    }
                  >
                    Edit
                  </div>
                </td>
              </tr>
            ))}
          </>
        );
      case "EDP Stock AddStock List":
        return (
          <>
            {props.TableData?.map((person, personIdx) => (
              <tr className="divide-x divide-gray-200" key={personIdx}>
                <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                  {person.OrderId}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.win_num}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.model_name}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.fueltype}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.variant}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.colour}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.carstatus}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.allocation_status}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.customer_name} {person.LastName}
                </td>

                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                  <a
                    href={`/edp-update-stock/${person.CarStockID}`}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                    <span className="sr-only">, {person.name}</span>
                  </a>
                </td>
              </tr>
            ))}
          </>
        );
      // case "EDP Allocation Report":
      //   return (
      //     <>
      //       {props.TableData?.map((person, personIdx) => (
      //         <tr className="divide-x divide-gray-200" key={personIdx}>

      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.model_name}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.variant}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.colour}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.fueltype}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.win_num}
      //           </td>

      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {dateHandler(person.CommittedDeliveryDate)}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {dateHandler(person.AmEdd)}
      //           </td>

      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {dateHandler(person.allocated_on.slice(0, 10))}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.allocation_status}
      //           </td>
      //           <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
      //             {person.carstatus}
      //           </td>
      //         </tr>
      //       ))}
      //     </>
      //   );

      case "AMAllStockDetail List":
        return (
          <>
            {props.TableData?.map((person, personIdx) => (
              <tr className="divide-x divide-gray-200" key={personIdx}>
                <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                  {person.model_name}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.fueltype}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.variant}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.colour}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.win_num}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.availabilitystatus}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.yom}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.carstatus}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.allocation_status}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.Location}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.AsmName}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.ExecutiveName}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {`${person.customer_name}  ${person.LastName}`}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.customer_mobile}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.allocated_on &&
                    dateHandler(person.allocated_on.slice(0, 10))}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.truck_num}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.driver_name}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.driver_num}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.last_location}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.CarAging}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.AllocationAging}
                </td>
              </tr>
            ))}
          </>
        );
      case "Manage transit modal data":
        return (
          <>
            {props.TableData?.map((person, personIdx) => (
              <tr className="divide-x divide-gray-200" key={personIdx}>
                <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                  {person.model_name || person.TransitMode}
                </td>
                {/* {console.log(person, " abc")} */}
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.variant || person.truck_num}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.colour || person.driver_name}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.win_num || person.driver_num}
                </td>
              </tr>
            ))}
          </>
        );

      case "SM Allocated Bookings Modal":
        return (
          <>
            <tr className="divide-x divide-gray-200">
              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                {props.TableData.TransitMode}
              </td>
              {/* {console.log(person, " abc")} */}
              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                {props.TableData.truck_num}
              </td>
              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                {props.TableData.driver_name}
              </td>

              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                {props.TableData.driver_num}
              </td>
              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                {props.TableData.last_location}
              </td>
            </tr>
          </>
        );

      case "SM Allocated Aging Modal":
        return (
          <>
            {props.TableData?.map((person, personIdx) => (
              <tr
                className="divide-x divide-gray-200 overflow-auto"
                key={personIdx}
              >
                <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 overflow-auto">
                  {person.model_name}
                </td>
                {/* {console.log(person, " abc")} */}
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.fueltype}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.variant}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.colour}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.carstatus}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.yom}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {getAgingDate(person.allocated_on)}
                </td>
                {/* <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.customer_mobile}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.AsmName}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.ExecutiveName}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.exchange}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.booking_amount}
                </td> */}
              </tr>
            ))}
          </>
        );
      case "car info list":
        return (
          <>
            {props.TableData?.map((person, personIdx) => (
              <tr className="divide-x divide-gray-200" key={personIdx}>
                {person.truck_num?.length > 0 &&
                  person.driver_name?.length > 0 &&
                  person.driver_num?.length > 0 ? (
                  <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6"></td>
                ) : (
                  <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                    <input
                      id="remember-me"
                      name={person.CarStockID}
                      type={person.win_num ? "checkbox" : ""}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                      checked={props.checked.includes(person.CarStockID)}
                      onChange={() => handleCheck(person.CarStockID)}
                    // onChange={() => { handleCheck(person.CarStockID); props.stockprefilledwithid(person.CarStockID) }}
                    />
                  </td>
                )}
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.OrderId}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.model_name}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.fueltype}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.variant}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.colour}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.yom}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.allocation_status}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.carstatus}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {person.truck_num}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {dateHandler(person.created_at?.slice(0, 10))}
                </td>

                {person.win_num?.length > 0 ? (
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4  text-sm text-gray-500 sm:pr-6 text-center">
                    <div className="text-center">
                      {person.win_num}
                      <span className="sr-only">, {person.name}</span>
                    </div>
                  </td>
                ) : (
                  <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                    <div
                      onClick={() => {
                        props.setGetstockid(person.CarStockID);
                        props.setOpen(true);

                        props.stockprefilledwithid(person.CarStockID);
                      }}
                      className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    >
                      Add VIN
                      <span className="sr-only">, {person.name}</span>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </>
        );
      case "edp today activity":
        return (
          <>
            {typeof props.TableData === "string"
              ? LogOut()
              : props.TableData &&
              props.TableData?.map((person, personIdx) => (
                <tr className="divide-x divide-gray-200">
                  <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                    {person.OrderId}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.win_num}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.model_name}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.fueltype}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.variant}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.colour}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.carstatus}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.TransitMode}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.driver_name.replace(/[0-9]/g, "")}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.driver_num}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.truck_num}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.allocation_status}
                  </td>
                  {/* <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => {
                          navigate(`/edp-update-stock/${person.CarStockID}`);
                        }}
                      >
                        Edit
                        <span className="sr-only">, {person.name}</span>
                      </button>
                    </td> */}
                </tr>
              ))}
          </>
        );
      case "colorListTable":
        return (
          <>
            {props.TableData &&
              props.TableData?.map((item, personIdx) => (
                <tr className="divide-x divide-gray-200" key={personIdx}>
                  <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                    {item.colour}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {item.yom ? item.yom : "-"}
                  </td>
                  <div
                    onClick={() => {
                      availityCheckHandler(
                        item.Availability,
                        item.Allocation
                      ) === "Allocated"
                        ? props.setAllCatedColor(item.colour)
                        : console.log("");
                    }}
                  >
                    <td
                      onClick={() => {
                        availityCheckHandler(
                          item.Availability,
                          item.Allocation
                        ) === "Allocated" && props.allocatedCarHandler(item);
                      }}
                      className={`whitespace-nowrap p-4 text-center flex justify-center text-sm text-gray-500 ${availityCheckHandler(
                        item.Availability,
                        item.Allocation
                      ) === "Allocated" &&
                        props.dashboard === "srm" &&
                        "text-indigo-500 cursor-pointer"
                        }`}
                    >
                      {availityCheckHandler(item.Availability, item.Allocation)}
                    </td>
                  </div>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {item.carstatus
                      ? carStatusHandler(item.carstatus, item.etaDate)
                      : "-"}
                  </td>
                </tr>
              ))}
          </>
        );
      case "allocated car list":
        return (
          <>
            {props.TableData?.map((item, index) => (
              <tr className="divide-x divide-gray-200">
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {index + 1}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.model_name}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.variant}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.colour}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.Location}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.AsmName}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.carstatus}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.carstatus === "InTransit" && item.etaDate
                    ? dateHandler(item.etaDate)
                    : "-"}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-blue-500 cursor-pointer">
                  {/* item.Location !== loginLocation */}
                  {item.Location &&
                    item.Location !== loginLocation &&
                    props.userRole !== "ASM" && props.userRole !== "Admin" ? (
                    <span onClick={() => props.requestAllocationHandler(item)}>
                      Request Re-Allocation
                    </span>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </>
        );
      case "allocation awaited list for booking":
        return (
          <>
            {props.TableData?.map((item, index) => (
              <tr className="divide-x divide-gray-200">
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {`${item.customer_name} ${item.LastName}`}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.customer_mobile}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.AsmName}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.ExecutiveName}
                </td>
                <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                  {item.CustomerSpecialNeed ? item.CustomerSpecialNeed : "-"}
                </td>

                <td className="whitespace-nowrap p-4 text-center text-sm text-blue-500 ">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    onChange={() => props.selectCarHandler(item)}
                    checked={
                      item.BookingID ===
                      props.selectedAwaitedCarDetail.BookingID
                    }
                  />
                </td>
              </tr>
            ))}
          </>
        );
      case "allocation status grid list dashboard":
        return (
          <>
            {props.data?.map((item, index) => {
              return (
                <tr className="divide-x divide-blue-200" key={index}>
                  <td className="whitespace-nowrap p-2 text-sm  text-center font-medium text-gray-900">
                    {item.type}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.ActiveBookings}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocatedPhysical}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocatedInTransit}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocatedPending}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.DLD}
                  </td>
                </tr>
              );
            })}
          </>
        );
      case "Model wise allocation status grid list dashboard":
        return (
          <>
            {props.data?.map((item, index) => {
              return (
                <tr className="divide-x divide-blue-200" key={index}>
                  <td className="whitespace-nowrap p-2 text-sm  text-center font-medium text-gray-900">
                    {item.type}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.variant ? item.variant : "-"}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.ActiveBookings}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocatedPhysical}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocatedInTransit}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocatedPending}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.DLD}
                  </td>
                </tr>
              );
            })}
          </>
        );
      case "Executive allocation status grid list dashboard":

        return (
          <>
            {props.data?.map((item, index) => {
              return (
                <tr className="divide-x divide-blue-200" key={index}>
                  <td className="whitespace-nowrap p-2 text-sm  text-center font-medium text-gray-900">
                    {item.AsmName}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.user_name}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.Allocated}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.AllocationAwaited}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.ActiveBooking}
                  </td>


                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.Cancelled}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.Delivered}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.TotalBooking}
                  </td>
                </tr>
              );
            })}
          </>
        );
      case "Executive allocation status grid list dashboard yearly":
        return (
          <>
            {props.data?.map((item, index) => {
              return (
                <tr className="divide-x divide-blue-200" key={index}>
                  <td className="whitespace-nowrap p-2 text-sm  text-center font-medium text-gray-900">
                    {item.AsmName}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.user_name}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {/* {item.ActiveBookings} */}
                    {item.ActiveBooking}
                  </td>

                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.Cancelled}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.Delivered}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500 text-center">
                    {item.TotalBooking}
                  </td>
                </tr>
              );
            })}
          </>
        );
      default:
        return (
          <>
            {props.TableData &&
              props.TableData.map((person) => (
                <tr className="divide-x divide-gray-200">
                  <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                    {person.AsmName}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.AsmMobile}
                  </td>
                  <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                    {person.AsmEmail}
                  </td>
                  <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                    {person.AsmLocation}
                  </td>
                  <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                    <div
                      className="text-red-600 cursor-pointer hover:text-red-900"
                      onClick={() => {
                        props.setBookingId(person.AsmID);
                        props.setOpen(true);
                      }}
                    >
                      Remove
                    </div>
                  </td>
                  <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                    <div
                      className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                      onClick={() => {
                        navigate(`/asm/${person.AsmID}`);
                      }}
                    >
                      Change
                    </div>
                  </td>
                </tr>
              ))}
          </>
        );
        break;
    }
  };
  const tableType = (type) => {
    switch (type) {
      case "stripped rows":
        return (
          <div>
            <div className="m-4 sm:m-0 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto ">
                <div className="inline-block min-w-full py-2 align-middle md:px-6">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          {props.tableHeading &&
                            props.tableHeading.map((item) => {
                              return (
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900  text-center"
                                >
                                  {item.name}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {Tabledata()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "vertical lines":
        return (
          <div>
            <div className="m-4 sm:m-0 sm:mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          {props.tableHeading &&
                            props.tableHeading?.map((item) => {
                              return (
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                                >
                                  {item.name}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {Tabledata()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "vertical lines ui changes":
        return (
          <table className="min-w-full divide-y divide-gray-300 my-4 shadow-lg">
            <thead>
              <tr className="divide-x divide-gray-200">
                {props.tableHeading.map((item, index) => {
                  return (
                    <th
                      key={index}
                      scope="col"
                      className="p-2 text-sm font-semibold text-white text-center border-blue-600 bg-blue-700"
                    >
                      {item.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y divide-blue-200 bg-white">
              {Tabledata()}
            </tbody>
          </table>
        );
      case "car info pending":
        return (
          <div>
            <div className="m-4 sm:m-0 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto ">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 ">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          {props.tableHeading?.map((item) => {
                            return (
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                              >
                                {item.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {Tabledata()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "stripped rows without button":
        return (
          <div className="">
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300 bg-white">
                      <thead className="bg-gray-50">
                        <tr>
                          {props.tableHeadingData.map((item, index) => {
                            return (
                              <th
                                key={index}
                                scope="col"
                                className="py-3.5 px-3 text-left  text-sm font-semibold text-gray-900 "
                              >
                                {item.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {props.TableData.map((item, personIdx) => (
                          <tr
                            key={personIdx}
                            className={
                              personIdx % 2 === 0 ? undefined : "bg-gray-50"
                            }
                          >
                            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 ">
                              {item.colour}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.yom}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {availityCheckHandler(item.Availability)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.carstatus}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.etaDate ? item.etaDate : "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "viewStocks":
        return (
          <div>
            <div className="m-4 sm:m-0  flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto ">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 ">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              VIN
                              {props.sort === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingVin("win_num")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingVin("win_num")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Order No.
                              {props.orderSort === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingOrder("OrderId")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingOrder("OrderId")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Model
                              {props.modelSort === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingModel("model_name")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingModel("model_name")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Variant
                              {props.variantSort === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingVariant("variant")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingVariant("variant")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Fuel Type
                              {props.feulSort === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingFuelType("fueltype")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingFuelType("fueltype")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Color
                              {props.colorSort === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingColor("colour")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingColor("colour")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Status
                              {props.carStatus === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingStatus("carstatus")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingStatus("carstatus")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            <div className="flex justify-center items-center">
                              Car Aging
                              {/* {props.carStatus === "DSC" ? (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingStatus("carstatus")}
                                >
                                  <AiOutlineArrowDown />
                                </span>
                              ) : (
                                <span
                                  className={classes.arrow}
                                  onClick={() => sortingStatus("carstatus")}
                                >
                                  <AiOutlineArrowUp />
                                </span>
                              )} */}
                            </div>
                          </th>
                          {!pathname.includes("/creta") && (
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                            >
                              <div className="flex justify-center items-center">
                                Edit
                              </div>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.TableData.length > 0 ? (
                          props.TableData.map((person, personIdx) => (
                            <tr
                              className="divide-x divide-gray-200"
                              key={personIdx}
                            >
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                {person.win_num}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {person.OrderId}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {person.model_name}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {person.variant}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {person.fueltype}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {person.colour}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {person.carstatus}
                              </td>
                              <td className="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                                {getAgingDate(person.signoff_date.slice(0, 10))}
                              </td>
                              {!pathname.includes("/creta") && (
                                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                                  <button
                                    className="text-Indigo-100  cursor-pointer hover:text-Indigo-900"
                                    onClick={() => {
                                      navigate(
                                        `/edp-update-stock/${person.CarStockID}`
                                      );
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                              colspan={props.columnList.length}
                            >
                              <DataNotFound text="Stock details not available" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "Allocate but vin awaited":
        return (
          <div className="m-4 sm:m-0  flex flex-col" key={props.data}>
            <div className="-my-2 -mx-4 overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 ">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 ">
                      <tr className="divide-x divide-gray-200 ">
                        {props.columnList.map((item, itemIndex) => (
                          <th
                            scope="col"
                            key={itemIndex}
                            className="py-2  text-center text-sm font-semibold text-gray-900"
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {props.data?.map((item) => {
                        return (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap text-center  text-sm font-medium text-gray-900 ">
                              {item.win_num}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.OrderId}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.model_name}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.variant}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.fueltype}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.colour}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.carstatus}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.etaDate && dateHandler(item.etaDate)}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.Location}
                            </td>

                            <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                              {props.tabType === "VIN Arrived" ? (
                                <button
                                  className="text-Indigo-100  cursor-pointer hover:text-Indigo-900"
                                  onClick={() => {
                                    props.datavalues(item);
                                    props.setPreferenceModal(true);
                                    props.AMBookingListByModelVariantColorAPi(
                                      item.model_name,
                                      item.variant,
                                      item.colour
                                    );
                                  }}
                                >
                                  Allocate
                                </button>
                              ) : (
                                <>-</>
                              )}
                            </td>

                            <td className="relative whitespace-nowrap  text-center text-sm font-medium ">

                              <button
                                className="text-red-600  cursor-pointer hover:text-Indigo-900"
                                onClick={() => {
                                  props.Params(item);
                                  props.setCancel(true);
                                }}
                              >
                                Cancel Allocation
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      case "Allocate Auto Suggest Orders":
        return (
          <div className="m-4 sm:m-0  flex flex-col" key={props.data}>
            <div className="-my-2 -mx-4 overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 ">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 ">
                      <tr className="divide-x divide-gray-200 ">
                        {props.columnList.map((item, itemIndex) => (
                          <th
                            scope="col"
                            key={itemIndex}
                            className="py-2  text-center text-sm font-semibold text-gray-900"
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {props.data?.map((item) => {
                        return (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.car_model}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.car_variant}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.fuelType}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.car_colour}
                            </td>
                            <td className="whitespace-nowrap p-1 text-center text-sm text-gray-500">
                              {item.count}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      case "allStocks":
        return (
          <div>
            <div className="m-4 sm:m-0  flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto ">
                <div className="inline-block min-w-full py-2 align-middle md:px-6">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          {props.columnList.map((item, itemIndex) => (
                            <th
                              scope="col"
                              key={itemIndex}
                              className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                            >
                              {item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.TableData.length > 0 ? (
                          props.TableData.map((person, personIdx) => (
                            <tr className="divide-x divide-gray-200">
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                {person.win_num}
                              </td>
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                {person.model_name}
                              </td>
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                {person.variant}
                              </td>
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                {person.fueltype}
                              </td>
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                {person.colour}
                              </td>
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                {person.carstatus}
                              </td>
                              <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                {person.OrderId}
                              </td>
                              <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                                <button
                                  className="text-Indigo-100 font-bold"
                                  onClick={() => {
                                    navigate(
                                      `/edp-update-stock/${person.CarStockID}`
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                              colspan={props.columnList.length}
                            >
                              <img
                                src="././assets/dataNot.png"
                                alt="no data found"
                                className={classes.img}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "modalList":
        return (
          <div className="m-4 sm:m-0  flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle md:px-4 ">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        {props.columnList.map((item, itemIndex) => (
                          <th
                            scope="col"
                            key={itemIndex}
                            className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900"
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {props.TableData.map((person, personIdx) => (
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap py-4 text-center pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                            {person.model}
                          </td>
                          <td className="text-ellipsis py-4 pl-4 pr-3 text-sm text-center text-gray-700 sm:pl-6">
                            {person.variant}
                          </td>
                          <td className="text-ellipsis py-4 pl-4 pr-3 text-sm text-center text-gray-700 sm:pl-6">
                            {person.colour}
                          </td>
                          <td className="text-ellipsis py-4 pl-4 pr-3 text-sm text-center text-gray-700 sm:pl-6">
                            {person.fuel_type}
                          </td>
                          <td className="text-ellipsis py-4 pl-4 pr-3 text-sm text-center text-gray-700 sm:pl-6">
                            <button
                              className="text-Indigo-100 font-bold cursor-pointer"
                              onClick={() => {
                                props.getSoloModel(person.CardbID);
                              }}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{tableType(props.type)}</div>;
};

export default Table;
