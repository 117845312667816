import React, { useState } from "react";
import Heading from "../../tailwindUI/Heading";
import StackedList from "../../tailwindUI/StackedList";
import Pagination from "../../tailwindUI/Pagination";
import Tab from "../../tailwindUI/Tab";
import classes from "../../module.css/PendingBookings.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import SmWrapper from "../../Components/SmWrapper";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import Modal from "../../tailwindUI/Modal";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
const ReallocationRequests = () => {
  const [open, setOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [accept, setAccept] = useState(false);
  const [data, setData] = useState([]);
  const [sentData, setSentData] = useState([]);
  const [recieveHistory, setRecieveHistory] = useState([]);
  const [sentHistory, setSentHistory] = useState([]);
  const [dataCount, setDataCount] = useState("");
  const [allTabCount, setAllTabCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [sentDataCount, setSentDataCount] = useState("");
  const [recieveHistoryCount, setRecieveHistoryCount] = useState("");
  const [sentHistoryCount, setSentHistoryCount] = useState("");
  const [tabType, setTabType] = useState("Re-Allocation Request Recieved");
  const [loading, setLoading] = useState(true);
  const [reAllocationId, setReallocationId] = useState("");
  const [message, setMessage] = useState("");
  const [confirmModalBtnLoading, setConfirmModalBtnLoading] = useState(false);
  const UserToken = localStorage.getItem("UserToken");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
  const navigate = useNavigate();
  useEffect(() => {
    tabCountApi();
    if (tabType === "Re-Allocation Request Recieved") {
      BookingList();
    }
    if (tabType === "Re-Allocation Request Sent") {
      SentRequestList();
    }
    if (tabType === "Re-Allocation Sent History") {
      SentHistory();
    }
    if (tabType === "Re-Allocation Recieved History") {
      RecievedHistory();
    }
  }, [tabType]);
  useEffect(() => {
    tabCountApi();
    if (tabType === "Re-Allocation Request Recieved") {
      BookingList();
    }
    if (tabType === "Re-Allocation Request Sent") {
      SentRequestList();
    }
    if (tabType === "Re-Allocation Sent History") {
      SentHistory();
    }
    if (tabType === "Re-Allocation Recieved History") {
      RecievedHistory();
    }
  }, [page]);
  const tabs = [
    {
      name: "Re-Allocation Request Recieved",
      count: allTabCount.SRMReAllocationRecievedList,
      current: true,
    },

    {
      name: "Re-Allocation Request Sent",
      count: allTabCount.SRMReAllocationSentList,
      current: false,
    },
    {
      name: "Re-Allocation Recieved History",
      count: allTabCount.SRMReAllocationRecievedHistoryList,
      current: false,
    },
    {
      name: "Re-Allocation Sent History",
      count: allTabCount.SRMReAllocationSentHistorytList,
      current: false,
    },
  ];
  const BreadCrumb = {
    pagename: "Re-Allocation Requests",
    pagePath: "/sm-reallocation-requests",
    pagename1: "",
    pagePath1: "",
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const BookingDetail = (bookingId) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: bookingId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status === "Success" && result.status_code === 200) {
          setBookingData(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const BookingList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationRecievedList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status_code == 200 && result.status == "Success") {
          setData(result.data);
          setDataCount(result.count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const SentRequestList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationSentList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status_code == 200 && result.status == "Success") {
          setSentData(result.data);
          setSentDataCount(result.count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const SentHistory = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationSentHistory",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status_code == 200 && result.status == "Success") {
          setSentHistory(result.data);
          setSentHistoryCount(result.count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const RecievedHistory = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationReceivedHistory",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status_code == 200 && result.status == "Success") {
          setRecieveHistory(result.data);
          setRecieveHistoryCount(result.count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }

      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const DataType = () => {
    if (tabType === "Re-Allocation Request Recieved") {
      return data;
    }
    if (tabType === "Re-Allocation Request Sent") {
      return sentData
    }
    if (tabType === "Re-Allocation Sent History") {
      return sentHistory
    }
    if (tabType === "Re-Allocation Recieved History") {
      return recieveHistory
    }
  }
  const DataCount = () => {
    if (tabType === "Re-Allocation Request Recieved") {
      return dataCount;
    }
    if (tabType === "Re-Allocation Request Sent") {
      return sentDataCount
    }
    if (tabType === "Re-Allocation Sent History") {
      return sentHistoryCount
    }
    if (tabType === "Re-Allocation Recieved History") {
      return recieveHistoryCount
    }
  }
  const tabCountApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationTabCounts",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status == "Success") {
          setAllTabCount(result);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const maxPage = Math.ceil(DataCount() / 20);
  const ListType = (type) => {
    if (
      type === "Re-Allocation Request Recieved" ||
      type === "Re-Allocation Recieved History"
    ) {
      return "Reallocation Request Recieved";
    } else {
      return "Reallocation Request Sent";
    }
  };

  const declineHandler = (detail) => {
    setCancel(true);
    setReallocationId(detail.ReallocationID);
  };

  const SRMReAllocationRequestDeclineApi = () => {
    setConfirmModalBtnLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      ReallocationID: reAllocationId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationRequestDecline",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status == "Success") {
          setCancel(false);
          setMessage("Re-Allocation has been cancelled");
          tabCountApi();
          BookingList();
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setConfirmModalBtnLoading(false));
  };

  const acceptHandler = (detail) => {
    setAccept(true);
    setReallocationId(detail.ReallocationID);
  };

  const SRMReAllocationRequestAcceptApi = () => {
    setConfirmModalBtnLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      ReallocationID: reAllocationId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMReAllocationRequestAccept",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status == "Success") {
          setAccept(false);
          setMessage("Re-Allocation Accepted Successfully");
          tabCountApi();
          BookingList();
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setConfirmModalBtnLoading(false));
  };

  const StackListType = ListType(tabType);

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
    return message;
  };
  return (
    <SmWrapper>
      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"Re-Allocation Requests"}
        subheadingText={localStorage.getItem("Location")}

      />
      <div className={classes.mainContainer}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {message && <SuccessMessage message={successMessage()} />}

        {open && (
          <FollowUpRemarks
            bookingData={bookingData}
            setOpen={setOpen}
            open={open}
          />
        )}
        {cancel && (
          <Modal
            setOpen={setCancel}
            desc={"Are you sure you want to decline request?"}
            heading={"Decline Request"}
            open={cancel}
            onCancel={() => {
              setCancel(false);
            }}
            onClick={() => SRMReAllocationRequestDeclineApi()}
            type="Simple alert"
          />
        )}
        {accept && (
          <Modal
            setOpen={setAccept}
            desc={"Are you sure you want to accept request?"}
            heading={"Accept Request"}
            open={accept}
            onCancel={() => {
              setAccept(false);
            }}
            onClick={() => SRMReAllocationRequestAcceptApi()}
            type="Simple alert"
          />
        )}
        <div className={classes.innerContainer}>
          <div className="text-end pt-2 pr-4 text-gray-500">
            {" "}
            {DataType() ? DataType().length : 0} of {DataCount() ? DataCount() : 0}
          </div>

          <div>
            <Tab setTabType={setTabType} tabs={tabs} />
            {loading ? (
              <div className={classes.loader}>
                {" "}
                <img src="assets/loadingGif.gif" alt="loading" />
              </div>
            ) : (
              <>
                {" "}
                <div className={classes.list}>

                  <div className={classes.stackListContainer}>
                    <div role="list" className={classes.stackListWrapper}>
                      {DataType().length ? (
                        DataType().map((item, index) => {
                          return (
                            <StackedList
                              bookingTo={item.bookingTo}
                              bookingFrom={item.bookingFrom}
                              BookingDetail={BookingDetail}
                              setCancel={setCancel}
                              setAccept={setAccept}
                              setOpen={setOpen}
                              data={item}
                              tabType={tabType}
                              type={StackListType}
                              declineHandler={declineHandler}
                              acceptHandler={acceptHandler}
                            />
                          );
                        })
                      ) : (
                        <DataNotFound />
                      )}
                    </div>
                  </div>

                </div>
                {maxPage > 1 ? <Pagination maxPages={maxPage} /> : <></>}
              </>
            )}
          </div>
        </div>
      </div>
    </SmWrapper>
  );
};

export default ReallocationRequests;
