import React, { useState, useContext } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { CountContext } from "../Context/DataCount";
import { IoIosNotifications } from "react-icons/io";
const navigation = [
  { name: "Dashboard", href: "/edp-dashboard", current: true },
  { name: "Activity Log", href: "/edp-today-activity-log", current: false },
  { name: "Real Time Stock", href: "/edp-real-time-stock-one", current: false },
];
const SRMNavigation = [
  { name: "Dashboard", href: "/sm-dashboard", current: true },
  { name: "Real Time Stock", href: "/sm-real-time-stock-one", current: false },
];
const ADMINNavigation = [
  { name: "Dashboard", href: "/creta-dashboard", current: true },
  {
    name: "Real Time Stock",
    href: "/creta-real-time-stock-one",
    current: false,
  },
];
const AllocationNavigation = [
  { name: "Dashboard", href: "/stm-dashboard", current: true },
  {
    name: "Auto Suggest Orders",
    href: "/stm-auto-suggest-orders",
    current: false,
  },
  { name: "Real Time Stock", href: "/stm-real-time-stock-one", current: false },
];
const GMNavigation = [
  { name: "Dashboard", href: "/gm-dashboard", current: true },
  {
    name: "Booking Cancellations",
    href: "/booking-cancellations-by-showroom-manager",
    current: false,
  },
  { name: "Detailed Allocation Report", href: "/gm-detailed-allocation-report", current: false },
];
const CBTNavigation = [
  { name: "Dashboard", href: "/cbt-dashboard", current: true },
  { name: "Allocation Awaited ", href: "/cbt-allocation-awaited", current: false },
  {
    name: "Recently Allocated Cars",
    href: "/cbt-recently-allocatedCars",
    current: false,
  },
  { name: "Booking Cancellations", href: "/cbt-booking-cancellation", current: false },
  { name: "Confirmed Booking Cancellations", href: "/cbt-confirmed-booking-cancellations", current: false },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Navbar = (props) => {
  const [active, setActive] = useState("");

  const navigate = useNavigate();
  const SignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  const { count, allocationCount } = useContext(CountContext);
  const { pathname } = useLocation();

  const getFullName = (fullname) => {
    const [firstname, lastname] = fullname.split(" ");
    const firstnameFirstLetter = firstname ? firstname[0]?.toUpperCase() : "";
    const lastnameFirstLetter = lastname ? lastname[0]?.toUpperCase() : "";
    return firstnameFirstLetter + lastnameFirstLetter

  };
  const switchNavbar = (navbarType) => {
    switch (navbarType) {
      case "blue navbar":
        return (
          <Disclosure as="nav" className="bg-Indigo-100 text-White">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-Indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          onClick={() => navigate("/edp-dashboard")}
                          className="block sm:h-8 h-6 w-auto lg:hidden"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                        <img
                          onClick={() => navigate("/edp-dashboard")}
                          className="hidden sm:h-8 h-6 w-auto lg:block cursor-pointer"
                          src="/assets/stockone_logo.svg"
                          alt="Stock One Icon"
                        />
                      </div>
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex space-x-4">
                          {navigation.slice(0, 1).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-Indigo-200 text-white"
                                  : "text-gray-300 hover:bg-Indigo-200 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/edp-add-stock" ||
                                    pathname === "/edp-stock-details" ||
                                    pathname === "/edp-car-info-pending" ||
                                    pathname === "/edp-all-stocks" ||
                                    pathname === "/edp-manage-delivery" ||
                                    pathname === "/edp-manage-model" ||
                                    pathname === "/edp-delete-car" ||
                                    pathname === "/edp-delivered-cars"
                                    ? "bg-Indigo-200 inline-flex w-full justify-center text-gray-300 hover:bg-Indigo-200  hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : "  inline-flex w-full justify-center text-gray-300 hover:bg-Indigo-200  hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                }
                              >
                                Stocks
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-add-stock"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-200"
                                            : "text-Indigo-200",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Add New Stock
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-stock-details"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        View Stock Detail
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-car-info-pending"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Car Info Pending
                                      </a>
                                    )}
                                  </Menu.Item>

                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-manage-delivery"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Manage Delivery
                                      </a>
                                    )}
                                  </Menu.Item>

                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-manage-model"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Manage Models
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-delete-car"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delete Car &amp; Deleted Cars Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-delivered-cars"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delivered Cars Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/edp-manage-transit"
                                    ? "bg-Indigo-200  inline-flex w-full justify-center text-gray-300 hover:bg-Indigo-200  hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : "  inline-flex w-full justify-center text-gray-300 hover:bg-Indigo-200  hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                }
                              >
                                Stock Status
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="text-Black absolute z-10 right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/edp-manage-transit"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Indigo-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Manage Transit
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          {navigation
                            .slice(1, navigation.length)
                            .map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  item.href === pathname
                                    ? "bg-Indigo-200 text-white"
                                    : "text-gray-300 hover:bg-Indigo-200 hover:text-white",
                                  "px-3 py-2 rounded-md text-sm font-medium"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </a>
                            ))}
                          <a
                            className={
                              pathname.includes("/edp-notification")
                                ? " bg-Indigo-200 text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                                : "text-gray-300 hover:bg-Indigo-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                            }
                            href="/edp-notification"
                          >
                            <IoIosNotifications fontSize={20} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">
                              {localStorage.getItem("FirstName") &&
                                localStorage.getItem("LastName")
                                ? `${localStorage
                                  .getItem("FirstName")
                                  .slice(0, 1)}${localStorage
                                    .getItem("LastName")
                                    .slice(0, 1)}`
                                : ""}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    SignOut();
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.href === pathname
                            ? "bg-Indigo-200 text-white"
                            : "text-gray-300 hover:bg-Indigo-200 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}

                    <Menu as="div" className="relative  text-left">
                      <div>
                        <Menu.Button
                          className={
                            pathname === "/edp-add-stock" ||
                              pathname === "/edp-stock-details" ||
                              pathname === "/edp-car-info-pending" ||
                              pathname === "/edp-all-stocks" ||
                              pathname === "/edp-manage-delivery" ||
                              pathname === "/edp-manage-delivery" ||
                              pathname === "/edp-manage-model"
                              ? "bg-Indigo-200 inline-flex w-full  text-gray-300 hover:bg-Indigo-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "inline-flex w-full  text-gray-300 hover:bg-Indigo-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Stocks
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className=" right-0 mt-2  z-10 origin-top-right rounded-md bg-white shadow-lg text-Black   ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/edp-add-stock"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Indigo-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Add New Stock
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/edp-stock-details"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Indigo-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  View Stock Detail
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/edp-car-info-pending"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Indigo-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Car Info Pending
                                </a>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/edp-manage-delivery"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Indigo-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Manage Delivery
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/edp-manage-model"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Indigo-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Manage Models
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <Menu as="div" className="relative  text-left">
                      <div>
                        <Menu.Button
                          className={
                            pathname === "/edp-manage-transit"
                              ? "bg-Indigo-200 inline-flex w-full  text-gray-300 hover:bg-Indigo-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "inline-flex w-full  text-gray-300 hover:bg-Indigo-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Stock Status
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className=" right-0 mt-2  z-10  origin-top-right rounded-md shadow-lg text-Black bg-White ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/edp-manage-transit"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Indigo-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Manage Transit
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      case "red navbar":
        return (
          <Disclosure as="nav" className="bg-Red-100 text-White">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-Red-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          onClick={() => navigate("/sm-dashboard")}
                          className="block sm:h-8 cursor-pointer h-6 w-auto lg:hidden"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                        <img
                          onClick={() => navigate("/sm-dashboard")}
                          className="hidden sm:h-8 cursor-pointer h-6 w-auto lg:block"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex space-x-4">
                          {SRMNavigation.slice(0, 1).map((item) => (
                            <a
                              onClick={() => setActive(item.name)}
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-Red-200 text-white"
                                  : "text-gray-300 hover:bg-Red-300 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/sm-new-booking" ||
                                    pathname === "/sm-search-update"
                                    ? "bg-Red-200 inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                Bookings
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-new-booking"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        New Booking
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-search-update"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Search and Update Booking
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/sm-delievred-cars" ||
                                    pathname === "/sm-cancelled-bookings" ||
                                    pathname === "/sm-waiting-period-analysis" ||
                                    pathname === "/sm-allotment-pending" ||
                                    pathname === "/sm-pending-bookings"
                                    ? "bg-Red-200 inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                Booking Status
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="text-Black absolute z-20 right-0 mt-2 w-max origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1 ">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/sm-pending-bookings")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          " px-4 py-2 text-sm flex  flex-between cursor-pointer"
                                        )}
                                      >
                                        <span>Total Bookings</span>{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count?.PendingBookingCount
                                            ? count?.PendingBookingCount
                                            : 0}{" "}
                                        </span>
                                        {/* <span className="bg-gray-200 px-3 ml-2 rounded-3xl"> {props.dummyData?.pendingBooking} </span> */}
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/sm-allocated-bookings")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Allocated but not Delivered{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count?.AllocatedbutnotDeliveredCount
                                            ? count?.AllocatedbutnotDeliveredCount
                                            : 0}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/sm-allotment-pending")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Delivery Due but Allocation Pending{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count?.DeliveryDuebutAllocationPendingCount
                                            ? count?.DeliveryDuebutAllocationPendingCount
                                            : 0}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate(
                                            "/sm-waiting-period-analysis"
                                          )
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Waiting Period Analysis{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count?.WaitingPeriodAnalysisCount
                                            ? count?.WaitingPeriodAnalysisCount
                                            : 0}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/sm-cancelled-bookings")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Cancelled Bookings{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count?.CancelledBookingCount
                                            ? count?.CancelledBookingCount
                                            : 0}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/sm-delievred-cars")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Delivered Cars{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {count?.bookingdelivered
                                            ? count?.bookingdelivered
                                            : 0}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate(
                                            "/sm-auto-allocation-cancelled"
                                          )
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Auto Allocation Cancelled{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count.get_auto_allocation_cancelled_count
                                            ? count.get_auto_allocation_cancelled_count
                                            : 0}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/sm-reallocation-requests")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-Red-200",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Re-Allocation Requests{" "}
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {" "}
                                          {count.reallocation_count
                                            ? count.reallocation_count
                                            : 0}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          {/* ---------------------reports */}
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname ===
                                    "/sm-booking-report-cumulative" ||
                                    pathname === "/sm-delivery-report-yearly" ||
                                    pathname === "/sm-delivery-report-monthly" ||
                                    pathname === "/sm-booking-report-monthly" ||
                                    pathname === "/sm-booking-cancel-yearly" ||
                                    pathname === "/sm-booking-cancel-monthly" ||
                                    pathname === "/sm-detailed-booking-report"
                                    ? "bg-Red-200 inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                Reports
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-booking-report-cumulative"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Report Cumulative
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-booking-report-monthly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Report Monthly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-booking-cancel-yearly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Cancel Yearly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-booking-cancel-monthly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Cancel Monthly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-delivery-report-yearly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delivery Report Yearly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-delivery-report-monthly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delivery Report Monthly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-detailed-booking-report"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Detailed Booking Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          {/* -------------------------------- */}
                          {SRMNavigation.slice(1, 2).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-Red-200 text-white"
                                  : "text-gray-300 hover:bg-Red-300 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/asm" ||
                                    pathname === "/sm-manage-executive"
                                    ? "bg-Red-200 inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                Manage Team
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/asm"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        ASM
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/sm-manage-executive"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-Red-100"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Manage Executive
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          {SRMNavigation.slice(2, 3).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-Red-200 text-white"
                                  : "text-gray-300 hover:bg-Red-300 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                          <a
                            className={
                              pathname.includes("/sm-notification")
                                ? "bg-Red-300 text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                                : " text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                            }
                            href="/sm-notifications"
                          >
                            <IoIosNotifications fontSize={20} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">
                              {localStorage.getItem("FirstName") &&
                                localStorage.getItem("LastName")
                                ? `${localStorage
                                  .getItem("FirstName")
                                  .slice(0, 1)}${localStorage
                                    .getItem("LastName")
                                    .slice(0, 1)}`
                                : ""}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => SignOut()}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {SRMNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-Red-200 text-white"
                            : "text-gray-300 hover:bg-Red-300 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}

                    <Menu as="div" className="relative  text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full  text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                          Search and Update Booking
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className=" right-0 mt-2  z-10 origin-top-right rounded-md bg-white shadow-lg text-Black   ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-new-booking"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  New Booking
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-search-update"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Search and Update Booking
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <Menu as="div" className="relative  text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full  text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                          Booking Status
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className=" right-0 mt-2  z-10  origin-top-right rounded-md bg-white shadow-lg text-Black   ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-pending-bookings"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    " px-4 py-2 text-sm flex  flex-between"
                                  )}
                                >
                                  <span>Total Bookings</span>{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count?.PendingBookingCount
                                      ? count?.PendingBookingCount
                                      : 0}{" "}
                                  </span>
                                  {/* <span className="bg-gray-200 px-3 ml-2 rounded-3xl"> {props.dummyData?.pendingBooking} </span> */}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-allocated-bookings"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Allocated but not Delivered{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count?.AllocatedbutnotDeliveredCount
                                      ? count?.AllocatedbutnotDeliveredCount
                                      : 0}{" "}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-allotment-pending"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Delivery Due but Allocation Pending{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count?.DeliveryDuebutAllocationPendingCount
                                      ? count?.DeliveryDuebutAllocationPendingCount
                                      : 0}{" "}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-waiting-period-analysis"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Waiting Period Analysis{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count?.WaitingPeriodAnalysisCount
                                      ? count?.WaitingPeriodAnalysisCount
                                      : 0}{" "}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-cancelled-bookings"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Cancelled Bookings{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count.CancelledBookingCount
                                      ? count.CancelledBookingCount
                                      : 0}{" "}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-delievred-cars"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Delivered Cars{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {count?.bookingdelivered
                                      ? count?.bookingdelivered
                                      : 0}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-auto-allocation-cancelled"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Auto Allocation Cancelled
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count.autoallocationcancelledcount
                                      ? count.autoallocationcancelledcount
                                      : 0}{" "}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-reallocation-requests"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-Red-200",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Reallcation Requests{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {" "}
                                    {count.reallocation_count
                                      ? count.reallocation_count
                                      : 0}{" "}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <Menu as="div" className="relative  text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full  text-gray-300 hover:bg-Red-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                          Manage Team
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className=" right-0 mt-2  z-10 origin-top-right rounded-md bg-white shadow-lg text-Black   ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/asm"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  ASM
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/sm-manage-executive"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-Red-100"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Manage Executive
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );

      case "black navbar":
        return (
          <Disclosure as="nav" className="bg-zinc-800 text-White">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-zinc-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          onClick={() => navigate("/stm-dashboard")}
                          className="block sm:h-8 h-6 w-auto lg:hidden"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                        <img
                          onClick={() => navigate("/stm-dashboard")}
                          className="hidden sm:h-8 h-6 w-auto lg:block cursor-pointer"
                          src="/assets/stockone_logo.svg"
                          alt="Stock One Icon"
                        />
                      </div>
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex space-x-4">
                          {AllocationNavigation.slice(0, 1).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-zinc-600 text-white"
                                  : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/stm-allocation-awaited" ||
                                    pathname === "/stm-auto-allocation" ||
                                    pathname === "/stm-re-allocation-request" ||
                                    pathname === "/stm-allocated-vin-awaited" ||
                                    pathname === "/stm-allocation-status" ||
                                    pathname ===
                                    "/stm-booking-cancellation-by-showroom-manager" ||
                                    pathname === "/stm-todays-allocation" ||
                                    pathname === "/stm-allocation-history"
                                    ? "  inline-flex w-full justify-center text-gray-300 bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : "bg-zinc-800  inline-flex w-full justify-center text-gray-300 hover:bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                }
                              >
                                Manage Allocation
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-allocation-awaited"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Allocation Awaited
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {
                                            allocationCount.allocationawaitedcount
                                          }
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-re-allocation-request"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Re-Allocation Request
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {allocationCount.reallocationtabcount}
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-allocated-vin-awaited"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Allocated But VIN Awaited
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {
                                            allocationCount.allocatedbutvinawaited
                                          }
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-allocation-status"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Allocation Status
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {
                                            allocationCount.allocationstatuscount
                                          }
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-auto-allocation"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Auto Allocation Cancelled
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {
                                            allocationCount.autoallocationcancelledcount
                                          }
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-booking-cancellation-by-showroom-manager"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Cancellation by Showroom Manager
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {
                                            allocationCount.cancelledbyshowroommanagercount
                                          }
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>

                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-allocation-history"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Allocation History
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {
                                            allocationCount.allocatioHistorycount
                                          }
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-todays-allocation"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Todays Allocation
                                        <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                          {allocationCount.allocation_datewise}
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname ===
                                    "/stm-allocation-status-report" ||
                                    pathname === "/stm-delivered-cars" ||
                                    pathname === "/stm-detailed-allocation-report"
                                    ? "  inline-flex w-full justify-center text-gray-300 bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : "bg-zinc-800  inline-flex w-full justify-center text-gray-300 hover:bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                }
                              >
                                Manage Reports
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-delivered-cars"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delivered Cars Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-allocation-status-report"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Allocation Status Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/stm-detailed-allocation-report"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Detailed Allocation Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/confirm-booking-cancellation-by-cbt"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-600"
                                            : "text-gray-600",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Confirm Booking Cancellation By CBT
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          {AllocationNavigation.slice(
                            1,
                            AllocationNavigation.length
                          ).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-zinc-600 text-white"
                                  : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}

                          <a
                            className={
                              pathname.includes("/stm-notification")
                                ? " bg-zinc-600 text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer flex items-center"
                                : "flex items-center text-gray-300 hover:bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                            }
                            href="/stm-notifications"
                          >
                            <IoIosNotifications fontSize={20} />{" "}
                            <span className="mt-[-1rem] text-xs ">
                              {" "}
                              {allocationCount.unreadNotifications}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">
                              {localStorage.getItem("FirstName") &&
                                localStorage.getItem("LastName")
                                ? `${localStorage
                                  .getItem("FirstName")
                                  .slice(0, 1)}${localStorage
                                    .getItem("LastName")
                                    .slice(0, 1)}`
                                : ""}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    SignOut();
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {AllocationNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.href === pathname
                            ? "bg-zinc-800 text-white"
                            : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}

                    <Menu as="div" className="relative  text-left">
                      <div>
                        <Menu.Button
                          className={
                            pathname === "/stm-allocation-awaited" ||
                              pathname === "/stm-auto-allocation" ||
                              pathname === "/stm-re-allocation-request" ||
                              pathname === "/stm-allocated-vin-awaited" ||
                              pathname === "/stm-allocation-status" ||
                              pathname ===
                              "/stm-booking-cancellation-by-showroom-manager"
                              ? "inline-flex w-full  text-gray-300 bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "inline-flex w-full  text-gray-300 hover:bg-zinc-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Manage Allocation
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className=" right-0 mt-2  z-10 origin-top-right rounded-md bg-white shadow-lg text-Black   ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/stm-allocation-awaited"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Allocation Awaited
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {allocationCount.allocationawaitedcount}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/stm-re-allocation-request"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Re-Allocation Request{" "}
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {allocationCount.reallocationtabcount}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/stm-allocated-vin-awaited"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Allocated But VIN Awaited
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {allocationCount.allocatedbutvinawaited}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/stm-allocation-status"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Allocation Status
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {allocationCount.allocationstatuscount}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/stm-auto-allocation"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Auto Allocation Cancelled
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {
                                      allocationCount.autoallocationcancelledcount
                                    }
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="
                                  /stm-booking-cancellation-by-showroom-manager"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Booking Cancellation by Showroom Manager
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {
                                      allocationCount.cancelledbyshowroommanagercount
                                    }
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-600"
                                      : "text-gray-600",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Showroom Quota
                                  <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                                    {allocationCount.showroomquotacount}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );

      case "green navbar":
        return (
          <Disclosure as="nav" className="bg-pink-600 text-White">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-pink-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          onClick={() => navigate("/asm-dashboard")}
                          className="block sm:h-8 h-6 w-auto lg:hidden"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                        <img
                          onClick={() => navigate("/asm-dashboard")}
                          className="hidden sm:h-8 h-6 w-auto lg:block cursor-pointer"
                          src="/assets/stockone_logo.svg"
                          alt="Stock One Icon"
                        />
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">
                              {localStorage.getItem("FirstName") &&
                                localStorage.getItem("LastName")
                                ? `${localStorage
                                  .getItem("FirstName")
                                  .slice(0, 1)}${localStorage
                                    .getItem("LastName")
                                    .slice(0, 1)}`
                                : ""}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    SignOut();
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
        );

      case "admin navbar":
        return (
          <Disclosure as="nav" className="bg-[#5B3256] text-White">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-Red-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          onClick={() => navigate("/creta-dashboard")}
                          className="block sm:h-8 cursor-pointer h-6 w-auto lg:hidden"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                        <img
                          onClick={() => navigate("/creta-dashboard")}
                          className="hidden sm:h-8 cursor-pointer h-6 w-auto lg:block"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex space-x-4">
                          {ADMINNavigation.slice(0, 1).map((item) => (
                            <a
                              onClick={() => setActive(item.name)}
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-[#8d5286] text-white"
                                  : "text-gray-300 hover:bg-[#8d5286] hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/creta-view-stock-detail" ||
                                    pathname === "/creta-activity-log" || pathname === "/creta-delete-car"
                                    ? "bg-[#8d5286] inline-flex w-full justify-center text-gray-300 hover:bg-[#8d5286] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-[#8d5286] hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                EDP Reports
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-view-stock-detail"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-[#8d5286]  "
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        View Stock Detail
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-activity-log"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-[#8d5286]  "
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Activity Log
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-delete-car"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-[#8d5286]  "
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Deleted Cars Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname === "/creta-delivered-cars" ||
                                    pathname === "/creta-todays-allocation" ||
                                    pathname === "/creta-all-stock-details" ||
                                    pathname === "/creta-allocation-status"
                                    ? "bg-[#8d5286] inline-flex w-full justify-center text-gray-300 hover:bg-[#8d5286] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-[#8d5286] hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                STM Reports
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="text-Black absolute z-20 right-0 mt-2 w-max origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1 ">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/creta-delivered-cars")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100   text-[#8d5286]"
                                            : "text-[#8d5286]",
                                          " px-4 py-2 text-sm flex  flex-between cursor-pointer"
                                        )}
                                      >
                                        <span>Delivered Cars</span>{" "}
                                        {/* <span className="bg-gray-200 px-3 ml-2 rounded-3xl"> {props.dummyData?.pendingBooking} </span> */}
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/creta-todays-allocation")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100   text-[#8d5286]"
                                            : "text-[#8d5286]",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Todays Allocations
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/creta-all-stock-details")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100   text-[#8d5286]"
                                            : "text-[#8d5286]",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        All Stocks Detail
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() =>
                                          navigate("/creta-allocation-status")
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100   text-[#8d5286]"
                                            : "text-[#8d5286]",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Allocation Status
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          {/* ---------------------reports */}
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={
                                  pathname ===
                                    "/creta-booking-report-cumulative" ||
                                    pathname ===
                                    "/creta-booking-report-monthly" ||
                                    pathname === "/creta-booking-cancel-yearly" ||
                                    pathname ===
                                    "/creta-booking-cancel-monthly" ||
                                    pathname ===
                                    "/creta-delivery-report-yearly" ||
                                    pathname ===
                                    "/creta-delivery-report-monthly" ||
                                    pathname === "/creta-detailed-booking-report"
                                    ? "bg-[#8d5286] inline-flex w-full justify-center text-gray-300 hover:bg-[#8d5286] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    : `inline-flex w-full justify-center text-gray-300 hover:bg-[#8d5286] hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                                }
                              >
                                SRM Reports
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute text-Black right-0 mt-2 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-booking-report-cumulative"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100   text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Report Cumulative
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-booking-report-monthly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100  text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Report Monthly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-booking-cancel-yearly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100  text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Cancel Yearly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-booking-cancel-monthly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100  text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Booking Cancel Monthly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-delivery-report-yearly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100  text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delivery Report Yearly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-delivery-report-monthly"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100  text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Delivery Report Monthly
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/creta-detailed-booking-report"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100  text-[#8d5286]"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Detailed Booking Report
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          {/* -------------------------------- */}
                          {/* #real_time */}
                          {ADMINNavigation.slice(1, 2).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-[#8d5286] text-white"
                                  : "text-gray-300 hover:bg-[#8d5286] hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">

                              {getFullName(localStorage.getItem("UserName"))}

                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => SignOut()}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-[#8d5286] cursor-pointer"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                {/* #Mobile */}

              </>
            )}
          </Disclosure>
        );
      case "CBT":
        return (
          <Disclosure as="nav" className="bg-zinc-800 text-White">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-zinc-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          onClick={() => navigate("/stm-dashboard")}
                          className="block sm:h-8 h-6 w-auto lg:hidden"
                          src="/assets/stockone_logo.svg"
                          alt="Workflow"
                        />
                        <img
                          onClick={() => navigate("/stm-dashboard")}
                          className="hidden sm:h-8 h-6 w-auto lg:block cursor-pointer"
                          src="/assets/stockone_logo.svg"
                          alt="Stock One Icon"
                        />
                      </div>
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex space-x-4">
                          {CBTNavigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.href === pathname
                                  ? "bg-zinc-600 text-white"
                                  : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
 
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">
                              {localStorage.getItem("FirstName") &&
                                localStorage.getItem("LastName")
                                ? `${localStorage
                                  .getItem("FirstName")
                                  .slice(0, 1)}${localStorage
                                    .getItem("LastName")
                                    .slice(0, 1)}`
                                : ""}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    SignOut();
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {CBTNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.href === pathname
                            ? "bg-zinc-800 text-white"
                            : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}

                  
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
        case "GM navbar":
          return (
            <Disclosure as="nav" className="bg-zinc-800 text-White">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7lg px-2 sm:px-6 lg:px-28 xl:px-28">
                    <div className="relative flex h-16 items-center justify-between">
                      <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                        {/* Mobile menu button*/}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-zinc-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                        <div className="flex flex-shrink-0 items-center">
                          <img
                            onClick={() => navigate("/stm-dashboard")}
                            className="block sm:h-8 h-6 w-auto lg:hidden"
                            src="/assets/stockone_logo.svg"
                            alt="Workflow"
                          />
                          <img
                            onClick={() => navigate("/stm-dashboard")}
                            className="hidden sm:h-8 h-6 w-auto lg:block cursor-pointer"
                            src="/assets/stockone_logo.svg"
                            alt="Stock One Icon"
                          />
                        </div>
                        <div className="hidden lg:ml-6 lg:block">
                          <div className="flex space-x-4">
                            {GMNavigation.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  item.href === pathname
                                    ? "bg-zinc-600 text-white"
                                    : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                                  "px-3 py-2 rounded-md text-sm font-medium"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </a>
                            ))}
  
                     
                          </div>
                        </div>
                      </div>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 sm:pr-0">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <div className="h-8 w-8 rounded-full bg-white text-black justify-center flex items-center">
                                {localStorage.getItem("FirstName") &&
                                  localStorage.getItem("LastName")
                                  ? `${localStorage
                                    .getItem("FirstName")
                                    .slice(0, 1)}${localStorage
                                      .getItem("LastName")
                                      .slice(0, 1)}`
                                  : ""}
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() => {
                                      SignOut();
                                    }}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                  >
                                    Sign out
                                  </div>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
  
                  <Disclosure.Panel className="lg:hidden">
                    <div className="space-y-1 px-2 pt-2 pb-3">
                      {GMNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.href === pathname
                              ? "bg-zinc-800 text-white"
                              : "text-gray-300 hover:bg-zinc-600 hover:text-white",
                            "block px-3 py-2 rounded-md text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
  
                 
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
  
        default:
        console.log("navbar type not found");
    }
  };
  return switchNavbar(props.navbarType);
};

export default Navbar;
