import React, { useEffect, useState } from 'react'
import SmWrapper from '../../Components/SmWrapper'
import Heading from '../../tailwindUI/Heading'
import classes from "../../module.css/Dashboard.module.css";
import { useNavigate, useParams } from 'react-router-dom';
import '../../index.css';
import {
    GridComponent, ColumnsDirective, ColumnDirective, Page,
    Inject,
    Resize,
    Group, Sort, ColumnMenu, Filter, Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import { registerLicense } from '@syncfusion/ej2-base';
import ErrorMessage from '../../tailwindUI/ErrorMessage';
const DashBoardReports = () => {
    const reportname = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        SRMBookingReportMonthlyApi();
    }, []);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/login", { replace: true });
    };

    const SRMBookingReportMonthlyApi = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
            'Authorization',
            'Token 8cb23c1e5efb27dbadefabe601226a4264f59daa'
        );
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
            UserToken: localStorage.getItem("UserToken"),
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        callApiBasedOnUrl(requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login" ||
                    result.message === "Please login with a different account"
                ) {
                    LogOut();
                }
                else if (result.status_code === 200 && result.status === "Success") {
                    setData(result.data)
                }
                else if (result.status_code === 400) {
                    setErrorMessage("Something went wrong please contact support !");
                }
            })
            .catch((error) => { console.log('error', error); setErrorMessage("Something went wrong please contact support !"); })
            .finally(() => setLoading(false))
    };

    const groupOptions = { showGroupedColumn: true };
    const filterSettings = { type: "CheckBox" };


    registerLicense(
        'Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9'
    );

    const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];
    let gridInstance;
    let checkboxObj;
    let flag = true;
    function dataBound() {
        if (flag) {
            gridInstance.toolbarModule.toolbar.hideItem(2, true);
            flag = false;
        }
    }

    function toolbarClick(args) {
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }


    const callApiBasedOnUrl = (requestOptions) => {
        let basePath = "https://promote.onecorp.co.in/api/v1/stockone/srm"
        if (reportname.reportname === "stock-allocated-today") {
            return fetch(
                `${basePath}/SRMStockAllocatedToday`,
                requestOptions
            )
        }
        else if (reportname.reportname === "delivery-scheduled-today") {
            return fetch(
                `${basePath}/SRMdeliveryscheduledtoday`,
                requestOptions
            )
        }
        else if (reportname.reportname === "delivery-request-pending") {
            return fetch(
                `${basePath}/SRMPendingDelivery`,
                requestOptions
            )
        }

        else if (reportname.reportname === "delivered-this-month") {
            return fetch(
                `${basePath}/SRMdeliverytilldate`,
                requestOptions
            )
        }
        else if (reportname.reportname === "total-active-bookings") {
            return fetch(
                `${basePath}/SRMactivebookingmonthaly`,
                requestOptions
            )
        }
        else if (reportname.reportname === "allocated") {
            return fetch(
                `${basePath}/SRMcurrentmonthallocated`,
                requestOptions
            )
        }
        else if (reportname.reportname === "allocation-awaited") {
            return fetch(
                `${basePath}/SRMcurrentmonthawaited`,
                requestOptions
            )
        }
        else if (reportname.reportname === "auto-allocation-cancelled") {
            return fetch(
                `${basePath}/SRMcurrentmonthautocancelled`,
                requestOptions
            )
        }
        else if (reportname.reportname === "booking-cancelled") {
            return fetch(
                `${basePath}/SRMcurrentmonthcancelled`,
                requestOptions
            )
        }
        else if (reportname.reportname === "booking-cancelled-yearly") {
            return fetch(
                `${basePath}/SRMcurrentyearcancelled`,
                requestOptions
            )
        }
        else if (reportname.reportname === "current-month-bookings") {
            return fetch(
                `${basePath}/SRMCurrentMonthBooking`,
                requestOptions
            )
        }
    }

    return (
        <SmWrapper>
            {errorMessage && <ErrorMessage message={errorMessage} />}

            <div className="mb-16">
                <div className={classes.heading}>
                    <Heading
                        headingType={"Section Headings"}
                        BreadCrumHomeLink={"/sm-dashboard"}
                        headingText={reportname.reportname.replaceAll("-", "    ") + " " + "report"}
                        subheadingText={localStorage.getItem("Location")}
                    />

                    {loading ?
                        <div className={classes.loader}>
                            <img src="/assets/loadingGif.gif" alt="loading" />
                        </div> :
                        <div className='control-pane'>
                            <div className='control-section'>
                                <GridComponent
                                    dataSource={data}
                                    height='500'
                                    allowPaging={true}
                                    allowResizing={true}
                                    allowGrouping={true}
                                    allowSorting={true}
                                    allowFiltering={true}
                                    showColumnMenu={true}
                                    groupSettings={groupOptions}
                                    filterSettings={filterSettings}
                                    toolbar={toolbarOptions}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbarClick={toolbarClick.bind(this)}
                                    ref={(grid) => (gridInstance = grid)}
                                    dataBound={dataBound.bind(this)}
                                    pageSettings={{ pageCount: 4, pageSizes: true }}
                                    autoFit={true}


                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='AsmName' headerText='ASM' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='ExecutiveName' headerText='Executive' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='FullName' headerText='Customer Name' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='customer_mobile' headerText='Customer Mobile' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='booking_amount' headerText='Booking Amount' width='120' textAlign='center' />
                                        <ColumnDirective field='booking_date' headerText='Booking Date' width='120' textAlign='center' />
                                        <ColumnDirective field='booking_id' headerText='Booking Id' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='receipt_num' headerText='Receipt No.' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='exchange' headerText='Exchange' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='win_num' headerText='VIN' width='150' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='status' headerText='Allocation Status' width='120' textAlign='center'></ColumnDirective>

                                        <ColumnDirective field='car_model' headerText='Model1' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='car_variant' headerText='Variant1' width='150' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='car_colour' headerText='Colour1' width='120' textAlign='center'></ColumnDirective>

                                        <ColumnDirective field='CarModel2' headerText='Model2' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CarVariant2' headerText='Variant2' width='150' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CarColour2' headerText='Colour2' width='120' textAlign='center'></ColumnDirective>

                                        <ColumnDirective field='CSModel' headerText='Allocated Model' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CSVarient' headerText='Allocated Variant' width='150' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CSColour' headerText='Allocated Colour' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CSFuleType' headerText='Allocated Fuel' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='carstatus' headerText='Car Status' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='allocated_on' headerText='Allocated On' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CreatedAt' headerText='Created On' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CustomerSpecialNeed' headerText='Customer Special Need' width='150' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='EstimatedDeliveryDate' headerText='Estimated Delivery Date' width='120' textAlign='center'></ColumnDirective>

                                    </ColumnsDirective>
                                    <Inject services={[Resize, Group, Sort, ColumnMenu, Filter, Page, Toolbar, ExcelExport, PdfExport]} />
                                </GridComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </SmWrapper >)
}

export default DashBoardReports