import React from 'react'
import classes from "../module.css/Wrapper.module.css"
import Navbar from "../tailwindUI/Navbar";

const SmWrapper = (props) => {
  return (
    <div>
      <Navbar dummyData={props.dummyData} navbarType={"red navbar"} />
      <div className={classes.body}>{props.children}</div>
    </div>
  )
}

export default SmWrapper