import React, { useEffect } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/EdpDashboard.module.css";
import { useState } from "react";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import StatsCard from "../../tailwindUI/StatsCard";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import EDPTableCopyClipBoard from "../../Components/EDPTableCopyClipBoard";

const AdminDashboard = () => {
    const getUsersData = JSON.parse(localStorage.getItem("userdata"));
    const edpFilter = getUsersData?.filter((edp) => edp.UserRole === "EDP");
    const edpToken = edpFilter[0].UserToken;

    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [apiFailled, setApiFailled] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [eDPStockcountOfCarCount, setEDPStockcountOfCar] = useState({
        InTransitcar: "",
        PendingDeliveries: "",
        Pendingcar: "",
        Physicalcar: "",
        Totalcar: "",
    });
    const [modelWiseDLD, setModelWiseDLD] = useState([]);
    const [totalStockModelWise, setTotalStockModelWise] = useState([]);

    useEffect(() => {
        EDPStockcountOfCar();
        EDPstockdeliveredmodelwise();
    }, []);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/creta", { replace: true });
    };

    const EDPStockcountOfCar = () => {
        setloading(true);

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: edpToken,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockcountOfCar",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "fail" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data === "You dont have permission to access model list" &&
                    result.message === "Please login with a different account"
                ) {
                    LogOut();
                } else if (result.status_code === 200 && result.status === "Success") {
                    setEDPStockcountOfCar({
                        InTransitcar: result.InTransitcar,
                        PendingDeliveries: result.PendingDeliveries,
                        Pendingcar: result.Pendingcar,
                        Physicalcar: result.Physicalcar,
                        Totalcar: result.Totalcar,
                    });
                } else {
                    setApiFailled(true);
                    setMessage(result.Reason);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            })
            .finally(() => setloading(false));
    };

    const EDPstockdeliveredmodelwise = () => {
        setloading(true);

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: edpToken,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPstockdeliveredmodelwise",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data === "You dont have permission to access model list" &&
                    result.message === "Please login with a different account"
                ) {
                    LogOut();
                } else if (result.status === "Success") {
                    setModelWiseDLD(result.stockdeliveredmodelwise);
                    setTotalStockModelWise(result.freestock);
                } else {
                    setApiFailled(true);
                    setMessage(result.Reason);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            })
            .finally(() => setloading(false));
    };

    const successMessage = () => {
        setTimeout(() => {
            setMessage("");
        }, 3000);
        return message;
    };

    const data = [
        {
            model_name: "Total",
            count: eDPStockcountOfCarCount.Totalcar,
            url: "/creta-view-stock-detail",
            icon: "../assets/logos/total.png",
        },
        {
            model_name: "Pending",
            count: eDPStockcountOfCarCount.Pendingcar,
            url: "/creta-view-stock-detail?term=Pending",
            icon: "../assets/logos/pending.png",
        },
        {
            model_name: "InTransit",
            count: eDPStockcountOfCarCount.InTransitcar,
            url: "/creta-view-stock-detail?term=InTransit",
            icon: "../assets/logos/transit.png",
        },
        {
            model_name: "Physical",
            count: eDPStockcountOfCarCount.Physicalcar,
            url: "/creta-view-stock-detail?term=Physical",
            icon: "../assets/logos/physical.png",
        },
        {
            model_name: "Pending Deliveries",
            count: eDPStockcountOfCarCount.PendingDeliveries,
            // url: "/edp-manage-delivery",
            icon: "../assets/logos/delivered.png",
        },
    ];

    return (
        <>
            {message && (
                <SuccessMessage
                    onClose={() => setMessage("")}
                    message={successMessage()}
                    apiFailled={apiFailled}
                />
            )}

            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Navbar navbarType={"admin navbar"} />
            {loading ? (
                <img
                    src="../assets/loadingGif.gif"
                    alt="loading"
                    className={classes.loader}
                />
            ) : (
                <div className={classes.maincontainer}>
                    <Heading headingType={"Page Headings"} headingText={"Dashboard"} />

                    <div className={classes.background}>
                        <StatsCard
                            type="EDP Total Real Time Stock ui changes"
                            data={data}
                            typewidth={"width"}
                        />
                        <div className="flex items-start my-4 gap-4">
                            <EDPTableCopyClipBoard
                                data={modelWiseDLD}
                                caption="Model Wise Delivered"
                                className=" border-indigo-600 bg-indigo-700 text-white font-semibold cursor-pointer py-2"
                            />
                            <EDPTableCopyClipBoard
                                data={totalStockModelWise}
                                caption="Total Stock Model Wise"
                                className="border-pink-600 bg-pink-700 text-white font-semibold cursor-pointer py-2"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminDashboard;
