import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/StmDashboard.module.css";
import Button from "../../tailwindUI/Button";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import StatsCard from "../../tailwindUI/StatsCard";
import Modal from "../../tailwindUI/Modal";
import Table from "../../tailwindUI/Table";
import TableHeader from "../../Components/TableHeader";
import Pagination from "../../tailwindUI/Pagination";
import DataNotFound from "../../Components/DataNotFound";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";

const StmDashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;

  const [message, setMessage] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalstockcount, setTotalstockcount] = useState({
    totalstockcountobj: "",
    unAllocatedPriorityCount: "",
    hiddenStockCount: "",
    allocationAgingCount: "",
  });
  const [physicalnotdeliveredcount, setPhysicalnotdeliveredcount] =
    useState("");
  const [intransitnotdeliveredcount, setIntransitnotdeliveredcount] =
    useState("");
  const [pendingnotdeliveredcount, setPendingnotdeliveredcount] = useState("");
  const [loading, setLoading] = useState(false);
  const [aMUsersLocationArjunganj, setAMUsersLocationArjunganj] = useState([]);
  const [aMUsersLocationStation, setAMUsersLocationStation] = useState([]);
  const [aMUsersLocationKanpur, setAMUsersLocationKanpur] = useState([]);
  const [aMUsersLocationTelibagh, setAMUsersLocationTelibagh] = useState([]);
  const [aMUsersLocationRaebareli, setAMUsersLocationRaebareli] = useState([]);
  const [aMUsersLocationJagdishpur, setAMUsersLocationJagdishpur] = useState(
    []
  );
  const [aMUsersLocationSultanpur, setAMUsersLocationSultanpur] = useState([]);
  const [currentMonthCount, setCurrentMonthCount] = useState({
    currentmonthactive: "",
    currentmonthallocated: "",
    currentmonthautocancelled: "",
    currentmonthawaited: "",
    currentmonthcancelled: "",
    currentmonthunallocatedpriority: "",
    currentmonthdelivered: "",
  });

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [amAllStock, setAmAllStock] = useState([]);
  const [amAllStockData, setAmAllStockData] = useState([]);
  const [amAllStockCount, setAmAllStockCount] = useState();
  const maxPages = Math.ceil(amAllStockCount / 20);

  const users = localStorage.getItem("UserToken");

  useEffect(() => {
    totalCountApi();
    AMPhyInTraPenApi();
    AMCurMonthDelApi();
    AMUsersLocationAPi();
  }, []);

  useEffect(() => {
    AMAllStockDetailAPI();
  }, [searchTerm === "", page]);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const showStockApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPshowCarStock",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data === "Stock Show Updated Successfully" &&
          result.message === "Stock Show Updated Successfully"
        ) {
          setOpen(false);
          setMessage(result.message);
          totalCountApi();
          setApiFailled(false);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data === "There is no  stock to updated" &&
          result.message === "There is no  stock to updated"
        ) {
          setOpen(false);

          setApiFailled(true);
          setMessage(result.message);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const totalCountApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMTotalStock",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setTotalstockcount({
            totalstockcountobj: result.totalstockcount,
            unAllocatedPriorityCount: result.unAllocatedPriorityCount,
            hiddenStockCount: result.hiddenStockCount,
            allocationAgingCount: result.allocationAgingCount,
          });
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMPhyInTraPenApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMPhyInTraPen",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setPendingnotdeliveredcount(result.pendingnotdeliveredcount);
          setIntransitnotdeliveredcount(result.intransitnotdeliveredcount);
          setPhysicalnotdeliveredcount(result.physicalnotdeliveredcount);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMCurMonthDelApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMcountApi",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setCurrentMonthCount({
            currentmonthactive:
              result.data.Allocated +
              result.data.AllocationAwaited +
              result.data.AutoAllocationCancelled,
            currentmonthallocated: result.data.Allocated,
            currentmonthautocancelled: result.data.AutoAllocationCancelled,
            currentmonthawaited: result.data.AllocationAwaited,
            currentmonthcancelled: result.data.Cancelled,
            currentmonthunallocatedpriority: result.data.Delivered,
            currentmonthdelivered: result.data.MonthlyDelivered
          });
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMUsersLocationAPi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMUsersLocation",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setAMUsersLocationArjunganj(result.Arjunganj);
          setAMUsersLocationStation(result.Station);
          setAMUsersLocationKanpur(result.Kanpur);
          setAMUsersLocationTelibagh(result.Telibagh);
          setAMUsersLocationRaebareli(result.Raebareli);
          setAMUsersLocationJagdishpur(result.Jagdishpur);
          setAMUsersLocationSultanpur(result.Sultanpur);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMAllStockDetailAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
      page: page,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AMAllStockDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          if (searchTerm && result.data.length < 2) {
            navigate(pathname);
          }
          setAmAllStock(result.data);
          setAmAllStockData(result.alldata);
          setAmAllStockCount(result.count);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const datastockstatus = [
    {
      model_name: "Pending",
      count: pendingnotdeliveredcount,
      icon: "../assets/logos/pending.png",
    },
    {
      model_name: "InTransit",
      count: intransitnotdeliveredcount,
      icon: "../assets/logos/transit.png",
    },
    {
      model_name: "Physical",
      count: physicalnotdeliveredcount,
      icon: "../assets/logos/physical.png",
    },
  ];

  const currentMonth = [
    {
      Model: "Hidden Stock",
      Count: totalstockcount.hiddenStockCount,
      icon: "../assets/logos/hidden.png",
    },
    {
      Model: "Total Stock",
      Count: totalstockcount.totalstockcountobj,
      icon: "../assets/logos/stock.png",
    },
    {
      Model: "Allocated",
      Count: currentMonthCount.currentmonthallocated,
      icon: "../assets/logos/allocate.png",
    },
    {
      Model: "Auto Allocation Cancelled",
      Count: currentMonthCount.currentmonthautocancelled,
      icon: "../assets/logos/cancel.png",
    },
    {
      Model: "Allocation Awaited",
      Count: currentMonthCount.currentmonthawaited,
      icon: "../assets/logos/await.png",
    },
    {
      Model: "Allocation Cancelled",
      Count: currentMonthCount.currentmonthcancelled,
      icon: "../assets/logos/pending.png",
    },
    {
      Model: "Monthly Allocation Delivered",
      Count: currentMonthCount.currentmonthdelivered,
      icon: "../assets/logos/monthly.png",
    },
    {
      Model: "Total Allocation Delivered",
      Count: currentMonthCount.currentmonthunallocatedpriority,
      icon: "../assets/logos/autocancel.png",
    },
  ];

  const tablecolumns = [
    {
      name: "Model",
    },
    {
      name: "Fuel",
    },
    {
      name: "Variant",
    },
    {
      name: "Colour",
    },

    {
      name: "VIN No.",
    },
    {
      name: "Type",
    },
    {
      name: "YOM",
    },
    {
      name: "Status",
    },
    {
      name: "Allocation Status",
    },
    {
      name: "Outlet",
    },

    {
      name: "ASM",
    },
    {
      name: "Executive",
    },
    {
      name: "Customer Name",
    },
    {
      name: "Customer No.",
    },
    {
      name: "Allocation Date",
    },
    {
      name: "Truck/Rail No.",
    },
    {
      name: "Driver Name",
    },
    {
      name: "Driver No.",
    },
    {
      name: "Last Location",
    },
    {
      name: "Car Aging",
    },
    {
      name: "Allocation Aging (Day's)",
    },
  ];

  return (
    <>
      {open && (
        <Modal
          setOpen={setOpen}
          desc={`Are you sure you want to show stock ?`}
          heading={"Show Stock"}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          onClick={() => showStockApi()}
          type="Simple alert"
        />
      )}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}

      <Navbar navbarType={"black navbar"} />

      <div className="mb-4">
        <Heading headingType={"Page Headings"} headingText={"Dashboard"} />
      </div>
      {loading ? (
        <img
          src="../assets/loadingGif.gif"
          alt="loading"
          className={classes.loader}
        />
      ) : (
        <div className={classes.form}>
          <div className="mb-4">
            <Button
              type="redFilled"
              buttonText={"Show Stock"}
              onClick={() => setOpen(true)}
            />
          </div>

          <StatsCard type="Edp Model Fuel Ui Change"
            url={"/stm-hidden-stock-report"}
            data={currentMonth} />

          <div className="text-base font-bold leading-6 text-gray-900 mt-2 ">
            Total Status Count
          </div>

          <StatsCard
            type="Edp Model Fuel Ui Change"
            data={datastockstatus}
          />

          <StatsCard
            type="AMCurMonthDel Ui Change"
            data={totalstockcount.allocationAgingCount}
            url={"/stm-allocation-status?term=AllocationAging"}
            heading={" Allocation Aging"}
          />

          <div className="flex justify-between gap-2">
            <StatsCard
              type="AM Users Location New Ui"
              heading={"Station Road- Cars Allocated"}
              data={aMUsersLocationStation}
            />
            <StatsCard
              type="AM Users Location New Ui"
              heading={"Kanpur Road- Cars Allocated"}
              data={aMUsersLocationKanpur}
            />
          </div>

          <div className="flex justify-between gap-2">
            <StatsCard
              type="AM Users Location New Ui"
              heading={"Telibagh Road- Cars Allocated"}
              data={aMUsersLocationTelibagh}
            />

            <StatsCard
              type="AM Users Location New Ui"
              heading={" Arjunganj- Cars Allocated"}
              data={aMUsersLocationArjunganj}
            />
          </div>

          <div className="flex justify-between gap-2">
            <StatsCard
              type="AM Users Location New Ui"
              heading={"  Raebareli Road- Cars Allocated"}
              data={aMUsersLocationRaebareli}
            />

            <StatsCard
              type="AM Users Location New Ui"
              heading={" Sultanpur Road- Cars Allocated"}
              data={aMUsersLocationSultanpur}
            />
          </div>
          <StatsCard
            type="AM Users Location New Ui"
            heading={" Jagdishpur Road- Cars Allocated"}
            data={aMUsersLocationJagdishpur}
          />

          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-bold leading-6 text-gray-900">
                All Stock Detail
              </h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <ExcelDownload
                csvData={amAllStockData}
                fileName="All Model Booking Status Report"
                className="block rounded-md bg-zinc-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
              />
            </div>
          </div>

          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={amAllStockCount}
            pageResult={amAllStock.length}
            placeholderType="Enter Model, Fuel, Variant, Color, YOM, VIN, Status, Allocation Status (For Example:-Grand,i10,Fiery red,Telibagh)"
            apicall={AMAllStockDetailAPI}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />

          {amAllStock.length > 0 ? (
            <Table
              type="stripped rows"
              TableData={amAllStock}
              tableHeading={tablecolumns}
              tabledataType="AMAllStockDetail List"
            />
          ) : (
            <DataNotFound />
          )}

          <div className="bg-white mt-2">
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
      )}
    </>
  );
};

export default StmDashboard;
