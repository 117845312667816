import React, { useEffect, useState } from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/StmAllocationStatus.module.css";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Resize,
    Group,
    Sort,
    ColumnMenu,
    Filter,
    Toolbar,
    ExcelExport,
    PdfExport,
} from "@syncfusion/ej2-react-grids";
import { registerLicense } from "@syncfusion/ej2-base";
import ErrorMessage from "../../tailwindUI/ErrorMessage";

const AdminSMDeliveredCars = () => {
    const getUsersData = JSON.parse(localStorage.getItem("userdata"));

    const stockManagerFilter = getUsersData?.filter(
        (stockManager) => stockManager.UserRole === "StockManager"
    );
    const stockManagerToken = stockManagerFilter[0].UserToken;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        AMDeliveredCarsReportAPI();
    }, []);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/creta", { replace: true });
    };

    const AMDeliveredCarsReportAPI = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: stockManagerToken,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/am/AMDeliveredCarsReport",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login" ||
                    result.message === "Please login with a different account"
                ) {
                    LogOut();
                } else if (result.status_code === 200 && result.status === "Success") {
                    setData(result.data);
                } else if (result.status_code === 400) {
                    setErrorMessage("Something went wrong please contact support !");
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            })
            .finally(() => setLoading(false));
    };

    const groupOptions = { showGroupedColumn: true };
    const filterSettings = { type: "CheckBox" };

    registerLicense(
        "Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9"
    );

    const toolbarOptions = ["ExcelExport", "PdfExport", "CsvExport", "Search"];
    let gridInstance;
    let checkboxObj;
    let flag = true;
    function dataBound() {
        if (flag) {
            gridInstance.toolbarModule.toolbar.hideItem(2, true);
            flag = false;
        }
    }

    function toolbarClick(args) {
        switch (args.item.text) {
            case "PDF Export":
                gridInstance.pdfExport();
                break;
            case "Excel Export":
                gridInstance.excelExport();
                break;
            case "CSV Export":
                gridInstance.csvExport();
                break;
        }
    }

    const BreadCrumb = {
        pagename: "Delivered Cars Report",
        pagePath: "/creta-delivered-cars",
        pagename1: "",
        pagePath1: "",
    };
    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Navbar navbarType={"admin navbar"} />
            <div className={classes.maincontainer}>
                <Heading
                    headingType={"Page Headings"}
                    headingText={"Delivered Cars Report"}
                    // subheadingText={localStorage.getItem("Location")}
                    BreadCrumb={BreadCrumb}
                    BreadCrumHomeLink={"/creta-dashboard"}
                />
                {loading ? (
                    <div className="flex justify-center my-2">
                        <img src="assets/loadingGif.gif" alt="loading" />
                    </div>
                ) : (
                    <div className={classes.background}>
                        <div className="control-pane">
                            <div className="control-section">
                                <GridComponent
                                    dataSource={data}
                                    height="500"
                                    allowPaging={true}
                                    allowResizing={true}
                                    allowGrouping={true}
                                    allowSorting={true}
                                    allowFiltering={true}
                                    showColumnMenu={true}
                                    groupSettings={groupOptions}
                                    filterSettings={filterSettings}
                                    toolbar={toolbarOptions}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbarClick={toolbarClick.bind(this)}
                                    ref={(grid) => (gridInstance = grid)}
                                    dataBound={dataBound.bind(this)}
                                    pageSettings={{ pageCount: 4, pageSizes: true }}
                                    autoFit={true}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective
                                            field="fullname"
                                            headerText="Customer Name"
                                            width="120"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="customer_mobile"
                                            headerText="Customer Mobile"
                                            width="150"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="AsmName"
                                            headerText="ASM"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="ExecutiveName"
                                            headerText="Executive"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="booking_amount"
                                            headerText="Booking Amount"
                                            width="130"
                                            textAlign="center"
                                        />
                                        <ColumnDirective
                                            field="booking_date"
                                            headerText="Booking Date"
                                            width="120"
                                            textAlign="center"
                                        />
                                        <ColumnDirective
                                            field="booking_id"
                                            headerText="Booking Id"
                                            width="130"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="receipt_num"
                                            headerText="Receipt No."
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="exchange"
                                            headerText="Exchange"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="win_num"
                                            headerText="VIN"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="status"
                                            headerText="Allocation Status"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>

                                        <ColumnDirective
                                            field="CSModel"
                                            headerText="Allocated Model"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="CSVarient"
                                            headerText="Allocated Variant"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="CSColour"
                                            headerText="Allocated Colour"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="CSFuleType"
                                            headerText="Allocated Fuel"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="carstatus"
                                            headerText="Car Status"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="allocated_on"
                                            headerText="Allocated On"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="CreatedAt"
                                            headerText="Created On"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="CustomerSpecialNeed"
                                            headerText="Customer Special Need"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                        <ColumnDirective
                                            field="Location"
                                            headerText="Location"
                                            width="150"
                                            textAlign="center"
                                        ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject
                                        services={[
                                            Resize,
                                            Group,
                                            Sort,
                                            ColumnMenu,
                                            Filter,
                                            Page,
                                            Toolbar,
                                            ExcelExport,
                                            PdfExport,
                                        ]}
                                    />
                                </GridComponent>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdminSMDeliveredCars;
