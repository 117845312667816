import { TailSpin } from "react-loader-spinner";
const Button = (props) => {
  const buttonType = (type) => {
    switch (type) {
      case "smallButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              disabled={props.loading}
              className="inline-flex items-center rounded border border-transparent bg-purpleButton px-5 py-2 text-lg font-medium leading-4 text-white shadow-sm hover:bg-Indigo-100 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "orange":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-transparent bg-orange-600 px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-orange-200 focus:outline-none"
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "pinkLong":
        return (
          <button
            type="button"
            onClick={props.onClick}
            className="inline-flex items-center rounded border border-transparent bg-pinkButton px-6 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-lightPink focus:outline-none "
          >
            {props.buttonText}
          </button>
        );
      case "greenLong":
        return (
          <button
            type="button"
            onClick={props.onClick}
            className="inline-flex items-center rounded border border-transparent bg-greenButton px-6 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-lightGreen focus:outline-none "
          >
            {props.loading ? (
              <TailSpin height="18 " width="18" color="white" />
            ) : (
              props.buttonText
            )}
          </button>
        );
      case "redFilled":
        return (
          <>
            <button
              onClick={props.onClick}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-redHeader px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="20" width="20" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "whiteButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-gray bg-white px-5 py-2 text-sm font-medium leading-4 text-gray shadow-sm hover:bg-Gray focus:outline-none "
            >
              {props.buttonText}
            </button>
          </>
        );
      case "greenFilled":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-transparent bg-greenButton px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-lightGreen focus:outline-none"
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "blueFilled":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none"
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "SearchButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded border border-transparent bg-purpleButton px-5 h-10 sm:h-9 text-lg font-medium leading-4 text-white shadow-sm hover:bg-Indigo-100 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "limeFilled":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-transparent bg-lime-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-lime-800 focus:outline-none"
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );

      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{buttonType(props.type)}</div>;
};

export default Button;
