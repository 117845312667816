import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BiTrain } from "react-icons/bi";
import { BsTruck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Card = (props) => {
  const navigate = useNavigate();

  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const cardSwitchHandler = (cardType) => {
    switch (cardType) {
      case "carImage":
        return (

          <div
            key={props.item.id}
            className="bg-white flex flex-col  justify-between"
          >
            <div className="relative  ">
              <div className="relative   w-full overflow-hidden rounded-lg ">
                <img
                  src={props.item.carmodel_image}
                  alt={"car image"}
                  className="h-56 w-full object-contain object-center"
                />
                {/* {props.item.carmodel_image} */}
              </div>
              <div className="relative mt-4 px-4">
                <h3 className="text-sm font-medium text-gray-900">
                  {props.item.model}
                </h3>
              </div>
            </div>
            <div className="mt-6">
              <div
                onClick={() =>
                  navigate(
                    props.url
                      ? props.url + props.item.model.replace(/\//g, '-')
                      : "/sm-real-time-stock-two/" + props.item.model.replace(/\//g, '-')
                  )
                }
                className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-200 py-2 px-8 text-sm font-medium cursor-pointer text-gray-900 hover:bg-gray-300"
              >
                View
                <span className="sr-only">, {props.item.model}</span>
              </div>
            </div>
          </div>
        );
      case "Transit Train List":
        return (
          <>
            {typeof props.trainData === "string"
              ? LogOut()
              :
              props.trainData?.map((item) => {
                return (
                  <ul role="list" className="mt-3 sm:w-1/4  md:w-1/2  xl:w-1/4 lg:w-1/3">
                    <Menu
                      as="div"
                      className="relative inline-block text-left"
                    >
                      <li className="col-span-1 flex rounded-md shadow-sm relative cursor-pointer" onClick={() => {
                        props.setOpentransitinforTrain(true);
                        props.setAllTrainData(item.cars);
                      }}>
                        <div

                          className={classNames(
                            "bg-red-700",
                            "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                          )}
                        >
                          <BiTrain size={35} />
                        </div>
                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                          <div className="flex-1 px-4 py-2 text-sm" >
                            <div className="">
                              <div className="font-medium text-gray-900 hover:text-gray-600">
                                {item.truck_num}
                              </div>
                              <div>{item.etaDate}</div>
                            </div>
                            <p className="text-gray-500">
                              {item.count} Cars In Stock
                            </p>
                          </div>
                          <div className="flex-shrink-0 pr-2">
                            <div>
                              <Menu.Button className="inline-flex mb-2  w-full justify-center px-4 py-2 text-sm font-medium">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>
                            {/* 
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-2 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                          onClick={() => {
                                            props.setOpen(true);
                                            props.setEta(item.etaDate);
                                            props.setDriverName(
                                              item.driver_name
                                            );
                                            props.setDriverNo(item.driver_num);
                                            props.setTruckRailNo(
                                              item.truck_num
                                            );
                                            props.setLastLocation(
                                              item.last_location
                                            );
                                            props.setCarid(item.CarStockIDs);
                                          }}
                                        >
                                          {" "}
                                          Update Status
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          onClick={() =>
                                            props.markAsPhyscialApi(
                                              item.truck_num,
                                              item.etaDate
                                            )
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                        >
                                          Mark as Physical
                                        </div>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition> */}
                          </div>
                        </div>
                      </li>
                    </Menu>
                  </ul>
                );
              })}
          </>

        );
      default:
        return (
          <>
            {typeof props.truckData === "string"
              ? LogOut()
              : props.truckData?.map((item) => {

                return (
                  <ul
                    role="list"
                    className="mt-3 sm:w-1/4  md:w-1/2  xl:w-1/4 lg:w-1/3"
                  >
                    <Menu
                      as="div"
                      className="relative inline-block text-left"
                    >
                      <li className="col-span-1 flex rounded-md shadow-sm relative h-20">
                        <div
                          onClick={() => {
                            props.setOpentransitinfor(true);
                            props.setAllTruckData(item.cars);
                          }}
                          className={classNames(
                            "bg-sky-500 cursor-pointer",
                            "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                          )}
                        >
                          <BsTruck size={35} />
                        </div>
                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                          <div
                            className="flex-1 px-4 py-2 text-sm cursor-pointer"
                            onClick={() => {
                              props.setOpentransitinfor(true);
                              props.setAllTruckData(item.cars);
                            }}
                          >
                            <div className="font-medium text-gray-900 hover:text-gray-600">
                              {item.truck_num === "" ? "" : item.truck_num}
                            </div>
                            <div>
                              {item.etaDate === ""
                                ? ""
                                : dateHandler(item.etaDate)}
                            </div>

                            <p className="text-gray-500">
                              {item.count} Cars In Stock
                            </p>
                          </div>
                          <div className="flex-shrink-0 pr-2">
                            <div>
                              <Menu.Button className="inline-flex mb-2  w-full justify-center px-4 py-2 text-sm font-medium">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-2 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                        onClick={() => {
                                          props.setOpen(true);
                                          props.setEta(item.etaDate);
                                          props.setDriverName(
                                            item.driver_name
                                          );
                                          props.setDriverNo(item.driver_num);
                                          props.setTruckRailNo(
                                            item.truck_num
                                          );
                                          props.setLastLocation(
                                            item.last_location
                                          );
                                          props.setCarid(item.CarStockIDs);
                                        }}
                                      >
                                        Update Status
                                      </div>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() => {
                                          props.setConfirmation(true);
                                          props.getids(item);
                                        }}
                                        // onClick={() =>
                                        //   props.markAsPhyscialApi(
                                        //     item.truck_num,
                                        //     item.etaDate
                                        //   )
                                        // }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        Mark as Physical
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </div>
                        </div>
                      </li>
                    </Menu>
                  </ul>
                );
              })}

          </>
        );
    }
  };
  return cardSwitchHandler(props.cardType);
};

export default Card;
