/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import Button from "./Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PreferenceCard = (props) => {
  // const [selectedMailingLists, setSelectedMailingLists] = useState(
  //   mailingLists[0]
  // );
  //   console.log("abc", selectedMailingLists);
  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };
  const carStatusColorChange = (status) => {
    if (status === "InTransit") {
      return "orange";
    } else if (status === "Physical") {
      return "limeFilled";
    } else if (status === "Pending") {
      return "redFilled";
    } else return;
  };



  const getAgingDate = (dateGet) => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month.toString().length === 1 ? `0${month}` : month
      }-${day}`;
    let allocatedDate = dateGet;
    const diffTime = Math.abs(new Date(currentDate) - new Date(allocatedDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (dateGet !== "") return diffDays + " " + "Days";
    else return;
  };


  const cardSwitchHandler = (cardType) => {
    switch (cardType) {
      case "autoAllocationCancel":
        return (
          <RadioGroup>
            <RadioGroup.Option
              className={() =>
                classNames(
                  `${"border-indigo-500 ring-1 ring-indigo-500"} relative border-2 flex cursor-pointer  rounded-lg  bg-white p-4 shadow-sm focus:outline-none`
                )
              }
            >
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm  text-gray-900 font-bold"
                    >
                      {props.values.model_name} {props.values.variant}{" "}
                      {props.values.fueltype}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm  text-gray-900 font-bold"
                    >
                      <Button
                        buttonText={`${props.values.carstatus}`}
                        type={carStatusColorChange(props.values.carstatus)}
                      />
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-4 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">CDD Date: </span>
                      {props.values.CommittedDeliveryDate
                        ? dateHandler(props.values.CommittedDeliveryDate)
                        : ""}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Physical Date: </span>{" "}
                      {props.values.PhysicalDate
                        ? dateHandler(props.values.PhysicalDate)
                        : ""}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">ETA: </span>
                      {props.values.etaDate
                        ? dateHandler(props.values.etaDate)
                        : ""}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  aria-hidden="true"
                  className={classNames("h-5 w-5 text-indigo-600")}
                />
                <span
                  aria-hidden="true"
                  className={classNames(
                    "border-indigo-500",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                />
              </>
            </RadioGroup.Option>
          </RadioGroup>
        );
      case "Allocated Vin Awaited":
        return (
          <RadioGroup>
            <RadioGroup.Option
              onClick={props.onClick}
              className={() =>
                classNames(
                  `${props.preferenceBookingDetail?.BookingID ===
                  props.preference.BookingID &&
                  "border-indigo-500 ring-1 ring-indigo-500"
                  } relative border-2 flex cursor-pointer  rounded-lg  bg-white p-4 shadow-sm focus:outline-none h-80`
                )
              }
            >
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900 "
                    >
                      <span className="font-bold">Customer Name :</span>
                      {props.preference.customer_name}{" "}
                      {props.preference.LastName}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900 "
                    >
                      <span className="font-bold">Customer No :</span>
                      {props.preference.customer_mobile}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Booking Ref. no : </span>
                      {props.preference.booking_id}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">CDD : </span>
                      {dateHandler(props.preference.CommittedDeliveryDate)}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold"> Location : </span>{" "}
                      {props.preference.Location}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">ASM : </span>{" "}
                      {props.preference.AsmName}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Executive : </span>{" "}
                      {props.preference.ExecutiveName}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Booking Priority : </span>{" "}
                      {props.preference.CustomerSpecialNeed}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Exchange : </span>{" "}
                      {props.preference.exchange}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    props.preferenceBookingDetail?.BookingID !==
                      props.preference.BookingID
                      ? "invisible"
                      : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    props.preferenceBookingDetail?.BookingID ===
                      props.preference.BookingID
                      ? "border-indigo-500"
                      : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            </RadioGroup.Option>
          </RadioGroup>
        );
      case "Allocated Vin Awaited checked":
        return (
          <RadioGroup>
            <RadioGroup.Option
              onClick={props.onClick}
              className={() =>
                classNames(
                  `${"border-indigo-500 ring-1 ring-indigo-500"} relative border-2 flex   rounded-lg  bg-white p-4 shadow-sm focus:outline-none`
                )
              }
            >
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900 "
                    >
                      <span className="font-bold">Customer Name :</span>
                      {props.preference.customer_name}{" "}
                      {props.preference.LastName}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900 "
                    >
                      <span className="font-bold">Customer No :</span>
                      {props.preference.customer_mobile}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Booking Ref. Id : </span>
                      {props.preference.booking_id}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">CDD : </span>
                      {dateHandler(props.preference.CommittedDeliveryDate)}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold"> Location : </span>{" "}
                      {props.preference.Location}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">ASM : </span>{" "}
                      {props.preference.AsmName}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Executive : </span>{" "}
                      {props.preference.ExecutiveName}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Booking Priority : </span>{" "}
                      {props.preference.CustomerSpecialNeed}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Exchange : </span>{" "}
                      {props.preference.exchange}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames("h-5 w-5 text-indigo-600")}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            </RadioGroup.Option>
          </RadioGroup>
        );
      case "Allocation Awaited without cursor":
        return (
          <RadioGroup>
            <RadioGroup.Option
              onClick={props.onClick}
              className={() =>
                classNames(
                  `${props.carId === props.item.CarStockID &&
                  "border-indigo-500 ring-1 ring-indigo-500"
                  } relative border-2 flex   rounded-lg  bg-white p-4 shadow-sm focus:outline-none h-56`
                )
              }
            >
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <Button
                      buttonText={`${props.item.carstatus}`}
                      type={carStatusColorChange(props.item.carstatus)}
                    />
                    {props.item.carstatus === "InTransit" ? (
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 block text-sm  text-gray-900 font-bold"
                      >
                        <span className="font-bold">ETA: </span>
                        {props.item.etaDate
                          ? dateHandler(props.item.etaDate)
                          : ""}
                      </RadioGroup.Description>
                    ) : (
                      ""
                    )}

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm  text-gray-900 font-bold"
                    >
                      {props.item.availabilitystatus
                        ? props.item.availabilitystatus
                        : ""}
                    </RadioGroup.Description>
                    {/* <div className="font-bold text-sm">3-dec-2022</div>
  
                <div className="font-bold  text-sm">Free Stock</div> */}

                    {/* <RadioGroup.Label
                  as="span"
                  className="mt-1 block text-sm font-semibold text-gray-900"
                >
                  {props.item.signoff_date
                    ? dateHandler(props.item.signoff_date)
                    : "Sign Off Date : N/A"}
                </RadioGroup.Label> */}
                    <RadioGroup.Description
                      as="span"
                      className="mt-4 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">VIN: </span>
                      {props.item.win_num ? props.item.win_num : ""}
                      {/* {props.item.win_num ? props.item.win_num : "VIN no : N/A"} */}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">SignOff Date: </span>{" "}
                      {props.item.signoff_date
                        ? dateHandler(props.item.signoff_date)
                        : ""}
                      {/* {props.item.carstatus
                    ? props.item.carstatus
                    : "Car Status: N/A"} */}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">YOM: </span>{" "}
                      {props.item.yom ? props.item.yom : ""}
                      {/* {props.item.carstatus
                    ? props.item.carstatus
                    : "Car Status: N/A"} */}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    props.carId !== props.item.CarStockID ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    props.carId === props.item.CarStockID
                      ? "border-indigo-500"
                      : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            </RadioGroup.Option>
          </RadioGroup>
        );
      default:

        return (
          <RadioGroup>
            <RadioGroup.Option
              onClick={props.onClick}
              className={() =>
                classNames(
                  `${props.carId === props.item.CarStockID &&
                  "border-indigo-500 ring-1 ring-indigo-500"
                  } relative border-2 flex cursor-pointer  rounded-lg  bg-white p-4 shadow-sm focus:outline-none h-56`
                )
              }
            >
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <Button
                      buttonText={`${props.item.carstatus}`}
                      type={carStatusColorChange(props.item.carstatus)}
                    />
                    {props.item.carstatus === "InTransit" ? (
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 block text-sm font-semibold text-gray-900 "
                      >
                        <span className="font-bold">ETA: </span>
                        {props.item.etaDate
                          ? dateHandler(props.item.etaDate)
                          : ""}
                      </RadioGroup.Description>
                    ) : (
                      ""
                    )}

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900 "
                    >
                      {props.item.availabilitystatus
                        ? props.item.availabilitystatus
                        : ""}
                    </RadioGroup.Description>
                    {/* <div className="font-bold text-sm">3-dec-2022</div>

              <div className="font-bold  text-sm">Free Stock</div> */}

                    {/* <RadioGroup.Label
                as="span"
                className="mt-1 block text-sm font-semibold text-gray-900"
              >
                {props.item.signoff_date
                  ? dateHandler(props.item.signoff_date)
                  : "Sign Off Date : N/A"}
              </RadioGroup.Label> */}
                    <RadioGroup.Description
                      as="span"
                      className="mt-4 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">VIN: </span>
                      {props.item.win_num ? props.item.win_num : ""}
                      {/* {props.item.win_num ? props.item.win_num : "VIN no : N/A"} */}
                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">SignOff Date: </span>{" "}
                      {props.item.signoff_date
                        ? dateHandler(props.item.signoff_date)
                        : ""}
                      {/* {props.item.carstatus
                  ? props.item.carstatus
                  : "Car Status: N/A"} */}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">Aging : </span>{" "}
                      {props.item.signoff_date
                        ? getAgingDate(props.item.signoff_date?.slice(0, 10))
                        : ""}

                    </RadioGroup.Description>

                    <RadioGroup.Description
                      as="span"
                      className="mt-1 block text-sm font-semibold text-gray-900"
                    >
                      <span className="font-bold">YOM: </span>{" "}
                      {props.item.yom ? props.item.yom : ""}
                      {/* {props.item.carstatus
                  ? props.item.carstatus
                  : "Car Status: N/A"} */}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    props.carId !== props.item.CarStockID ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    props.carId === props.item.CarStockID
                      ? "border-indigo-500"
                      : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            </RadioGroup.Option>
          </RadioGroup>
        );
    }
  };
  // console.log(props.item, "item");
  return cardSwitchHandler(props.cardType);
};
export default PreferenceCard;
