import { useState } from "react"
import { useNavigate } from "react-router-dom"

const tabs = [
    { name: 'All', href: '#', current: false },
    { name: 'New', href: '#', current: false },
    { name: 'Viewed', href: '#', current: true },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
 const  CBTTabs=({tabType,setTabType,setEndDate,setStartDate})=> {
  const navigate=useNavigate()
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            onChange={(e)=>{setTabType(e.target.value);navigate("/cbt-allocation-awaited")}}
            className="block w-full rounded-md p-2 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.name}
             onClick={()=>{setTabType(tab.name);navigate("/cbt-allocation-awaited")}}
                  className={classNames(
                    tabType===tab.name
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'w-1/3 border-b-2 py-4 cursor-pointer px-1 text-center text-sm font-medium'
                  )}
                  aria-current={tabType===tab.name ? 'page' : undefined}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
  }
  

export default CBTTabs