import React, { useState, useRef } from "react";
import classes from "../module.css/ManageForm.module.css";
import { AiFillPlusCircle } from "react-icons/ai";
import Input from "../tailwindUI/Input";
import Badge from "../tailwindUI/Badge";
import { useEffect } from "react";

function ManageForm(props) {
  const modelImage = useRef(null);
  const resetBtn = useRef(null);

  const [modelName, setModelName] = useState("");
  const [variantName, setVariantName] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [colorName, setColorName] = useState("");
  const [variantGroup, setVariantGroup] = useState([]);
  const [colorGroup, setColorGroup] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [change, setChange] = useState(1);

  useEffect(() => {
    if (props.updateData !== "") {
      setModelName(props.updateData.model);
      setFuelType(props.updateData.fuel_type);
      setImgLink(props.updateData.carmodel_image);
      let updateVariantGroup = props.updateData.variant.split(",");
      setVariantGroup(updateVariantGroup);
      let updateColorGroup = props.updateData.colour.split(",");
      setColorGroup(updateColorGroup);
      setChange(1 + 1);
    } else {
      setModelName("");
      setFuelType("");
      setImgLink([]);
      setVariantGroup([]);
      setColorGroup([]);
    }
  }, [props.updateData]);

  useEffect(() => {
    if (props.updateData !== "") {
      props.formValidation(
        modelName,
        colorGroup,
        variantGroup,
        fuelType,
        imgLink
      );
    }
  }, [change]);

  const imageHandler = () => {
    document.getElementById("modelImg").click();
  };

  // Adding Variants
  const addVariant = () => {
    let addedVariant = variantGroup.filter((item) => {
      return variantName === item;
    });
    if (addedVariant.length < 1) {
      if (variantName !== "") {
        setVariantGroup([...variantGroup, variantName]);
      }
    }
    setVariantName("");
  };

  // Remove Variants
  const removeVariant = (soloVariant) => {
    let newVariant = variantGroup.filter((item) => {
      return item !== soloVariant;
    });
    setVariantGroup(newVariant);
  };

  // Adding Colors
  const addColor = () => {
    let addedColor = colorGroup.filter((item) => {
      return colorName === item;
    });
    if (addedColor.length < 1) {
      if (colorName !== "") {
        setColorGroup([...colorGroup, colorName]);
      }
    }
    setColorName("");
  };

  // Remove Color
  const removeColor = (soloColor) => {
    let newColor = colorGroup.filter((item) => {
      return item !== soloColor;
    });
    setColorGroup(newColor);
  };

  const callAction = () => {
    if (props.action === 0) {
      props.addModel(modelName, colorGroup, variantGroup, fuelType, imgLink);
      setModelName("");
      setFuelType("");
      setImgLink([]);
      setVariantGroup([]);
      setColorGroup([]);
      document.getElementById("modelImg").value = [];
    }

    if (props.action === 1) {
      if (typeof imgLink === "string") {
        props.updateModel(
          modelName,
          colorGroup,
          variantGroup,
          fuelType,
          "",
          props.updateData.CardbID
        );
      } else {
        props.updateModel(
          modelName,
          colorGroup,
          variantGroup,
          fuelType,
          imgLink,
          props.updateData.CardbID
        );
      }
    }
  };

  const resetForm = () => {
    props.setAction(0);
    setModelName("");
    setFuelType("");
    setImgLink([]);
    setVariantGroup([]);
    setColorGroup([]);
    document.getElementById("modelImg").value = [];
  };

  const notificationMethods = [
    { id: "petrol", title: "Petrol" },
    { id: "diesel", title: "Diesel" },
    { id: "cng", title: "CNG" },
    { id: "electric", title: "Electric" },
  ];

  return (
    <>
      <div className="my-2">
        <h1 className={classes.formHeading}> {props.formHeading} </h1>
      </div>
      <div className={classes.mainForm}>
        <form>
          <div className={classes.strips}>
            <label htmlFor="modelName">Model Name</label>
            <div className={classes.ibModel}>
              <input
                type="text"
                id="modelName"
                className={classes.simpleIb}
                onInput={() => (props.error.modelName = "")}
                value={modelName}
                onChange={(e) => {
                  setModelName(e.target.value);
                }}
                error={props.error.modelName}
              />
              <span className="error">{props.error.modelName}</span>
            </div>
          </div>

          <div className={classes.strips}>
            <label htmlFor="modelVariant">Variant</label>
            <div className={classes.ibVariant}>
              <div className={classes.varWarn}>
                <input
                  type="text"
                  id="modelVariant"
                  onInput={() => (props.error.variantName = "")}
                  className={classes.simpleIb}
                  value={variantName}
                  onChange={(e) => {
                    setVariantName(e.target.value);
                  }}
                  error={props.error.variantName}
                />
                <span className="error">{props.error.variantName}</span>
              </div>
              <span className={classes.addBtn}>
                <AiFillPlusCircle onClick={addVariant} />
              </span>
            </div>
          </div>

          <div className={classes.variantContainer}>
            <h2 className="font-bold text-lg"> Variant </h2>
            <div className={classes.variantInner} id="variantBox">
              {variantGroup.map((item, index) => (
                <Badge
                  text={item}
                  colorClass={"bg-indigo-100"}
                  textClass={"text-indigo-800 font-bold mr-2 mb-2"}
                  onClick={() => {
                    removeVariant(item);
                  }}
                />
              ))}
            </div>
          </div>

          <div className={classes.strips}>
            <label htmlFor="modelColor">Color</label>
            <div className={classes.ibVariant}>
              <div className={classes.varWarn}>
                <input
                  type="text"
                  onInput={() => (props.error.colorName = "")}
                  id="modelColor"
                  className={classes.simpleIb}
                  value={colorName}
                  onChange={(e) => {
                    setColorName(e.target.value);
                  }}
                  error={props.error.colorName}
                />
                <span className="error">{props.error.colorName}</span>
              </div>
              <span className={classes.addBtn}>
                <AiFillPlusCircle onClick={addColor} />
              </span>
            </div>
          </div>

          <div className={classes.colorContainer}>
            <h2 className="font-bold text-lg"> Color </h2>
            <div className={classes.colorInner}>
              {colorGroup.map((item, index) => (
                <Badge
                  text={item}
                  colorClass={"bg-green-100"}
                  textClass={"text-indigo-800 mr-2 mb-2"}
                  onClick={() => {
                    removeColor(item);
                  }}
                />
              ))}
            </div>
          </div>

          <div className={classes.strips}>
            <div className={classes.ibArea}>
              <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-10 flex-col">
                <Input
                  type={"radio group"}
                  label="Fuel Type"
                  onChange={() => (props.error.fuelType = "")}
                  value={fuelType}
                  setState={setFuelType}
                  data={notificationMethods}
                  error={props.error.fuelType}
                />
              </div>
              <span className="error">{props.error.fuelType}</span>
            </div>
          </div>

          <div className={classes.imgStrip}>
            <div className={classes.imgstt}>
              <label htmlFor="modelImg">Upload Car Image</label>
              <span className={classes.addBtn}>
                <AiFillPlusCircle onClick={imageHandler} />
              </span>
            </div>
            <div className={classes.ibImgArea}>
              <span className="error">{props.error.imgLink}</span>

              {imgLink?.name ? (
                <p className="mt-2 text-sm">
                  <span className="text-Indigo-100">{imgLink?.name}</span>
                </p>
              ) : (
                <></>
              )}

              <a
                href={`${typeof imgLink === "string" ? imgLink : ""}`}
                target="_blank"
                className="text-Indigo-100 mt-2"
              >
                {" "}
                {`${typeof imgLink === "string" ? imgLink : ""}`}{" "}
              </a>
              <input
                type="file"
                id="modelImg"
                // onInput={() => ()}
                className={classes.imgIb}
                accept="image/*"
                onChange={(e) => {
                  setImgLink(e.target.files[0]);
                  props.error.imgLink = "";
                }}
                error={props.error.imgLink}
              />
            </div>
          </div>

          <div className={classes.strips}>
            <div className={classes.btnArea}>
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2"
                onClick={() => {
                  resetForm();
                }}
              >
                Cancel
              </button>

              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-700 px-6 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-800 focus:ring-offset-1 ml-4"
                onClick={() => {
                  props.formValidation(
                    modelName,
                    colorGroup,
                    variantGroup,
                    fuelType,
                    imgLink
                  )
                    ? callAction()
                    : console.log("Request error");
                }}
              >
                {props.actionName}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default ManageForm;
