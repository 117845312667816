import React from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { GoLocation } from "react-icons/go";

const Heading = (props) => {
  const navigate = useNavigate();

  const userRole = localStorage.getItem("UserRole");

  const userRolehandler = () => {
    if (userRole === "ShowroomManager") {
      return "SRM";
    } else if (userRole === "EDP") {
      return "EDP";
    } else if (userRole === "StockManager") {
      return "Stock Manager";

    } else if (userRole === "GeneralManager") {
      return "General Manager";
    }
    else if (userRole === "ASM") {
      return "ASM";
    }
    else if (userRole === "Admin") {
      return "Super Admin";
    }
  };

  const switchHeading = (headingType) => {
    switch (headingType) {
      case "Page Headings":
        return (
          <div className="bg-white px-4  lg:px-28  py-2 ">
            <div>
              <nav className="sm:hidden bg-white " aria-label="Back">
                <div
                  onClick={() => navigate(-1)}
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </div>
              </nav>
              <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-4">
                  {props.BreadCrumb ? (
                    <>
                      {" "}
                      <li>
                        <div className="flex">
                          <a
                            href={props.BreadCrumHomeLink}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <HomeIcon
                              className="h-5 w-5 flex-shrink-0"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Home</span>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />

                          <a
                            href={
                              props.BreadCrumb?.pagePath
                                ? props.BreadCrumb.pagePath
                                : "#"
                            }
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          >
                            {props.BreadCrumb?.pagename
                              ? props.BreadCrumb.pagename
                              : ""}
                          </a>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {props.BreadCrumb?.pagename1 ? (
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <a
                          href={
                            props.BreadCrumb?.pagePath1
                              ? props.BreadCrumb.pagePath1
                              : "#"
                          }
                          aria-current="page"
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          {props.BreadCrumb?.pagename1
                            ? props.BreadCrumb.pagename1
                            : "Back End Developer"}
                        </a>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                  {props.BreadCrumb?.pagename2 ? (
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <a
                          href={
                            props.BreadCrumb?.pagePath2
                              ? props.BreadCrumb.pagePath2
                              : "#"
                          }
                          aria-current="page"
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          {props.BreadCrumb?.pagename2
                            ? props.BreadCrumb.pagename2
                            : "Back End Developer"}
                        </a>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </ol>
              </nav>
            </div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
                  {userRolehandler()} {props.headingText}
                </h2>
                {props.subheading ? (
                  <h3 className="text-lg  leading-7 flex items-center text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
                    {props.subheading}
                  </h3>
                ) : (
                  <></>
                )}
                {props.subheadingText ? (
                  <h3 className="text-lg  leading-7 flex items-center text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
                    <GoLocation className="text-sm mr-1" />
                    {props.subheadingText}
                  </h3>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-4">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Publish
                  </button>
                </div> */}
            </div>
          </div>
        );


      case "Page Headings small":
        return (
          <div className="bg-white px-4  lg:px-28  py-2 mb-2">
            <div>
              <nav className="sm:hidden bg-white " aria-label="Back">
                <div
                  onClick={() => navigate(-1)}
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </div>
              </nav>
              <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-4">
                  {props.BreadCrumb ? (
                    <>
                      {" "}
                      <li>
                        <div className="flex">
                          <a
                            href={props.BreadCrumHomeLink}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <HomeIcon
                              className="h-5 w-5 flex-shrink-0"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Home</span>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />

                          <a
                            href={
                              props.BreadCrumb?.pagePath
                                ? props.BreadCrumb.pagePath
                                : "#"
                            }
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          >
                            {props.BreadCrumb?.pagename
                              ? props.BreadCrumb.pagename
                              : ""}
                          </a>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {props.BreadCrumb?.pagename1 ? (
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <a
                          href={
                            props.BreadCrumb?.pagePath1
                              ? props.BreadCrumb.pagePath1
                              : "#"
                          }
                          aria-current="page"
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          {props.BreadCrumb?.pagename1
                            ? props.BreadCrumb.pagename1
                            : "Back End Developer"}
                        </a>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                  {props.BreadCrumb?.pagename2 ? (
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <a
                          href={
                            props.BreadCrumb?.pagePath2
                              ? props.BreadCrumb.pagePath2
                              : "#"
                          }
                          aria-current="page"
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          {props.BreadCrumb?.pagename2
                            ? props.BreadCrumb.pagename2
                            : "Back End Developer"}
                        </a>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </ol>
              </nav>
            </div>
            <div className="mt-1 md:flex md:items-center md:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-[22px] sm:tracking-tight">
                  {userRolehandler()} {props.headingText}
                </h2>
                <h3 className="text-lg  leading-7 flex items-center text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
                  <GoLocation className="text-sm mr-1" />
                  {props.subheadingText}
                </h3>
              </div>
            </div>
          </div>
        );

      case "Section Headings":
        return (
          <div className=" border-b border-gray-200 pb-5 bg-white px-4  py-4 mb-4">
            <h3 className="text-2xl  capitalize font-bold leading-6 text-gray-900">
              {userRolehandler()} {props.headingText}
            </h3>
            {props.subheadingText ? (
              <h3 className="text-lg  leading-7 flex items-center text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
                <GoLocation className="text-sm mr-1" />
                {props.subheadingText}
              </h3>
            ) : (
              <></>
            )}
          </div>
        );
      case "sub heading":
        return (
          <div className=" border-b border-gray-200 pb-5 bg-white  py-4">
            <h3 className="text-xl   font-bold leading-4 text-gray-900">
              {props.headingText}
            </h3>

          </div>
        );
      case "sub heading small":
        return (
          <div className=" border-b border-gray-200 pb-3 bg-white  py-3">
            <h3 className="text-lg   font-bold leading-4 text-gray-900">
              {props.headingText}

            </h3>

          </div>
        );
    }
  };
  return switchHeading(props.headingType);
};

export default Heading;
