import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../Components/DataNotFound";

const items = [
  {
    name: "Graph API",
    initials: "GA",
    href: "#",
    members: 16,
    bgColor: "bg-pink-600",
  },
  {
    name: "Component Design",
    initials: "CD",
    href: "#",
    members: 12,
    bgColor: "bg-purple-600",
  },
  {
    name: "Templates",
    initials: "T",
    href: "#",
    members: 16,
    bgColor: "bg-yellow-500",
  },
  {
    name: "React Components",
    initials: "RC",
    href: "#",
    members: 8,
    bgColor: "bg-green-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const color = (item) => {
  if (item.AllocationCount < 1 && item.colour < 1) {
    return "bg-red-500";
  }
  if (item.colour > 0 ) {
    return "bg-green-500";
  }
  if ((item.colour < 1 )&&item.AllocationCount>0) {
    return "bg-blue-600";
  }
};
// const Availability = (AllocationCount,colour) => {
//   if (AllocationCount < 1 && colour< 1) {
//     return "Unavailable";
//   }
//   if (colour>AllocationCount ) {
//     return "Available";
//   }
//   if ((colour < AllocationCount)||colour===AllocationCount) {
//     return "Allocated";
//   }
// };
const Availability = (colour,AllocationCount) => {
  if (colour>0 ) {
    return "Available";
  }
  if (colour< 1) {
    return "Unavailable";
  }
};
const Allocation = (AllocationCount,colour) => {
  if (AllocationCount< 1) {
    return "";
  }
  if (colour<1&&AllocationCount>0 ) {
    return "Allocated";
  }
};
// const Allocation = (item) => {
//   if (item.AllocationCount < 1 && item.colour < 1) {
//     return " ";
//   }
//   if (item.colour > 0&&item.colour!==item.AllocationCount ) {
//     return "bg-green-500";
//   }
//   if ((item.colour < 1 && item.AllocationCount > 0)||item.colour===item.AllocationCount) {
//     return "bg-blue-600";
//   }
// };
const GridListCards = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <h2 className="text-sm font-medium text-gray-500">Pinned items</h2> */}
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        {props.stockList?.length ? (
          props.stockList.map((item) => (
            <li
              key={item.name}
              className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
              onClick={() =>
                navigate(
                  props.url
                    ? `${props.url}/${props.modelName}/${item.variant}`
                    : `/sm-real-time-stock-three/${props.modelName}/${item.variant}`
                )
              }
            >
              {console.log(`${props.url}/${props.modelName}/${item.variant}`)}{" "}
              <div
                className={classNames(
                  `${color(item)}`,
                  "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                )}
              >
                {item.initials}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <a
                    href={item.href}
                    className="font-medium text-gray-900 hover:text-gray-600"
                  >
                    {item.variant}
                  </a>
                  <p className="text-gray-500">
                 { Availability(item.colour)} {(Availability(item.colour)&& Allocation(item.AllocationCount,item.colour))? '|' :""} {Allocation(item.AllocationCount,item.colour)}
                    {/* {item.colour} Color(s) in stock */}
                  </p>
                  <p className="text-gray-500">
                  {/* {Allocation(item.AllocationCount,item.colour)} */}
                    {/* {item.AllocationCount} Car(s) allocated */}
                  </p>
                </div>
              </div>
            </li>
          ))
        ) : (
          <DataNotFound text={"Variant not found"} />
        )}
      </ul>
    </div>
  );
};

export default GridListCards;
