import React, { useState, useEffect } from "react";
import Heading from "../../tailwindUI/Heading";
import Table from "../../tailwindUI/Table";
import classes from "../../module.css/RealTimeStockOne.module.css";
import { useNavigate, useParams } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import Navbar from "../../tailwindUI/Navbar";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Button from "../../tailwindUI/Button";
import Modal from "../../tailwindUI/Modal";
const EDPRealTimeStockThree = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [allcatedColor, setAllCatedColor] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => realTimeStockOneApi(), []);

  const { modelName, variant } = useParams();

  const realTimeStockOneApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      Model: modelName.replace(/-/g, '/'),
      Variant: variant,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/common/RTSInstockcarDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else if (result.status === "Success" && result.status_code === 200) {
          if (result.data === "") {
            setData([]);
          } else {
            setData(result.data);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };
  const BreadCrumb = {
    pagename: "Real Time Stock",
    pagePath: "/edp-real-time-stock-one",
    pagename1: "Variant List",
    pagePath1: "/edp-real-time-stock-two/" + modelName,
    pagename2: "Color List",
    pagePath2: `/edp-real-time-stock-three/${modelName}/${variant}`,
  };

  const tableHeading = [
    {
      id: 1,
      name: "Color",
    },
    {
      id: 2,
      name: "YOM",
    },
    {
      id: 3,
      name: "Availability",
    },
    {
      id: 4,
      name: "Status",
    },
  ];
  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Navbar navbarType={"blue navbar"} />

      <Heading
        headingType={"Page Headings"}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingText={`Real Time Stock : ${modelName} : ${variant}`}
        BreadCrumb={BreadCrumb}
      />
      <Modal setOpen={setOpen} open={open} type="modal theme">
        <div className="p-8 px-10 pb-4">
          <div className="font-semibold">
            Model :{" "}
            <span className="text-gray-500 font-normal">{modelName}</span>
          </div>
          <div className="font-semibold">
            Variant :{" "}
            <span className="text-gray-500 font-normal">{variant}</span>
          </div>
          <div className="font-semibold">
            Color :{" "}
            <span className="text-gray-500 font-normal">{allcatedColor}</span>
          </div>

          <div className="mt-4 flex justify-end">
            <Button
              buttonText={"Cancel"}
              onClick={() => setOpen(false)}
              type={"redFilled"}
            />
          </div>
        </div>
      </Modal>
      <div className={classes.mainContainer}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {loading ? (
          <div className={classes.loader}>
            <img src="/assets/loadingGif.gif" alt="loading" />
          </div>
        ) : (
          <div className={classes.innerContainer}>
            <div className="bg-white ">
              {data.length === 0 ? (
                <DataNotFound />
              ) : (
                <Table
                  type="vertical lines"
                  tabledataType={"colorListTable"}
                  TableData={data}
                  setAllCatedColor={setAllCatedColor}
                  setOpen={setOpen}
                  buttonText="Add "
                  subHeading="subHeading"
                  tableHeading={tableHeading}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EDPRealTimeStockThree;
