import React from "react";
import Modal from "../tailwindUI/Modal";
import classes from "../module.css/FollowUpRemarks.module.css";
import Button from "../tailwindUI/Button";
import { BsDot } from "react-icons/bs";

const FollowUpRemarks = (props) => {

  const pathname = window.location.pathname
  return (
    <Modal setOpen={props.setOpen} open={props.open} type="FollowUpAndRemarks">
      <div>
        <div className={classes.nameContainer}>
          <div className="truncate justify-center overflow-scroll flex text-sm font-medium text-indigo-600">
            {props.bookingData.customer_name} {props.bookingData.LastName}
            <span className={classes.middlePoint}>
              <BsDot size={20} color="#8882ed" />
            </span>
            {props.bookingData.customer_mobile}
            <span className={classes.middlePoint}>
              <BsDot size={20} color="#8882ed" />
            </span>
            {props.typeAllocation === "Auto Allocation Cancelled" ? (
              <span className="truncate">

                1.{props.bookingData.model_name}
                <span className="text-white"> | </span>
                {props.bookingData.variant}
                <span className="text-white"> | </span>
                {props.bookingData.colour}
              </span>
            ) : (
              <span className="truncate">

                1.{props.bookingData.car_model}
                <span className="text-white"> | </span>
                {props.bookingData.car_variant}
                <span className="text-white"> | </span>
                {props.bookingData.car_colour}
              </span>
            )}
          </div>
        </div>
        <div className={classes.mainHeading}>Follow up & Remarks</div>
        <div className={classes.subHeading}>Updated follow up and remarks</div>
        <div className={classes.innerContainer}>
          <div className={classes.remarkContainer}>
            <div className={classes.remark}>Remarks</div>
            <div className={classes.remarkDescription}>
              {props.bookingData.Remark}
            </div>
          </div>
          <div className={classes.remarkContainer}>
            <div className={classes.remark}>AM Remark</div>
            <div className={classes.remarkDescription}>
              {props.bookingData.StockManagerRemark}
            </div>
          </div>

          <div className={classes.remarkContainer}>
            <div className={classes.remark}>SRM Remark</div>
            <div className={classes.remarkDescription}>
              {props.bookingData.SRMRemark}
            </div>
          </div>
          
          <div className={classes.remarkContainer}>
          <div className={classes.remark}>CBT Remark</div>
          <div className={classes.remarkDescription}>
            {props.bookingData.CBTRemark}
          </div>
        </div>


          <div className={classes.remarkContainer}>
            <div className={classes.remark}>Follow up 1</div>
            <div className={classes.remarkDescription}>
              {props.bookingData.followup1}
            </div>
          </div>
          <div className={classes.remarkContainer}>
            <div className={classes.remark}>Follow up 2</div>
            <div className={classes.remarkDescription}>
              {props.bookingData.followup2}
            </div>
          </div>
          <div className={classes.remarkContainer}>
            <div className={classes.remark}>Follow up 3</div>
            <div className={classes.remarkDescription}>
              {props.bookingData.followup3}
            </div>
          </div>
          <div className={classes.Button}>
            <Button
              buttonText={"Close"}
              onClick={() => props.setOpen("")}
              type={"whiteButton"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FollowUpRemarks;
