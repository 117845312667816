import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tab = (props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const tabSetting = (index) => {
    props.setTabNumber(index);
    setTabIndex(index);
  };

  const dataType = (type) => {
    switch (type) {
      case "All":
        return props.DataCount.all_bookings_count
          ? props.DataCount.all_bookings_count
          : 0;
      case "Cars Allocated":
        return props.DataCount.alocated_bookings_count
          ? props.DataCount.alocated_bookings_count
          : 0;
      case "Not Allocated":
        return props.DataCount.unalocated_bookings_count
          ? props.DataCount.unalocated_bookings_count
          : 0;
    }
  };
  const tabType = (type) => {
    switch (type) {
      case "threeTabs":
        return (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>

              <select
                id="tabs"
                name="tabs"
                className="block  w-full p-2 border border-slate-200 outline-0    focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={"All"}
                onChange={(e) => props.setTabType(e.target.value)}
              >
                {props.tabs?.map((tab) => (
                  <option key={tab.name}>{tab.name} </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {props.tabs?.map((tab, index) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      onClick={() => {
                        setTabIndex(index);
                        props.setTabType(tab.name);
                      }}
                      className={classNames(
                        tabIndex === index
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        `w-1/3 py-4 px-1 cursor-pointer text-center border-b-2 font-medium text-sm`
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}{" "}
                      <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                        {" "}
                        {dataType(tab.name)}{" "}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        );

      case "manageDel":
        return (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <div className="text-end italic px-4 py-2">{`${props.count.current} of ${props.count.total}`}</div>

              <select
                id="tabs"
                name="tabs"
                className="block  w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={props.tabs?.find((tab) => tab.current).name}

                onChange={(e) => {
                  tabSetting(e.target.value);
                }}
              >
                {props.tabs?.map((tab, tabIndex) => (
                  <option key={tab.name} value={tabIndex} >

                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <div className="text-end italic px-4">{`${props.count.current} of ${props.count.total}`}</div>
                <nav className="-mb-px flex" aria-label="Tabs">
                  {props.tabs?.map((tab, index) => (
                    <div
                      key={tab.name}
                      onClick={() => tabSetting(index)}
                      className={classNames(
                        tabIndex === index
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        `w-1/4 py-4 px-1 text-center cursor-pointer  border-b-2 font-medium text-sm`
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        );
      case "Reallocation request":
        return (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>

              <select
                id="tabs"
                name="tabs"
                className="block  w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={props.tabs?.find((tab) => tab.current).name}
                onChange={(e) => props.setTabType(e.target.value)}
              >
                {props.tabs?.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {props.tabs?.map((tab, index) => (
                    <a
                      key={tab.name}
                      onClick={() => {
                        setTabIndex(index);
                        props.setTabType(tab.name);
                      }}
                      href={tab.href}
                      className={classNames(
                        tabIndex === index
                          ? "border-indigo-500 text-indigo-600 "
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 ",
                        `w-1/2 py-4 px-1 text-center cursor-pointer  border-b-2 font-medium text-sm`
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}{" "}
                      <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                        {" "}
                        {tab?.count}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>

              <select
                id="tabs"
                name="tabs"
                className="block  w-full p-2 border  outline-0  border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={"All"}
                onChange={(e) => props.setTabType(e.target.value)}
              >
                {props.tabs?.map((tab) => (
                  <option key={tab.name}>{tab.name} </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {props.tabs?.map((tab, index) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      onClick={() => {
                        setTabIndex(index);
                        props.setTabType(tab.name);
                      }}
                      className={classNames(
                        tabIndex === index
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        `w-1/4 py-4 px-1 cursor-pointer text-center border-b-2 font-medium text-sm`
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}{" "}
                      <span className="bg-gray-200 px-3 ml-2 rounded-3xl">
                        {" "}
                        {tab?.count}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        );
        break;
    }
  };
  return <div>{tabType(props.type)}</div>;
};

export default Tab;
