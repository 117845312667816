import React from "react";
import { useState } from "react";

const AllocationStatusGridInput = (props) => {
  const [state, setState] = useState(props.value);

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState(e.target.value);
    }
    props.getmodellocationquota(props.model, props.location, e.target.value);
  };

  const callApi = (e) => {
    if (e.key === "Enter") {
      props.QuotaData();
    }
  };
  return (
    <>
      <td className="whitespace-nowrap bg-yellow-300 text-center border border-black  text-gridsmall font-normal text-gray-900 ">
        <input
          type="text"
          value={state}
          onKeyDown={(e) => callApi(e)}
          onChange={(e) => {
            handleChange(e);
          }}
          className="text-center outline-none bg-yellow-300"
        />
      </td>
    </>
  );
};

export default AllocationStatusGridInput;
