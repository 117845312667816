import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import Table from "../../tailwindUI/Table";
import classes from "../../module.css/CarInfoPending.module.css";
import Input from "../../tailwindUI/Input";
import Modal from "../../tailwindUI/Modal";
import Button from "../../tailwindUI/Button";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Pagination from "../../tailwindUI/Pagination";
import DataNotFound from "../../Components/DataNotFound";
import TableHeader from "../../Components/TableHeader";

const CarInfoPending = () => {
  let navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [checked, setChecked] = useState([]);
  const [getstockid, setGetstockid] = useState("");
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [selected, setSelected] = useState("");
  const [variant, setVariant] = useState([]);
  const [color, setColor] = useState([]);
  const [fuelType, setFuelType] = useState([]);
  const [yom, setYom] = useState("");
  const [stockStatus, setStockStatus] = useState("");
  const [signOffDate, setSignOffDate] = useState("");
  const [hmilDate, setHmilDate] = useState("");
  const [eta, setEta] = useState("");
  const [vin, setVin] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [freeStockBBND, setFreeStockBBND] = useState("");
  const [transitMode, setTransitMode] = useState("Truck");
  const [driverName, setDriverName] = useState("");
  const [driverNo, setDriverNo] = useState("");
  const [truckRailNo, setTruckRailNo] = useState("");
  const [lastLocation, setLastLocation] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [tableCount, setTableCount] = useState("");
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const pathname = window.location.pathname;

  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);
  const maxpages = Math.ceil(tableCount / 20);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    carlistApi();
  }, [searchTerm === "", page]);

  const carlistApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      page: page,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPCarsInfoPendingList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "fail" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          if (searchTerm && result.data.length < 2) {
            navigate(pathname);
          }
          setTableData(result.data);
          setTableCount(result.total_count);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data not found "
        ) {
          if (searchTerm) {
            navigate(pathname);
            setTableCount(0);
          }
          setTableData([]);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const stockprefilledwithid = async (getstockid) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      CarStockID: getstockid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setSelected(result.data.model_name);
          setVariant(result.data.variant);
          setColor(result.data.colour);
          setFuelType(result.data.fueltype);
          setYom(result.data.yom);
          setStockStatus(result.data.carstatus);
          setSignOffDate(result.data.signoff_date);
          setHmilDate(result.data.hmil_date);
          setEta(result.data.etaDate);
          setVin(result.data.win_num);
          setOrderNo(result.data.OrderId);
          setFreeStockBBND(result.data.availabilitystatus);
          setTransitMode(
            result.data.TransitMode ? result.data.TransitMode : "Truck"
          );

          setDriverName(result.data.driver_name);
          setDriverNo(result.data.driver_num);
          setTruckRailNo(result.data.truck_num);
          setLastLocation(result.data.last_location);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const updateStockAPI = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      CarStockID: getstockid,
      OrderID: orderNo,
      Model: selected,
      FuelType: fuelType,
      Variant: variant,
      Colour: color,
      VINNumber: vin,
      YOM: yom,
      StockStatus: stockStatus,
      SignoffDate: signOffDate,
      HMILInvoiceDate: hmilDate,
      ETADate: eta,
      DriverName: driverName,
      DriverNumber: driverNo,
      TruckRailNumber: truckRailNo,
      TransitMode: transitMode,
      LastLocation: lastLocation,
      FreeStockBBND: freeStockBBND,
      NumberofVehicles: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "fail" &&
          result.data ===
          "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message !== "VINNumber already exist " &&
          result.message !== ",OrderID already exist " &&
          result.message !== "VINNumber,OrderID already exist "
        ) {
          setOpen(false);
          setMessage(result.message);
          setApiFailled(false);
          setError("");
          carlistApi();
          setSelected("");
          setVariant([]);
          setColor([]);
          setFuelType([]);
          setYom("");
          setStockStatus("");
          setSignOffDate("");
          setHmilDate("");
          setEta("");
          setVin("");
          setOrderNo("");
          setFreeStockBBND("");

          setDriverName("");
          setDriverNo("");
          setTruckRailNo("");
          setLastLocation("");
        } else if (
          result.data === "VINNumber already exist" &&
          result.message === "VINNumber already exist "
        ) {
          setError({
            ...error,
            vin: "VIN number already exists",
          });
        } else if (
          result.message === ",OrderID already exist " &&
          result.data === ",OrderID already exist"
        ) {
          setError({
            ...error,
            orderNo: "Order number already exists",
          });
        } else if (
          result.message === "VINNumber,OrderID already exist " &&
          result.data === "VINNumber,OrderID already exist"
        ) {
          setError({
            ...error,
            vin: "VIN number already exists",
            orderNo: "Order number already exists",
          });
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const updateDriverDetails = () => {
    if (transitMode === "Truck" && formValidationOne()) {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserToken: localStorage.getItem("UserToken"),
        TruckNumber: truckRailNo,
        ETADate: eta,
        DriverName: driverName,
        ContactDetails: driverNo,
        LastLocation: lastLocation,
        TransitMode: transitMode,
        CarStockID: checked.toString(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockSaveTransit",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result.status_code === 200 &&
            result.status === "fail" &&
            result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
            result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
          ) {
            LogOut();
          } else if (
            result.status_code === 200 &&
            result.status === "Success"
          ) {
            carlistApi();

            setEta("");

            setDriverName("");
            setDriverNo("");
            setTruckRailNo("");
            setLastLocation("");
            setChecked([]);

            setAdd(false);
            setMessage("Transit updated successfully ");
          } else if (result.status_code === 400) {
            setErrorMessage("Something went wrong please contact support !");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setErrorMessage("Something went wrong please contact support !");
        })
        .finally(() => setLoading(""));
    } else if (transitMode === "Rail" && formValidationtwo()) {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserToken: localStorage.getItem("UserToken"),
        TruckNumber: truckRailNo,
        ETADate: eta,
        DriverName: driverName,
        ContactDetails: driverNo,
        LastLocation: lastLocation,
        TransitMode: transitMode,
        CarStockID: checked.toString(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockSaveTransit",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result.status_code === 200 &&
            result.status === "fail" &&
            result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
            result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
          ) {
            LogOut();
          } else if (
            result.status_code === 200 &&
            result.status === "Success"
          ) {
            carlistApi();

            setEta("");

            setDriverName("");
            setDriverNo("");
            setTruckRailNo("");
            setLastLocation("");
            setChecked([]);

            setAdd(false);
            setMessage("Transit updated successfully ");
          } else if (result.status_code === 400) {
            setErrorMessage("Something went wrong please contact support !");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setErrorMessage("Something went wrong please contact support !");
        })
        .finally(() => setLoading(""));
    }
  };

  const formValidation = () => {
    const ordernoregex = /^[\w\-\s]+$/;
    const vinregex = /^[0-9a-zA-Z]+$/;
    let errors = {};
    let isValid = true;
    if (!signOffDate || signOffDate === "") {
      isValid = false;
      errors.signOffDate = "Please select sign off date";
    }
    if (!hmilDate || hmilDate === "") {
      isValid = false;
      errors.hmilDate = "Please select HMIL date";
    }

    if (!vin || vin === "") {
      isValid = false;
      errors.vin = "Please select VIN number";
    } else if (!vinregex.test(vin)) {
      isValid = false;
      errors.vin = "Please enter correct VIN number";
    }
    if (!orderNo || orderNo === "") {
      isValid = false;
      errors.orderNo = "Please select Order No.";
    } else if (!ordernoregex.test(orderNo)) {
      isValid = false;
      errors.orderNo = "Please enter correct Order No.";
    }
    setError(errors);
    return isValid;
  };

  const formValidationOne = () => {
    // const drivernameregex = /^[a-zA-Z ]*$/;
    // const drivernumber = /^[0-9\b]+$/;
    let errors = {};
    let isValid = true;
    if (!eta || eta === "") {
      isValid = false;
      errors.eta = "Please select ETA";
    }
    // if (!driverName || driverName === "") {
    //   isValid = false;
    //   errors.driverName = "Please select driver name";
    // } else if (!drivernameregex.test(driverName)) {
    //   isValid = false;
    //   errors.driverName = "Please enter correct driver name";
    // }
    // if (!driverNo || driverNo === "") {
    //   isValid = false;
    //   errors.driverNo = "Please select driver number";
    // } else if (!drivernumber.test(driverNo) || driverNo.length < 10) {
    //   isValid = false;
    //   errors.driverNo = "Please enter correct driver number";
    // }
    if (!truckRailNo || truckRailNo === "") {
      isValid = false;
      errors.truckRailNo = "Please select truck  no";
    }

    // if (!lastLocation || lastLocation === "") {
    //   isValid = false;
    //   errors.lastLocation = "Please select last location";
    // } else if (!drivernameregex.test(lastLocation)) {
    //   isValid = false;
    //   errors.lastLocation = "Please enter correct last location";
    // }
    if (!transitMode || transitMode === "") {
      isValid = false;
      errors.transitMode = "Please select transit mode";
    }
    setError(errors);
    return isValid;
  };

  const formValidationtwo = () => {
    // const drivernameregex = /^[a-zA-Z ]*$/;
    // const drivernumber = /^[0-9\b]+$/;
    let errors = {};
    let isValid = true;
    if (!eta || eta === "") {
      isValid = false;
      errors.eta = "Please select ETA";
    }

    if (!truckRailNo || truckRailNo === "") {
      isValid = false;
      errors.truckRailNo = "Please select rail no";
    }

    // if (!lastLocation || lastLocation === "") {
    //   isValid = false;
    //   errors.lastLocation = "Please select last location";
    // } else if (!drivernameregex.test(lastLocation)) {
    //   isValid = false;
    //   errors.lastLocation = "Please enter correct last location";
    // }
    if (!transitMode || transitMode === "") {
      isValid = false;
      errors.transitMode = "Please select transit mode";
    }
    setError(errors);
    return isValid;
  };
  const tablecolumns = [
    {
      name: "Add",
    },
    {
      name: "Order No.",
    },
    {
      name: "Model ",
    },
    {
      name: "Fuel Type",
    },
    {
      name: "Variant",
    },
    {
      name: "Colour",
    },
    {
      name: "YOM",
    },
    {
      name: "Allocation Status"
    },
    {
      name: "Stock Status",
    },
    {
      name: "Truck No.",
    },
    {
      name: "Created Date",
    },
    {
      name: "VIN",
    },
  ];

  const notificationMethods1 = [
    { id: "Truck", title: "Truck" },
    { id: "Rail", title: "Rail" },
  ];

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };
  // console.log("checked", checked);

  const BreadCrumb = {
    pagename: "Car Info Pending",
    pagePath: "/edp-car-info-pending",
    pagename1: "",
    pagePath1: "",
  };

  const BreadCrumHomeLink = "/edp-dashboard";



  return (
    <>
      {/* <div>{checked.toString()}</div> */}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {add && (
        <Modal
          setOpen={setAdd}
          open={add}
          type="car info modal"
          onCancel={() => {
            setAdd(false);
            setLastLocation("");
            setTruckRailNo("");

            setDriverNo("");
            setDriverName("");
            setEta("");
          }}
          onClick={() => updateDriverDetails()}
        >
          {transitMode === "Truck" ? (
            <>
              <div className="m-2">
                <Input
                  type={"radio group"}
                  label={`Transit Mode- (Added Count-${checked.length})`}
                  onChange={() => (error.transitMode = "")}
                  value={transitMode}
                  setState={setTransitMode}
                  data={notificationMethods1}
                  error={error.transitMode}
                />
                <span className="error">{error.transitMode}</span>
              </div>

              <div className="m-2">
                <Input
                  type={"input with label"}
                  inputType="text"
                  label="Truck Number"
                  placeholder="Enter truck number"
                  onInput={() => (error.truckRailNo = "")}
                  value={truckRailNo}
                  error={error.truckRailNo}
                  onChange={(e) => setTruckRailNo(e.target.value)}
                />
                <span className="error">{error.truckRailNo}</span>
              </div>
              <div className="m-2">
                <Input
                  type={"input with label"}
                  label="ETA"
                  inputType="date"
                  onInput={() => (error.eta = "")}
                  value={eta}
                  onChange={(e) => setEta(e.target.value)}
                  error={error.eta}
                  onKeyDown={(e) => e.preventDefault()}

                />
                <span className="error">{error.eta}</span>
              </div>
              <div className="m-2">
                <Input
                  type={"input with label"}
                  inputType="text"
                  label="Driver Name"
                  placeholder="Enter driver name"
                  // onInput={() => (error.driverName = "")}
                  value={driverName}
                  onChange={(e) => setDriverName(e.target.value)}
                // error={error.driverName}
                />
                {/* <span className="error">{error.driverName}</span> */}
              </div>

              <div className="m-2">
                <Input
                  type={"input with label"}
                  inputType="text"
                  label="Driver Number"
                  placeholder="Enter driver number"
                  // maxLength="10"
                  // onInput={() => (error.driverNo = "")}
                  value={driverNo}
                  onChange={(e) => setDriverNo(e.target.value)}
                // error={error.driverNo}
                />
                {/* <span className="error">{error.driverNo}</span> */}
              </div>

              <div className="m-2">
                <Input
                  type={"input with label"}
                  label="Last Location"
                  inputType="text"
                  placeholder="Enter last location"
                  onInput={() => (error.lastLocation = "")}
                  value={lastLocation}
                  onChange={(e) => setLastLocation(e.target.value)}
                // error={error.lastLocation}
                />
                {/* <span className="error">{error.lastLocation}</span> */}
              </div>
            </>
          ) : (
            <>
              <div className="m-2">
                <Input
                  type={"radio group"}
                  label={`Transit Mode- (Added Count-${checked.length})`}
                  onChange={() => (error.transitMode = "")}
                  value={transitMode}
                  setState={setTransitMode}
                  data={notificationMethods1}
                  error={error.transitMode}
                />
                <span className="error">{error.transitMode}</span>
              </div>

              <div className="m-2">
                <Input
                  type={"input with label"}
                  inputType="text"
                  label="Rail Number"
                  placeholder="Enter rail number"
                  onInput={() => (error.truckRailNo = "")}
                  value={truckRailNo}
                  error={error.truckRailNo}
                  onChange={(e) => setTruckRailNo(e.target.value)}
                />
                <span className="error">{error.truckRailNo}</span>
              </div>
              <div className="m-2">
                <Input
                  type={"input with label"}
                  label="ETA"
                  inputType="date"
                  onInput={() => (error.eta = "")}
                  value={eta}
                  onChange={(e) => setEta(e.target.value)}
                  error={error.eta}
                />
                <span className="error">{error.eta}</span>
              </div>

              <div className="m-2">
                <Input
                  type={"input with label"}
                  label="Last Location"
                  inputType="text"
                  placeholder="Enter last location"
                  onInput={() => (error.lastLocation = "")}
                  value={lastLocation}
                  onChange={(e) => setLastLocation(e.target.value)}
                // error={error.lastLocation}
                />
                {/* <span className="error">{error.lastLocation}</span> */}
              </div>
            </>
          )}
        </Modal>
      )}

      {open && (
        <Modal
          setOpen={() => {
            setOpen(false);
            setVin("");
            setOrderNo("");
          }}
          open={open}
          type="car info modal"
          onCancel={() => {
            setOpen(false);
            setVin("");

            setOrderNo("");
          }}
          onClick={() =>
            formValidation() ? updateStockAPI() : console.log("err")
          }
        >
          <div className="m-4">
            <Input
              type={"input with label"}
              onInput={() => (error.vin = "")}
              inputType="text"
              label="VIN Number"
              placeholder="Enter VIN number"
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              error={error.vin}
            />
            <span className="error">{error.vin}</span>
          </div>
          <div className="m-4">
            <Input
              type={"input with label"}
              inputType="text"
              label="Order Number"
              placeholder="Enter Order number"
              onInput={() => (error.orderNo = "")}
              value={orderNo}
              onChange={(e) => setOrderNo(e.target.value)}
              error={error.orderNo}
            />
            <span className="error">{error.orderNo}</span>
          </div>
          <div className="m-4">
            <Input
              type={"input with label"}
              onInput={() => (error.signOffDate = "")}
              label="Sign Off Date"
              inputType="date"
              value={signOffDate}
              onChange={(e) => setSignOffDate(e.target.value)}
              error={error.signOffDate}
              onKeyDown={(e) => e.preventDefault()}

            />
            <span className="error">{error.signOffDate}</span>
          </div>
          <div className="m-4">
            <Input
              onInput={() => (error.hmilDate = "")}
              type={"input with label"}
              label="HMIL Invoice Date"
              inputType="date"
              value={hmilDate}
              onChange={(e) => setHmilDate(e.target.value)}
              error={error.hmilDate}
              onKeyDown={(e) => e.preventDefault()}

            />
            <span className="error">{error.hmilDate}</span>
          </div>
        </Modal>
      )}

      <Navbar navbarType={"blue navbar"} />
      <div className={classes.maincontainer}>
        <div className="mb-4">
          <Heading
            headingType={"Page Headings"}
            headingText={"Car Info Pending"}
            BreadCrumb={BreadCrumb}
            BreadCrumHomeLink={BreadCrumHomeLink}
          />
        </div>
        <div className={classes.form}>
          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            placeholderType={"Enter For Ex:-(Order Id, Model, Variant, Fuel Type, Colour, Status)"}
            totalResult={tableCount}
            pageResult={tableData.length}
            apicall={carlistApi}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />

          {checked.length === 0 ? (
            <></>
          ) : (
            <div className="text-end p-2">
              <Button
                type="redFilled"
                buttonText="Add driver detail"
                onClick={() => setAdd(true)}
              />
            </div>
          )}

          {loading ? (
            <img
              src="../assets/loadingGif.gif"
              alt="loading"
              className={classes.loader}
            />
          ) : (
            <>
              {tableData.length > 0 ? (
                <div className={classes.table}>
                  <Table
                    checked={checked}
                    setChecked={setChecked}
                    type="car info pending"
                    TableData={tableData}
                    tabledataType="car info list"
                    tableHeading={tablecolumns}
                    setOpen={setOpen}
                    setGetstockid={setGetstockid}
                    getstockid={getstockid}
                    stockprefilledwithid={stockprefilledwithid}
                  />
                </div>
              ) : (
                <DataNotFound />
              )}

              {maxpages > 1 && (
                <div className={classes.pagination}>
                  <Pagination maxPages={maxpages} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CarInfoPending;
