import React from "react";
import { useState } from "react";
import LoginInput from "../tailwindUI/LoginInput";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setMobile(e.target.value)
      : console.log("Please enter correct mobile number");
  };

  const mobilenumValidator = () => {
    let isValid = true;
    let errors = {};
    if (mobile.length === "" || !mobile) {
      errors.mobile = "Please enter mobile number";
      isValid = false;
    }
    /*   if (!mobile || mobile === "") {
      errors.mobile = "Please Select your position";
      isValid = false;
    } */
    if (!password || password === "") {
      errors.password = "Please enter your password";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  let navigate = useNavigate();

  const loginApi = () => {
    setBtnLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Mobile: mobile,
      Password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/common/Login",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (
          result.status_code === 200 &&
          result.status === "SUCCESS" &&
          result.message === "data found successfully"
        ) {
          localStorage.setItem("UserName", result.data.UserName);
          localStorage.setItem("FirstName", result.data.FirstName);
          localStorage.setItem("LastName", result.data.LastName);
          localStorage.setItem("UserToken", result.data.UserToken);
          localStorage.setItem("UserRole", result.data.UserRole);
          localStorage.setItem("Location", result.data.Location);
          localStorage.setItem("Email", result.data.Email);
          localStorage.setItem("UserLoggedin", true);
          if (result.data.UserRole === "ShowroomManager") {
            navigate("/sm-dashboard");
            window.location.reload();
          } else if (result.data.UserRole === "StockManager") {
            navigate("/stm-dashboard");
            window.location.reload();
          } else if (result.data.UserRole === "ASM") {
            navigate("/asm-dashboard");
          } else if (result.data.UserRole === "CBT") {
            navigate("/cbt-dashboard");
          }
          else if (result.data.UserRole === "GeneralManager") {
            navigate("/gm-dashboard");
          }
           else {
            navigate("/edp-dashboard");
          }
        } else if (
          result.Reason === "'NoneType' object is not subscriptable" &&
          result.status_code === "400"
        ) {
          setLoginFailed("Invalid Credentials");
        } else if (
          result.status === "FAILED" &&
          result.message === "no user found"
        ) {
          setLoginFailed("Mobile number or password does not match");
        } else {
          setLoginFailed("OOP's Technical Error. Please contact support");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setBtnLoading(false));
  };

  return (
    <div>
     <div className="text-sm font-bold">V1.0.1 </div>
      <LoginInput
        Type="Login"
        placeholder={"Mobile Number"}
        onChangeMobile={(e) => {
          onlyNumericMobile(e);
          error.mobile = "";
        }}
        onChangePassword={(e) => {
          setPassword(e.target.value);
          error.password = "";
        }}
        loginApi={loginApi}
        value={mobile}
        mobilenumValidator={mobilenumValidator}
        error={error}
        nodata={loginFailed}
        loading={btnLoading}
      />
    </div>
  );
};

export default Login;
