import React, { useEffect, createContext, useState } from "react";

const CountContext = createContext({});

const DataCount = (props) => {
  const [count, setCount] = useState({});
  const [allocationCount, setAllocationCount] = useState({});

  const getCountHandler = (detail) => {
    setCount(detail);
  };

  const getAllocationCountHandler = (detail) => {
    setAllocationCount(detail);
  };
  const value = {
    count,
    allocationCount,
    getCountHandler,
    getAllocationCountHandler,
  };

  return (
    <CountContext.Provider value={value}>
      {props.children}
    </CountContext.Provider>
  );
};

export default DataCount;
export { CountContext };
