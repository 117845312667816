import React, { useState } from "react";
import Heading from "../../tailwindUI/Heading";
import StackedList from "../../tailwindUI/StackedList";
import Pagination from "../../tailwindUI/Pagination";
import classes from "../../module.css/AllocatedBookings.module.css";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import SmWrapper from "../../Components/SmWrapper";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Modal from "../../tailwindUI/Modal";
import Table from "../../tailwindUI/Table";
import { GrClose } from 'react-icons/gr';
import TableHeader from "../../Components/TableHeader";

const AllocatedBookings = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openCarStatus, setOpenCarStatus] = useState(false);
  const [inTransitData, setInTransitData] = useState("")
  const [searchTerm, setSearchTerm] = useState("");

  const UserToken = localStorage.getItem("UserToken");
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
  const navigate = useNavigate();
  useEffect(() => {
    BookingList();
  }, [searchTerm === "", page]);
  //   const tabs = [
  //     { name: "All", current: false },
  //     { name: "Allocated Cars", current: false },
  //     { name: "Unallocated Cars", current: true },
  //   ];
  const BreadCrumb = {
    pagename: "Allocated but not Delivered",
    pagePath: "/sm-allocated-bookings",
    pagename1: "",
    pagePath1: "",
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate('/login', { replace: true })
  }
  const BookingDetail = (bookingId) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: bookingId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        }
        if (result.status === "Success" && result.status_code === 200) {
          setBookingData(result.data);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert("Something went wrong please contact support !");
      });
  };
  const BookingList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/BookingAllocatedbutnotDeliveredList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()

        }
        else if (result.status_code == 200 && result.status == "Success") {
          if (searchTerm && result.data.length < 2) {
            navigate(pathname);
          }
          setData(result.data);
          setCount(result.count);
        }
        else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data not found "
        ) {
          if (searchTerm) {
            navigate(pathname);
            setCount(0);
          }
          setData([]);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const maxCount = Math.ceil(count / 20);


  const tablecolumns = [
    {
      name: "Transit Mode",
    },
    {
      name: "Truck/ Rail No.",
    },
    {
      name: "Driver Name",
    },
    {
      name: "Driver No.",
    },
    {
      name: "Last Location",
    },

  ];

  const getInTransitInData = (getdata) => {
    setInTransitData(getdata)
  }
  return (
    <SmWrapper>
      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"Allocated but not Delivered"}
        subheadingText={localStorage.getItem("Location")}

      />
      <div className={classes.mainContainer}>
        {openCarStatus && (
          <Modal
            type="manage transit info"
            setOpen={setOpenCarStatus}
            open={openCarStatus}
            onCancel={() => setOpenCarStatus(false)}
          >
            <div className="flex justify-end mb-2">
              <GrClose onClick={() => setOpenCarStatus(false)} />
            </div>
            <Table
              type="stripped rows"
              TableData={inTransitData}
              tableHeading={tablecolumns}
              tabledataType="SM Allocated Bookings Modal"
            />
          </Modal>
        )}
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {open && (
          <FollowUpRemarks
            bookingData={bookingData}
            setOpen={setOpen}
            open={open}
          />
        )}
        <div className={classes.innerContainer}>



          {/* <div className={classes.wrapper}> */}


          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            placeholderType={"For Ex:-(Order ID, Model, Variant, Fuel Type, Colour, Status, ASM, Executive, Customer Name, Customer Number)"}
            totalResult={count}
            pageResult={data.length}
            apicall={BookingList}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div className=" flex px-4 pt-2 justify-between items-center text-gray-500">
            <div className=" flex   items-center "> <div className=" mr-1 bg-green-600 w-2 h-2 "></div>
              <span className=" text-xs"> Allocated Cars</span></div>
            {/* <div >{data ? data.length : 0} of {count ? count : 0}</div> */}
          </div>
          {/* <Tab setTabType={setTabType} type={"threeTabs"} tabs={tabs} /> */}
          {loading ? (
            <div className={classes.loader}>
              {" "}
              <img src="assets/loadingGif.gif" alt="loading" />
            </div>
          ) : (
            <>
              {" "}
              <div className={classes.list}>
                <div className={classes.stackListContainer}>
                  <div role="list" className={classes.stackListWrapper}>
                    <StackedList
                      data={data}
                      allocated={true}
                      Upadte={true}
                      BookingDetail={BookingDetail}
                      setOpen={setOpen}
                      type="SM Allocated Bookings"
                      getInTransitInData={getInTransitInData}
                      setOpenCarStatus={setOpenCarStatus}
                    />
                  </div>
                </div>
              </div>
              {maxCount > 1 ? <Pagination maxPages={maxCount} /> : <></>}
            </>
          )}

        </div>
      </div>
    </SmWrapper>
  );
};

export default AllocatedBookings;
