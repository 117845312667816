import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Resize,
    Group,
    Sort,
    ColumnMenu,
    Filter,
    Toolbar,
    ExcelExport,
    PdfExport,
} from "@syncfusion/ej2-react-grids";
import { registerLicense } from "@syncfusion/ej2-base";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import DataNotFound from "../../Components/DataNotFound";
import Navbar from "../../tailwindUI/Navbar";
import Dropdown from "../../tailwindUI/Dropdown";

const AdminSRMBookingCancelMonthly = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectLocation, setSelectLocation] = useState({
        outlet: "Station Road",
        number: "9919800464",
    });

    const getUsersData = JSON.parse(localStorage.getItem("userdata"));

    const srmFilter = getUsersData?.filter(
        (srm) =>
            srm.Location === selectLocation.outlet &&
            srm.UserRole === "ShowroomManager" &&
            Number(srm.Mobile) === Number(selectLocation.number)
    );
    const srmToken = srmFilter[0]?.UserToken;

    useEffect(() => {
        SRMBookingReportMonthlyApi();
    }, [selectLocation.outlet]);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/creta", { replace: true });
    };

    const SRMBookingReportMonthlyApi = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: srmToken,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingcancledmonthlyReport",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login" ||
                    result.message === "Please login with a different account"
                ) {
                    LogOut();
                } else if (result.status_code === 200 && result.status === "Success") {
                    setData(result.data);
                } else if (result.status_code === 400) {
                    setErrorMessage("Something went wrong please contact support !");
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            })
            .finally(() => setLoading(false));
    };

    const groupOptions = { showGroupedColumn: true };
    const filterSettings = { type: "CheckBox" };

    registerLicense(
        "Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9"
    );

    const toolbarOptions = ["ExcelExport", "PdfExport", "CsvExport", "Search"];
    let gridInstance;
    let checkboxObj;
    let flag = true;
    function dataBound() {
        if (flag) {
            gridInstance.toolbarModule.toolbar.hideItem(2, true);
            flag = false;
        }
    }

    function toolbarClick(args) {
        switch (args.item.text) {
            case "PDF Export":
                gridInstance.pdfExport();
                break;
            case "Excel Export":
                gridInstance.excelExport();
                break;
            case "CSV Export":
                gridInstance.csvExport();
                break;
        }
    }

    const sortOptions = [
        { name: "Station Road", href: "#", number: 9919800464, current: true },
        { name: "Arjunganj", href: "#", number: 9919800463, current: false },
        { name: "Kanpur Road", href: "#", number: 9919800458, current: false },
        { name: "Telibagh", href: "#", number: 7408221166, current: false },
        { name: "Raebareli", href: "#", number: 6389500086, current: false },
        { name: "Jagdishpur", href: "#", number: 6389500022, current: false },
        { name: "Sultanpur", href: "#", number: 9919800561, current: false },
    ];
    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Navbar navbarType={"admin navbar"} />
            <div className="mb-16">
                <div className={classes.heading}>
                    <Heading
                        headingType={"Section Headings"}
                        BreadCrumHomeLink={"/creta-dashboard"}
                        headingText={"Booking Cancel Monthly"}
                        subheadingText={selectLocation.outlet}

                    />
                    <Dropdown
                        setSelectLocation={setSelectLocation}
                        sortOptions={sortOptions}
                    />
                    {loading ? (
                        <div className={classes.loader}>
                            <img src="assets/loadingGif.gif" alt="loading" />
                        </div>
                    ) : (
                        <>
                            {data.length > 0 ? (
                                <div className="control-pane">
                                    <div className="control-section">
                                        <GridComponent
                                            dataSource={data}
                                            height="500"
                                            allowPaging={true}
                                            allowResizing={true}
                                            allowGrouping={true}
                                            allowSorting={true}
                                            allowFiltering={true}
                                            showColumnMenu={true}
                                            groupSettings={groupOptions}
                                            filterSettings={filterSettings}
                                            toolbar={toolbarOptions}
                                            allowExcelExport={true}
                                            allowPdfExport={true}
                                            toolbarClick={toolbarClick.bind(this)}
                                            ref={(grid) => (gridInstance = grid)}
                                            dataBound={dataBound.bind(this)}
                                            pageSettings={{ pageCount: 4, pageSizes: true }}
                                            autoFit={true}

                                        // pageSettings={{ pageCount: 4, pageSize: 30 }}
                                        >
                                            <ColumnsDirective>
                                                <ColumnDirective
                                                    field="AsmName"
                                                    headerText="ASM"
                                                    width="192"
                                                    textAlign="center"
                                                ></ColumnDirective>
                                                <ColumnDirective
                                                    field="user_name"
                                                    headerText="Executive"
                                                    width="192"
                                                ></ColumnDirective>
                                                <ColumnDirective
                                                    field="car_model"
                                                    headerText="Model1"
                                                    width="192"
                                                    textAlign="center"
                                                />
                                                <ColumnDirective
                                                    field="car_variant"
                                                    headerText="Variant1"
                                                    width="192"
                                                    textAlign="center"
                                                />
                                                <ColumnDirective
                                                    field="CarModel2"
                                                    headerText="Model2"
                                                    width="192"
                                                    textAlign="center"
                                                />
                                                <ColumnDirective
                                                    field="CarVariant2"
                                                    headerText="Variant2"
                                                    width="192"
                                                    textAlign="center"
                                                />
                                                <ColumnDirective
                                                    field="win_num"
                                                    headerText="VIN"
                                                    width="192"
                                                    textAlign="center"
                                                ></ColumnDirective>
                                                <ColumnDirective
                                                    field="customer_name"
                                                    headerText="Customer Name"
                                                    width="192"
                                                    textAlign="center"
                                                ></ColumnDirective>
                                                <ColumnDirective
                                                    field="customer_mobile"
                                                    headerText="Customer Number"
                                                    width="192"
                                                    textAlign="center"
                                                ></ColumnDirective>
                                                <ColumnDirective
                                                    field="status"
                                                    headerText="Booking Status"
                                                    width="192"
                                                    textAlign="center"
                                                ></ColumnDirective>
                                            </ColumnsDirective>
                                            <Inject
                                                services={[
                                                    Resize,
                                                    Group,
                                                    Sort,
                                                    ColumnMenu,
                                                    Filter,
                                                    Page,
                                                    Toolbar,
                                                    ExcelExport,
                                                    PdfExport,
                                                ]}
                                            />
                                        </GridComponent>
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white">
                                    <DataNotFound />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default AdminSRMBookingCancelMonthly