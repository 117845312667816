import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import AllocationStatusGridInput from "./AllocationStatusGridInput";
import { DownloadTableExcel } from "react-export-table-to-excel";

const AllocationStatusGrid = () => {
  const tableRef = useRef(null);

  const [data1, setData1] = useState([]);
  const [message, setMessage] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [insertQuota, setInsertQuota] = useState({
    model: "",
    location: "",
    quota: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem("UserToken");

  useEffect(() => {
    AmGrandtotalAPI();
  }, []);

  const AmGrandtotalAPI = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: user,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/AmGrandtotal",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setData1(result.data);
          setApiFailled(false);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const QuotaData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: user,
      Location: insertQuota.location,
      Model: insertQuota.model,
      Quota: insertQuota.quota,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/am/QuotaData",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setMessage(result.message);
          AmGrandtotalAPI();
          setApiFailled(false);
        } else if (result.status_code === 300 && result.status === "fail") {
          setApiFailled(true);
          setMessage(result.Remark);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const getmodellocationquota = (model, location, state) => {
    setInsertQuota({ model: model, location: location, quota: state });
  };

  //   console.log(insertQuota.model, "model");
  //   console.log(insertQuota.location, "location");
  //   console.log(insertQuota.quota, "state");

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}

      {errorMessage && <ErrorMessage message={errorMessage} />}

      {loading ? (
        <img
          src="../assets/loadingGif.gif"
          alt="loading"
          className="mx-auto "
        />
      ) : (
        <div className="w-full  my-4 mx-auto overflow-x-auto">

          <div className="text-end">
            {/* <div
              className="block text-sm font-bold text-gray-700 mt-4"
            >
              Allocation Status
            </div> */}
            <DownloadTableExcel
              filename="Allocation Status"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button className="px-2 my-2 py-1 border bg-gray-300 rounded">
                Export Excel
              </button>
            </DownloadTableExcel>
          </div>

          <table
            ref={tableRef}
            className=" border border-black "
            cellSpacing={0}
            cellPadding={0}
          >

            <thead>

              <tr>
                {data1[0]?.showrooms?.map((item) => {
                  return (
                    <th className="    text-gridsmall font-semibold text-gray-900  text-center border border-black ">
                      {item.showroom}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {data1?.map((item) => {
              return (
                <tbody key={item}>
                  <tr
                    className={
                      item.type === "Grand Total" ? "bg-yellow-300" : "bg-white"
                    }
                  >
                    <td
                      rowSpan={"7"}
                      className="whitespace-nowrap text-center border border-black  text-gridsmall font-medium text-gray-900  "
                    >
                      {item.type}
                    </td>
                    <td className="whitespace-nowrap text-center border border-black bg-yellow-300 text-gridsmall font-normal text-gray-900 ">
                      Tentative Allocation
                    </td>
                    {item.type === "Grand Total" ? (
                      <>
                        {item.showrooms
                          .slice(2, item.showrooms.length)
                          ?.map((val) => {
                            return (
                              <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                                {val.TentativeAllocation}
                              </td>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {item.showrooms
                          .slice(2, item.showrooms.length)
                          ?.map((val) => {
                            return (
                              <AllocationStatusGridInput
                                value={val.TentativeAllocation}
                                model={item.type}
                                location={val.showroom}
                                getmodellocationquota={getmodellocationquota}
                                QuotaData={QuotaData}
                              />
                            );
                          })}
                      </>
                    )}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                      Allocated Physical
                    </td>
                    {item.showrooms
                      .slice(2, item.showrooms.length)
                      ?.map((val) => {
                        return (
                          <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                            {val.AllocatedPhysical}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                      Allocated Transit
                    </td>
                    {item.showrooms
                      .slice(2, item.showrooms.length)
                      ?.map((val) => {
                        return (
                          <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                            {val.AllocatedInTransit}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                      Allocated Pending
                    </td>
                    {item.showrooms
                      .slice(2, item.showrooms.length)
                      ?.map((val) => {
                        return (
                          <td className="whitespace-nowrap text-center border border-black  text-gridsmall font-normal text-gray-900 ">
                            {val.AllocatedPending}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap text-center border border-black bg-yellow-100 text-gridsmall font-normal text-gray-900 ">
                      DLD
                    </td>
                    {item.showrooms
                      .slice(2, item.showrooms.length)
                      ?.map((val) => {
                        return (
                          <td className="whitespace-nowrap text-center border border-black bg-yellow-100 text-gridsmall font-normal text-gray-900 ">
                            {val.DLD}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap text-center border border-black bg-green-100   text-gridsmall font-normal text-gray-900 ">
                      Total Allocation
                    </td>
                    {item.showrooms
                      .slice(2, item.showrooms.length)
                      ?.map((val) => {
                        return (
                          <td className="whitespace-nowrap text-center border border-black bg-green-100   text-gridsmall font-normal text-gray-900 ">
                            {val.TotalAllocation}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap text-center  border-black bg-orange-300 border text-gridsmall font-normal text-gray-900">
                      Balance
                    </td>
                    {item.showrooms
                      .slice(2, item.showrooms.length)
                      ?.map((val) => {
                        return (
                          <td className="whitespace-nowrap text-center  border-black bg-orange-300 border text-gridsmall font-normal text-gray-900 ">
                            {val.Balance}
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      )}
    </>
  );
};

export default AllocationStatusGrid;
