import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [mobileNum, setMobileNum] = useState("");
    const [loginFailed, setLoginFailed] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);


    const AdminLoginSendOtpAPi = () => {
        setBtnLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            mobile: mobileNum,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/common/AdminLoginSendOtp",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.message === "Otp send successfully"
                ) {

                    localStorage.setItem("verifynumber", mobileNum);
                    navigate("/creta-verify-otp");
                } else if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.message === "Please login with a different account"
                ) {
                    setLoginFailed("Invalid Credentials");
                } else if (
                    result.status_code === 200 &&
                    result.status === "Failed" &&
                    result.message === "OTP delivered fail"
                ) {
                    setLoginFailed(result.message);
                } else {
                    setLoginFailed("OOP's Technical Error. Please contact support");
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setBtnLoading(false));
    };

    const onlyNumericMobile = (e) => {
        var reg = /^[0-9\b]+$/;
        reg.test(e.target.value) || e.target.value === ""
            ? setMobileNum(e.target.value)
            : console.log("Please enter correct mobile number");
    };

    const mobilenumValidator = () => {
        let isValid = true;
        let errors = {};
        if (!mobileNum || mobileNum === "") {
            errors.mobileNum = "Please enter your mobile number";
            isValid = false;
        } else if (mobileNum.length < 10) {
            errors.mobileNum = "Please enter correct mobile number";
            isValid = false;
        }
        setError(errors);
        return isValid;
    };



    return (
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-6">
                <img
                    className="mx-auto h-12 w-auto"
                    src="../assets/Stockone_indigo.svg"
                    alt="StockOne"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
                <form
                    className="mt-8 space-y-6"
                    onSubmit={(e) => {
                        e.preventDefault();
                        mobilenumValidator()
                            ? AdminLoginSendOtpAPi()
                            : console.log("err in super admin login");
                    }}
                >
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm   font-bold text-gray-700"
                        >
                            Enter your Mobile number
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(e) => {
                                    onlyNumericMobile(e);
                                    error.mobileNum = "";
                                }}
                                className="block w-full rounded-md border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Enter your 10 digit valid mobile number"
                                maxLength={10}
                                value={mobileNum}
                            />
                        </div>
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs m-2">
                            {error.mobileNum}
                        </span>
                    </div>
                    {loginFailed && (
                        <div
                            className="p-2  text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                            role="alert"
                        >
                            <span className="font-medium"> {loginFailed}!</span>
                        </div>
                    )}
                    <button className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        {btnLoading ? (
                            <ThreeDots
                                height="20"
                                width="40"
                                radius="9"
                                color="#fff"
                                ariaLabel="three-dots-loading"
                            />
                        ) : (
                            <span>Submit</span>
                        )}
                    </button>

                </form>
            </div>
        </div>
    );
};

export default AdminLogin;
