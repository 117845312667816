import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";
import { AiFillNotification } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const SuccessMessage = (props) => {
  const navigate = useNavigate("")
  const [show, setShow] = useState(true);

  const SwitchHandler = (type) => {

    const actionOnEDPNotificationClick = (id) => {
      props.MarkRead(id)
      navigate("/edp-manage-delivery")
    }

    switch (type) {
      case "Stm Notifications":
        return (
          <div className="opacity-100   overflow-auto  ">
            {props.data?.map((item) => {
              return (
                <div aria-live="assertive" className=" px-4 py-6  sm:p-4  ">
                  <div className={props.conditiontype === "EDP" ? "w-full cursor-pointer" : "w-full"} onClick={() => props.conditiontype === "EDP" ? actionOnEDPNotificationClick(item.NotificationID) : ""}>
                    <Transition
                      show={show}
                      as={Fragment}
                      enter="transform ease-out duration-300 transition"
                      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="pointer-events-auto  overflow-hidden rounded-lg bg-amber-200 shadow-lg ring-1 ring-black ring-opacity-5" >
                        <div className="p-4">
                          <div className="flex items-center">
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                              <div className="text-sm font-medium text-gray-900 ">
                                <div className="flex items-center"><AiFillNotification fontSize={25} />{props.note}</div>
                                <p>Notification Message- {item.login || item.Massage}</p>
                              </div>
                            </div>
                            <div className="ml-4 flex flex-shrink-0">
                              <button
                                type="button"
                                className="inline-flex rounded-md bg-black text-white hover:text-gray-500 focus:outline-none "
                                onClick={() =>
                                  props.MarkRead(item.NotificationID)
                                }
                              >
                                <span className="sr-only">Close</span>
                                {props.loading ===
                                  item.NotificationID ? (
                                  <TailSpin
                                    height="18 "
                                    width="18"
                                    color="red"
                                  />
                                ) : (
                                  <XMarkIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>
              );
            })}
          </div>
        );
      default:
        return (
          <div
            aria-live="assertive"
            className="pointer-events-none mt-10  fixed inset-0 flex items-center px-4 py-6 sm:items-start sm:p-4"
          >
            <div className="flex w-full flex-col items-center space-y-4 ">
              <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {props.apiFailled ? (
                          <AiOutlineCloseCircle color="red" size={22} />
                        ) : (
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {props.message}
                        </p>
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          onClick={props.onClose}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        );
    }
  };

  return <>{SwitchHandler(props.type)}</>;
};

export default SuccessMessage;
