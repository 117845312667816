import React from "react";
import { useState } from "react";

const EDPTableCopyClipBoard = (props) => {
    const [text, setText] = useState(false);

    const copyTableData = () => {
        setText(true);
        setTimeout(() => {
            setText(false);
        }, 1500);

        let copyString = "";

        props.data.map((item) => {
            copyString += item.model + "=" + " Petrol - " + item.Petrol + ", Diesel - " + item.Diesel + ", CNG - " + item.CNG + ", Electric - " + item["Electric Vehicle"] + ", Total Count - " + item.totalCount + "\n";
        });

        navigator.clipboard.writeText(copyString);
    };

    return (
        <table className="border-collapse border border-slate-400 w-[50%] text-center">
            <caption className={props.className} onClick={copyTableData}>
                {props.caption} {text ? "(Copied)" : "(Click to copy)"}
            </caption>
            <thead>
                <tr>
                    <th className="border border-slate-300">Model</th>
                    <th className="border border-slate-300">Petrol</th>
                    <th className="border border-slate-300">Diesel</th>
                    <th className="border border-slate-300">CNG</th>
                    <th className="border border-slate-300">Electric</th>
                    <th className="border border-slate-300">Count</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td className="border border-slate-300">{item.model}</td>
                            <td className="border border-slate-300">{item.Petrol}</td>
                            <td className="border border-slate-300">{item.Diesel}</td>
                            <td className="border border-slate-300">{item.CNG}</td>
                            <td className="border border-slate-300">
                                {item["Electric Vehicle"]}
                            </td>
                            <td className="border border-slate-300">{item.totalCount}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default EDPTableCopyClipBoard;
