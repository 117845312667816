import React, { useEffect, useState } from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/ViewStockDetails.module.css";
import Input from "../../tailwindUI/Input";
import Button from "../../tailwindUI/Button";
import { useNavigate } from "react-router-dom";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import '../../index.css';
import {
    GridComponent, ColumnsDirective, ColumnDirective, Page,
    Inject,
    Resize,
    Group, Sort, ColumnMenu, Filter, Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import { registerLicense } from '@syncfusion/ej2-base';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const EDPDeleteCar = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [deleteCarRemark, setDeleteCarRemark] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [apiFailled, setApiFailled] = useState(false);
    const [deletedCarsData, setDeletedCarsData] = useState([]);
    const [query, setQuery] = useState("");
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [loading, setLoading] = useState(false);
    const users = localStorage.getItem("UserToken");

    useEffect(() => {
        EDPStockdeleteDetailListApi();
        EDPStockDetailListAPi();
    }, []);

    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/login", { replace: true });
    };

    const EDPStockDetailListAPi = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: users,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockDetailList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (
                    result.status_code === 200 &&
                    result.message === "data found successfully"
                ) {
                    setData(result.alldata);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const EDPStockdeleteAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: users,
            OrderNo: selectedPerson && selectedPerson.OrderId,
            CarRemark: deleteCarRemark,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockdelete",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (result.message === "send valid  OrderNo or VinNo") {
                    setApiFailled(true);
                    setMessage("Send valid Order or Vin No.");
                } else if (result.message === "car is allocated") {
                    setApiFailled(true);
                    setMessage("Car is allocated");
                } else if (result.status_code === 300) {
                    setApiFailled(true);
                    setMessage("Something went wrong please contact support !");
                } else if (result.status_code === 200 && result.status === "Success") {
                    setMessage(result.message);
                    setApiFailled(false);
                    setSelectedPerson(null);
                    setDeleteCarRemark("");
                    EDPStockdeleteDetailListApi();
                } else {
                    setApiFailled(true);
                    setMessage(result.Reason);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setErrorMessage("Something went wrong please contact support !");
            });
    };

    const EDPStockdeleteDetailListApi = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: users,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/edp/EDPStockdeleteDetailList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.status_code === 200 &&
                    result.status === "Success" &&
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" &&
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (
                    result.status_code === 200 &&
                    result.message === "data found successfully"
                ) {
                    setDeletedCarsData(result.data);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const successMessage = () => {
        setTimeout(() => {
            setMessage("");
        }, 3000);
        return message;
    };

    const BreadCrumb = {
        pagename: "Car Delete",
        pagePath: "/edp-delete-car",
        pagename1: "",
        pagePath1: "",
    };

    const BreadCrumHomeLink = "/edp-dashboard";

    const filteredPeople =
        query === ""
            ? data
            : data.filter((item) => {
                return item.OrderId?.toLowerCase()?.includes(query.toLowerCase());
            });

    const dateHandler = (date) => {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const monthNameLong = dateObj?.toLocaleString("en-US", { month: "short" });
        const year = dateObj.getFullYear();
        return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
    };

    const groupOptions = { showGroupedColumn: true };
    const filterSettings = { type: "CheckBox" };


    registerLicense(
        'Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9'
    );

    const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];
    let gridInstance;
    let flag = true;
    function dataBound() {
        if (flag) {
            gridInstance.toolbarModule.toolbar.hideItem(2, true);
            flag = false;
        }
    }

    function toolbarClick(args) {
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <>
            {message && (
                <SuccessMessage
                    onClose={() => setMessage("")}
                    message={successMessage()}
                    apiFailled={apiFailled}
                />
            )}

            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Navbar navbarType={"blue navbar"} />
            <div className={classes.maincontainer}>
                <div className="mb-4">
                    <Heading
                        headingType={"Page Headings"}
                        headingText={"Car Delete"}
                        BreadCrumb={BreadCrumb}
                        BreadCrumHomeLink={BreadCrumHomeLink}
                    />
                </div>
                <div className="w-[85%] bg-white mx-auto mt-[20px] p-2">
                    <form className="mb-4">
                        <div className="w-full relative pb-4">
                            <Combobox
                                as="div"
                                value={selectedPerson}
                                onChange={setSelectedPerson}
                            >
                                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                    Delete Car
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(event) => setQuery(event.target.value)}
                                        placeholder="Enter Order ID"
                                        displayValue={(person) => person?.OrderId}
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </Combobox.Button>

                                    {filteredPeople.length > 0 && (
                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredPeople.map((person) => (
                                                <Combobox.Option
                                                    key={person.car_id}
                                                    value={person}
                                                    className={({ active }) =>
                                                        classNames(
                                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                                            active
                                                                ? "bg-indigo-600 text-white"
                                                                : "text-gray-900"
                                                        )
                                                    }
                                                >
                                                    {({ active, selected }) => (
                                                        <>
                                                            <div className="flex">
                                                                <span
                                                                    className={classNames(
                                                                        "truncate",
                                                                        selected && "font-semibold"
                                                                    )}
                                                                >
                                                                    {person.win_num}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.OrderId}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.model_name}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.variant}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.colour}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.fueltype}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.carstatus}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.allocation_status}
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    Transit Mode- ({person.TransitMode})
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    {person.availabilitystatus}
                                                                </span>

                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    Sign Off- ({dateHandler(person.signoff_date)})
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        "ml-2 truncate text-gray-500",
                                                                        active ? "text-indigo-200" : "text-gray-500"
                                                                    )}
                                                                >
                                                                    Hmil- ({dateHandler(person.hmil_date)})
                                                                </span>
                                                            </div>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                                                        active ? "text-white" : "text-indigo-600"
                                                                    )}
                                                                >
                                                                    <CheckIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        {/* <div className='w-full relative pb-4'>
                            <Input
                                type={"input with label"}
                                label="Delete Car"
                                inputType="text"
                                placeholder="Enter Vin Or Order ID to delete a car"
                                value={deleteVinOrOrder}
                                error={error.deleteVinOrOrder}
                                onInput={() => (error.deleteVinOrOrder = "")}
                                onChange={(e) => {
                                    setDeleteVinOrOrder(e.target.value);
                                }}
                            />
                            <span className="error absolute left-0 -bottom-1">{error.deleteVinOrOrder}</span>
                        </div> */}
                        <div className="w-full relative pb-4">
                            <Input
                                type={"textarea"}
                                label="Car Remark"
                                rows="1"
                                inputType="text"
                                placeholder="Enter car remark"
                                value={deleteCarRemark}
                                onChange={(e) => {
                                    setDeleteCarRemark(e.target.value);
                                }}
                            />
                        </div>
                        {selectedPerson && selectedPerson.OrderId ? (
                            <div className="w-[10%] mt-2">
                                <Button
                                    type="redFilled"
                                    buttonText="Submit"
                                    onClick={() => EDPStockdeleteAPI()}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </form>
                    <div className="block text-sm font-medium text-gray-700">
                        Deleted Cars List
                    </div>
                    {loading ? (
                        <img
                            src="../assets/loadingGif.gif"
                            alt="loading"
                            className={classes.loader}
                        />
                    ) : (

                        <div className='control-pane'>
                            <div className='control-section'>
                                <GridComponent
                                    dataSource={deletedCarsData}
                                    height='500'
                                    allowPaging={true}
                                    allowResizing={true}
                                    allowGrouping={true}
                                    allowSorting={true}
                                    allowFiltering={true}
                                    showColumnMenu={true}
                                    groupSettings={groupOptions}
                                    filterSettings={filterSettings}
                                    toolbar={toolbarOptions}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbarClick={toolbarClick.bind(this)}
                                    ref={(grid) => (gridInstance = grid)}
                                    dataBound={dataBound.bind(this)}
                                    pageSettings={{ pageCount: 4, pageSizes: true }}
                                    autoFit={true}

                                // pageSettings={{ pageCount: 4, pageSize: 30 }}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='win_num' headerText='VIN' width='130' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='OrderId' headerText='Order' width='130' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='model_name' headerText='Model' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='variant' headerText='Variant' width='140'></ColumnDirective>
                                        <ColumnDirective field='fueltype' headerText='Fuel Type' width='120' textAlign='center' />
                                        <ColumnDirective field='colour' headerText='Colour' width='130' textAlign='center' />
                                        <ColumnDirective field='availabilitystatus' headerText='Status' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='carstatus' headerText='Car Status' width='120' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='CarRemark' headerText='Car Remark' width='130' textAlign='center'></ColumnDirective>
                                        <ColumnDirective field='updated_at' headerText='Deleteion Date' width='120' textAlign='center'></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Resize, Group, Sort, ColumnMenu, Filter, Page, Toolbar, ExcelExport, PdfExport]} />

                                </GridComponent>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EDPDeleteCar;
