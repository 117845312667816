import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GridListCards from "../../tailwindUI/GridListCards";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/RealTimeStockTwo.module.css";
import Navbar from "../../tailwindUI/Navbar";

const AsmRealTimeStockOne = () => {
    const [stockData, setStockdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        carStock();
    }, []);
    const { modelName } = useParams();
    const UserToken = localStorage.getItem("UserToken");

    const filters = [
        {
            id: "model",
            name: "Model",
            options: [
                { value: "new-arrivals", label: "All New Arrivals", checked: false },
                { value: "tees", label: "Tees", checked: false },
                { value: "objects", label: "Objects", checked: true },
            ],
        },
        {
            id: "color",
            name: "Color",
            options: [
                { value: "white", label: "White", checked: false },
                { value: "beige", label: "Beige", checked: false },
                { value: "blue", label: "Blue", checked: false },
            ],
        },
        {
            id: "variant",
            name: "Variant",
            options: [
                { value: "s", label: "S", checked: false },
                { value: "m", label: "M", checked: false },
                { value: "l", label: "L", checked: false },
            ],
        },
    ];



    const BreadCrumb = {
        pagename: "Real Time Stock",
        pagePath: "/asm-dashboard",
        pagename1: "Variant List",
        pagePath1: "/asm-real-time-stock-one/" + modelName,
    };
    const LogOut = () => {
        localStorage.removeItem("UserName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("LastName");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("Location");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserLoggedin");
        navigate("/login", { replace: true });
    };

    const carStock = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            UserToken: UserToken,
            Model: modelName.replace(/-/g, '/'),
            // "Variant": "1.0 Turbo GDI DCT SX+",
            // "FuelType": "Petrol"
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://promote.onecorp.co.in/api/v1/stockone/common/RTSList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (
                    result.data ===
                    "Invalid User Token, The token may have expired, You may need to re-login" ||
                    result.message ===
                    "Invalid User Token, The token may have expired, You may need to re-login"
                ) {
                    LogOut();
                } else if (result.status === "Success" && result.status_code === 200) {
                    setStockdata(result.data);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };
    return (
        <>
            {" "}
            <Navbar navbarType="green navbar" />
            <Heading
                headingType={"Page Headings"}
                headingText={"Dashboard"}
                subheadingText={localStorage.getItem("Location")}
                BreadCrumb={BreadCrumb}
            />
            {loading ? (
                <div className={classes.loader}>
                    {" "}
                    <img src="../assets/loadingGif.gif" alt="loading" />
                </div>
            ) : (
                <div className={classes.mainContainer}>
                    <div className="sm:flex sm:justify-between sm:items center bg-white p-4">
                        <div className="sm:flex pl-4 sm:pl-0 ">
                            <div className="mr-2 text-xs">
                                <span className="bg-green-600 w-2 h-2 inline-block mr-1"></span>
                                Available In Stock
                            </div>
                            <div className="mr-2 text-xs">
                                <span className="bg-blue-600 w-2 h-2 inline-block mr-1"></span>
                                InStock But Allocated
                            </div>
                            <div className="mr-2 text-xs">
                                <span className="bg-red-500 w-2 h-2 inline-block mr-1"></span>
                                Out Of Stock
                            </div>
                        </div>
                    </div>


                    <GridListCards
                        modelName={modelName}
                        stockList={stockData}
                        url={"/asm-real-time-stock-three"}
                    />
                </div>
            )}
        </>
    );
};

export default AsmRealTimeStockOne;
