// import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import Input from "./Input";
import { TiTick } from "react-icons/ti"
import { useRef } from "react";
import { useEffect } from "react";

const people = [
  {
    name: "Jane Cooper",
    title: "Paradigm Representative",
    role: "Admin",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  // More people...
];
export default function CarCard(props) {

  const variantIb = useRef(null);
  const colorIb = useRef(null);
  const updateImgIb = useRef(null);

  const addVar = () => {
    let variantName = variantIb.current.value;
    props.addVariant(variantName);
    variantIb.current.value = "";
  }

  const addCol = () => {
    let colorName = colorIb.current.value;
    props.addColor(colorName);
    colorIb.current.value = "";
  }

  const updateImg = (item) =>{
    props.changeModelImage(item);
  }

  useEffect(() => {
    if(props.type === "modalCard"){
      props.changeModelName(props.data.model);
      props.changeFuelType(props.data.fuel_type);
    }
  }, []);

  const cardType = (type) => {
    switch (type) {
      case "modalCard":
        return (
          <ul role="list" className="grid grid-cols-1 gap-6">
            <li
              key={props.data.cardbID}
              className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div className="flex-1 flex flex-col sm:p-8 p-0">

                <div className="flex justify-between md:flex-row flex-col relative">
                  <img
                    className="w-80 h-56 rounded-lg cursor-pointer md:m-0 m-auto"
                    src={props.data.carmodel_image}
                    alt="STOCK ONE"
                    onClick={()=>{document.getElementById("updateImg").click()}}
                  />
                  <input type="file" className="w-0" id="updateImg" ref={updateImgIb} onChange={(e)=>{updateImg(e.target.files[0])}} />
                  <div className="flex flex-col mx-auto md:w-64 w-80">
                    <label htmlFor="updateId" className="mr-auto mb-1 text-sm font-medium"> Model Name</label>
                    <input type="text" className="border border-gray-400 border-solid rounded-md w-56 px-2 py-px outline-none" id="updateId" defaultValue={props.data.model} onChange={(e) => { props.changeModelName(e.target.value) }} />
                    <span className="font-medium text-sm mr-auto mt-6"> Fuel Type </span>
                    <div className="flex md:flex-col flex-row">
                    {
                      props.fuelTypes.map((fuels) => (
                        <div className="flex md:mr-0 mr-3 md:justify-start justify-center items-center" key={fuels.id}>
                          <input
                            id={fuels.id}
                            name="fuel-types"
                            type="radio"
                            defaultChecked={fuels.title === props.data.fuel_type}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 mt-3"
                            onChange={() => { props.changeFuelType(fuels.title) }}
                          />
                          <label
                            htmlFor={fuels.id}
                            className="sm:ml-3 ml-1 block text-sm font-medium text-gray-700 mt-3"
                          >
                            {fuels.title}
                          </label>
                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-between md:flex-row flex-col">

                  <div className="flex flex-col md:w-80 w-full md:mt-0 mt-2">
                    <div className="flex md:flex-row flex-col items-start justify-around md:items-center pb-2 border-0 md:border-b-2 border-solid border-gray-300 font-medium relative md:w-auto w-80 md:mx-0 mx-auto">
                      <label htmlFor="updateVariantBox" className="text-sm font-medium mb-1 sm:mb-0"> Variant </label> {" "}{" "}
                      <input type="text" id="updateVariantBox" className="outline-none border-gray-400 border-solid border rounded-md px-2 py-px text-base sm:m-0 " ref={variantIb} />
                      <button className="px-2 py-1.5 absolute md:top-auto top-6 sm:top-5 md:right-4 right-1/3 outline-none" onClick={() => { addVar() }}> <TiTick /> </button>
                    </div>
                    <div className="h-full mt-2 md:w-full w-80 md:mx-0 mx-auto text-left">
                      {props.variantGroup.map((item, index) => (
                        <span
                          key={index}
                          className="bg-indigo-300 text-indigo-800 text-sm rounded-xl px-2 py-px mx-1 whitespace-nowrap"
                        >
                          {item} &nbsp;
                          <span
                            className="font-bold cursor-pointer"
                            onClick={() => {
                              props.removeVariant(item);
                            }}
                          >
                            &#10005;
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col md:w-80 w-full md:mt-0 mt-10">
                    <div className="flex md:flex-row flex-col items-start justify-around md:items-center pb-2 border-0 md:border-b-2 border-solid border-gray-300 font-medium relative md:w-auto w-80 md:mx-0 mx-auto">
                      <label htmlFor="updateColorBox" className="text-sm font-medium mb-1 sm:mb-0"> Colour </label> {" "}{" "}
                      <input type="text" id="updateColorBox" className="outline-none border-gray-400 border-solid border rounded-md px-2 py-px text-base" ref={colorIb} />
                      <button className="px-2 py-1.5 absolute md:top-auto top-6 sm:top-5 md:right-4 right-1/3 outline-none" onClick={() => { addCol() }}> <TiTick /> </button>
                    </div>
                    <div className="h-20 mt-2 md:w-full w-80 md:mx-0 mx-auto text-left">
                      {props.colorGroup.map((item, index) => (
                        <span
                          key={index}
                          className="bg-indigo-300 text-indigo-800 text-sm rounded-xl px-2 py-px mx-1 whitespace-nowrap"
                        >
                          {item} &nbsp;
                          <span
                            className="font-bold cursor-pointer"
                            onClick={() => {
                              props.removeColor(item);
                            }}
                          >
                            &#10005;
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>

                </div>
                
              </div>
            </li>
          </ul>
        );

      case "normalCard":
        return (
          <ul role="list" className="grid grid-cols-1 gap-6">
            <li
              key={props.data.cardbID}
              className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div className="flex-1 flex flex-col p-8">
                <img
                  className="w-full h-32 flex-shrink-0 mx-auto"
                  src={props.data.carmodel_image}
                  alt=""
                />
                <h3 className="mt-6 text-gray-900 text-sm font-medium">
                  {props.data.model}
                </h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                  {/* <dt className="sr-only">Title</dt> */}
                  <dd className="text-gray-500 text-sm">
                    {props.data.variant}
                  </dd>
                  <dd className="text-gray-500 text-sm">{props.data.colour}</dd>
                  {/* <dt className="sr-only">Role</dt> */}
                  <dd className="mt-3">
                    <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                      {props.data.fuel_type}
                    </span>
                  </dd>
                </dl>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="w-0 flex-1 flex">
                    <a
                      href={``}
                      className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                      <span className="ml-3">Email</span>
                    </a>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <a
                      href={``}
                      className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                      <span className="ml-3">Call</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        );
    }
  };

  return (cardType(props.type));
}
