import { useState, useEffect } from "react";
import ConfermationModal from "../../Components/ConfermationModal";
import SmWrapper from "../../Components/SmWrapper";
import Button from "../../tailwindUI/Button";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import Pagination from "../../tailwindUI/Pagination";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Table from "../../tailwindUI/Table";
import classes from "../../module.css/ManageExecutive.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";

const ManageExecutive = () => {
  const [executiveData, setExecutiveData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [asm, setAsm] = useState("");
  const [asmData, setAsmData] = useState([]);
  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [error, setError] = useState("");
  const [executiveId, setExecutiveId] = useState("");
  const [loading, setLoading] = useState("");
  const [message, setMessage] = useState("");
  const [count, setCount] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  useEffect(() => {
    AsmList();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(count / 20);
  useEffect(() => {
    ExecutiveList();
  }, [page]);

  const executiveTableHeading = [
    {
      name: "Name",
    },
    { name: "Mobile" },
    { name: "ASM name" },
    { name: "Email" },
    { name: "Location" },
    { name: "Role" },
    { name: "Remove" },
    { name: " Update Executive" },

  ];
  const emailRegex =
    /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let alphabetsRegex = /^[a-zA-Z][a-zA-Z ]*$/;

  const formValidation = () => {
    let errors = {};
    let isValid = true;
    // if (!email || email === "") {
    //   errors.email = "Please enter email address";
    //   isValid = false;
    // } else if (!emailRegex.test(email)) {
    //   isValid = false;
    //   errors.email = "Please enter valid email address";
    // }
    if (email && !emailRegex.test(email)) {
      isValid = false;
      errors.email = "Please enter valid email address";
    }
    if (!name || name === "") {
      errors.name = "Please enter Name";
      isValid = false;
    } else if (!alphabetsRegex.test(name)) {
      errors.name = "Please enter alphabets only";
      isValid = false;
    }

    if (!selectedLocation || selectedLocation === "") {
      errors.location = "Please Select your location";
      isValid = false;
    }

    if (!asm || asm === "") {
      errors.asm = "Please Select ASM";
      isValid = false;
    }
    if (!password || password === "") {
      errors.password = "Please  enter password";
      isValid = false;
    }
    if (phone.length < 10) {
      errors.phone = "Please enter valid phone no";
      isValid = false;
    }
    if (phone === "" || !phone) {
      errors.phone = "Please Enter phone number";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setPhone(e.target.value)
      : console.log("");
  };

  const onlyAlphabetsName = (e) => {
    var reg = /^[a-zA-Z][a-zA-Z ]*$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setName(e.target.value)
      : console.log("");
  };
  const UserToken = localStorage.getItem("UserToken");
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate('/login', { replace: true })
  }
  const ExecutiveList = () => {
    setLoadingList(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      content: 20,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/AllExecutive",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        }
        else if (result.status === "Success" && result.status_code === 200) {
          if (result.data === "") {
            setExecutiveData([]);
          } else {
            setExecutiveData(result.data);
            setCount(result.count);
          }
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoadingList(false));
  };
  const AsmList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMASMList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        } else if (result.status === "Success" && result.status_code === 200) {
          setAsmData(result.data);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
  };
  const RemoveExecutive = (id) => {
    setLoading("remove");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      ExecutiveId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMExicutivesDelete",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        } else if (result.status === "Success" && result.status_code === 200) {
          setOpen(false);
          setMessage("Executive removed successfully");
          ExecutiveList();
        } else if (result.status_code === 300 && result.status === "Fail") {
          alert("Sorry, This detail can not be deleted");
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const addExcutive = () => {
    setLoading("add");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      UserLocation: selectedLocation,
      UserPassword: password,
      UserMobile: phone,
      UserEmail: email,
      UserName: name,
      AsmId: asm,
      UserRole: "",
      UserStatus: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMExicutivesAdd",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        } else if (result.status === "Success" && result.status_code === 200) {
          setMessage("Executive added successfully");
          setName("");
          setPassword("");
          setEmail("");
          setError("");
          setPhone("");
          setAsm("");
          setSelectedLocation("");
          ExecutiveList();
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const BreadCrumb = {
    pagename: "Manage Executive",
    pagePath: "/sm-manage-executive",
    pagename1: "",
    pagePath1: "",
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const showroomLocation = [
    {
      id: 1,
      name: localStorage.getItem("Location"),
    },
  ];

  return (
    <SmWrapper>
      <ConfermationModal
        onClick={() => {
          RemoveExecutive(executiveId);
          ExecutiveList();
        }}
        loading={loading}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        open={open}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {message && <SuccessMessage message={successMessage()} />}
      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"Manage Executive"}
        subheadingText={localStorage.getItem("Location")}

      />
      {loadingList ? (
        <div className={classes.loader}>
          <img src="../assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="text"
                label="Name"
                value={name}
                onChange={(e) => {
                  onlyAlphabetsName(e);
                  error.name = "";
                }}
                error={error.name}
              />
              <p class="peer-invalid:visible text-red-500 italic">
                {error.name}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="email"
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  error.email = "";
                }}
                error={error.email}
              />
              <p class="peer-invalid:visible text-red-500 italic ">
                {error.email}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="tel"
                label="Mobile number"
                maxLength="10"
                onChange={(e) => {
                  onlyNumericMobile(e);
                  error.phone = "";
                }}
                value={phone}
                error={error.phone}
              />
              <p class="peer-invalid:visible text-red-500 italic">
                {error.phone}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="password"
                label="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  error.password = "";
                }}
                error={error.password}
              />
              <p class="peer-invalid:visible text-red-500 italic ">
                {error.password}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type={"Select Menus Simple native"}
                label={"ASM"}
                asmData={asmData}
                dropdownType={"asmNew"}
                selected={asm === ""}
                onChange={(e) => {
                  setAsm(e.target.value);
                  error.asm = "";
                }}
                error={error.asm}
              />
              <p class="peer-invalid:visible text-red-500 italic ">
                {error.asm}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type={"Select Menus Simple native"}
                label={"location"}
                data={showroomLocation}
                selected={selectedLocation === ""}
                onChange={(e) => {
                  setSelectedLocation(e.target.value);
                  error.location = "";
                }}
                dropdownType={"location"}
                error={error.location}
              />
              <p class="peer-invalid:visible text-red-500 italic">
                {error.location}
              </p>
            </div>
            <div
              // className={classes.inputContainer}
              class="mt-8"
            >
              <Button
                onClick={() => {
                  formValidation()
                    ? addExcutive()
                    : console.log("Request error");
                }}
                loading={loading === "add" ? true : false}
                type={"redFilled"}
                buttonText={"Submit"}
              />
            </div>
          </div>
          {executiveData.length === 0 ? (
            ""
          ) : (
            <div className="bg-white">
              <Table
                tableHeading={executiveTableHeading}
                setOpen={setOpen}
                setExecutiveId={setExecutiveId}
                tabledataType={"executive"}
                type="vertical lines"
                TableData={executiveData}
              />
              {maxPages > 1 && <Pagination maxPages={maxPages} />}
            </div>
          )}
        </div>
      )}
    </SmWrapper>
  );
};
export default ManageExecutive;
