import React from "react";
import Button from "../tailwindUI/Button";
import classes from "../module.css/ViewStockDetails.module.css";

function TableHeader(props) {
  const callApi = (e) => {
    if (e.key === "Enter" && props.searchTerm.length > 2) {
      props.apicall();
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-white sm:pb-6 sm:pt-4 py-2 relative">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl text-gray-900 mb-2 font-bold">
            {props.tableHeading}
          </h1>
          <div className="flex">
            <input
              type="search"
              className={`${classes.border1px} sm:w-3/4 w-4/5 border-solid border-gray-400 mr-4 rounded-md px-2 py-px sm:text-sm text-xs outline-none`}
              placeholder={
                props.placeholderType
                  ? props.placeholderType
                  : "Enter For Ex:-(Vin No, Order Id, Model, Variant, Fuel Type, Colour, Status)"
              }
              value={props.searchTerm}
              onChange={(e) => {
                props.setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => callApi(e)}
            />
            <Button
              type={"smallButton"}
              buttonText={props.buttonText}
              onClick={() => {
                props.apicall();
              }}
            />
          </div>
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"> */}
        <div className="sm:absolute sm:right-6 sm:bottom-2 mt-2">
          <p className="text-sm italic text-gray-500">
            {props.condition === "managedevlivery" ? "" : <>{props.pageResult} of {props.totalResult} results{" "}</>}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
