import React, { useState } from "react";
import Breadcrumb from "../tailwindUI/BreadCrumb";
import Devider from "../tailwindUI/Devider";
import Heading from "../tailwindUI/Heading";
import Navbar from "../tailwindUI/Navbar";
import Tab from "../tailwindUI/Tab";
import Button from "../tailwindUI/Button";
import GridListCards from "../tailwindUI/GridListCards";
import Input from "../tailwindUI/Input";
import Table from "../tailwindUI/Table";
import classes from "./UILibrary.module.css";
import Modal from "../tailwindUI/Modal";
import Pagination from "../tailwindUI/Pagination";
import Filter from "../tailwindUI/Filter";
import Card from "../tailwindUI/Card";
import CarCard from "../tailwindUI/CarCard";
import { BiTrain } from "react-icons/bi";
import { BsTruck } from "react-icons/bs";
const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
];
const asmTableHeading = [
  { name: "Name",},
  { name: "Mobile" },
  { name: "Email" },
  { name: "Location" },
  { name: "Remove" },
];
const filters = [
  {
    id: "model",
    name: "Model",
    options: [
      { value: "new-arrivals", label: "All New Arrivals", checked: false },
      { value: "tees", label: "Tees", checked: false },
      { value: "objects", label: "Objects", checked: true },
    ],
  },
  {
    id: "color",
    name: "Color",
    options: [
      { value: "white", label: "White", checked: false },
      { value: "beige", label: "Beige", checked: false },
      { value: "blue", label: "Blue", checked: false },
    ],
  },
  {
    id: "variant",
    name: "Variant",
    options: [
      { value: "s", label: "S", checked: false },
      { value: "m", label: "M", checked: false },
      { value: "l", label: "L", checked: false },
    ],
  },
];
const activeFilters = [{ value: "objects", label: "Objects" }];
const UILibrary = () => {
  const [filterOption, setFilterOption] = useState(filters);
  const [actFilter, setActFilter] = useState(activeFilters);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const tabledata = [
    {
      name: "Lindsay Walton",
      title: "Front-end Developer",
      email: "lindsay.walton@example.com",
      role: "Member",
    },
    // More people...
  ];

  // ...................for manage transit
  const vehicle = [
    {
      icon: <BsTruck size={35} />,
      vehicleno: "Exi Option",
      bgcolor: "bg-sky-500",
      stock: "colours in stock",
    },
    {
      icon: <BiTrain size={35} />,
      vehicleno: "Exi Option",
      bgcolor: "bg-red-600",
      stock: "colours in stock",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [open, setOpen] = useState(false);
  const tabs = [
    { name: "My Account", href: "#", current: false },
    { name: "Company", href: "#", current: false },
    { name: "Team Members", href: "#", current: true },
    { name: "Billing", href: "#", current: false },
  ];
  const handleCheckboxChange = (option, section) => {
    const isChecked = checkedCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox.value === option.value
    );
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox.value !== option.value
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(option));
    }
  };
  const removeHandler = (value) => {
    const remove = checkedCheckboxes.filter((item) => {
      return item.value !== value;
    });
    setCheckedCheckboxes(remove);
  };
  return (
    <div>
      <Devider title={"blue navbar"} />
      <Navbar navbarType={"blue navbar"} />

      <Devider title={"red navbar"} />
      <Navbar navbarType={"red navbar"} />
      <div className="p-10">
        <>
          <Devider title={"Section Headings"} />

          <div className={"heading_section mb-4 "}>
            <Heading headingType={"Section Headings"} headingText={"Simple"} />
          </div>
          <Devider title={"Page Headings"} />

          <div className={"heading_section "}>
            <Heading
              headingType={"Page Headings"}
              headingText={"With actions and breadcrumbs"}
            />
          </div>
        </>
        <Devider title={"Filter"} />

        <div className={"heading_section "}>
          <Filter
            sortOptions={sortOptions}
            filters={filterOption}
            activeFilters={actFilter}
            checkedCheckboxes={checkedCheckboxes}
            setCheckedCheckboxes={setCheckedCheckboxes}
            handleCheckboxChange={handleCheckboxChange}
            removeHandler={removeHandler}
          />
        </div>
        <Devider title={"Tabs"} />

        <Tab tabs={tabs} />

        <Devider title={"Breadcrumbs"} />
        <Breadcrumb />

        <>
          <Devider title={" Small Button"} />
          <Button buttonText={"click me!"} type={"smallButton"} />
          <Devider title={" Red filled button"} />
          <Button buttonText={"click me!"} type={"redFilled"} />
          <Devider title={" White button"} />
          <Button buttonText={"click me!"} type={"whiteButton"} />
          <Devider title={" Green button"} />
          <Button buttonText={"click me!"} type={"greenFilled"} />
          <Devider title={" Green long button"} />
          <Button buttonText={"click me!"} type={"greenLong"} />
          <Devider title={" Pink long button"} />
          <Button buttonText={"click me!"} type={"pinkLong"} />
          <Devider title={"grid list crads"} />

          <GridListCards />
          <Devider title={"Table stripped rows"} />
          <Table
            type="stripped rows"
            TableData={tabledata}
            tableHeading={asmTableHeading}
            buttonText="Add "
            // tableHeading="tableHeading"
            subHeading="subHeading"
            columnOne="columnOne"
            columnTwo="columnTwo"
            columnThree="columnThree"
            columnFour="columnFour"
          />
          <Devider title={"Table vertical lines"} />
          <Table type="vertical lines" tableHeading={asmTableHeading} TableData={tabledata} />
          <Devider title={"input text area"} />
          <Input type={"textarea"} />
          <Devider title={"input no label"} />
          <Input type={"input no label"} />
          <Devider title={"radio group"} />
          <Input type={"radio group"} />
          <Devider title={"input with label"} />
          <Input type={"input with label"} label={"email"} />
          <Devider title={"Select Menus Simple native"} />
          <Input type={"Select Menus Simple native"} label={"location"} />
          <Devider title={"Select Menus Simple custom"} />
          <Input type={"Select Menus Simple custom"} />
          <Devider title={"Transit card"} />

          <div className={classes.bg}>
            <div className={classes.heading}>
              <ul
                role="list"
                className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
              >
                {vehicle.map((item) => {
                  return <Card {...item} />;
                })}
              </ul>
            </div>
          </div>

          <Devider title={"Pagination"} />
          <Pagination />
          <Devider title={"CarCard"} />
          <CarCard />
          <Modal type={"Simple with gray footer"} />
          {/* <Modal type={"Simple alert"} /> */}
          <Modal type={"Manage transit"} setOpen={setOpen} open={open} />
          {/* <Modal type={"Simple alert"} /> */}
        </>
      </div>
    </div>
  );
};

export default UILibrary;
