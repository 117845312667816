import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SmWrapper from "../../Components/SmWrapper";
import Button from "../../tailwindUI/Button";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import StackedList from "../../tailwindUI/StackedList";
import classes from "../../module.css/SearchUpdate.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Pagination from "../../tailwindUI/Pagination";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
const SearchUpdate = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [bookingData, setBookingData] = useState([]);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  useEffect(() => {
    searchApi();
  }, [page, searchTerm === ""]);

  const navigate = useNavigate();
  const UserToken = localStorage.getItem("UserToken");
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const searchApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      SearchTerm: searchTerm,
      page: page,
      content: 20,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingsSearch",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message === "Data Found Successfully"
        ) {
          // if (searchTerm) {
          //   navigate(pathname);
          // }
          setBookingList(result.data);
          setCount(result.count);
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message === "Data not found"
        ) {
          // if (searchTerm) {
          //   navigate(pathname);
          //   setCount(0);
          // }
          setBookingList([]);
        } else if (result.status_code === 200 && result.status === "fail") {
          setBookingList([]);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };


  const BookingDetail = (bookingId) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: bookingId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        }
        if (result.status === "Success" && result.status_code === 200) {
          setBookingData(result.data);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
  };

  const maxPages = Math.ceil(count / 20);
  const BreadCrumb = {
    pagename: "Booking",
    pagePath: "/sm-search-update",
  };

  const callApi = (e) => {
    if (e.key === "Enter" && searchTerm.length > 2) {
      searchApi();
    }
  };

  return (
    <SmWrapper>
      {open && (
        <FollowUpRemarks
          bookingData={bookingData}
          setOpen={setOpen}
          open={open}
        />
      )}
      <Heading
        headingType={"Page Headings"}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingText={"Search and update booking"}
        BreadCrumb={BreadCrumb}
        subheadingText={localStorage.getItem("Location")}

      />
      <div className={classes.mainContainer}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <div className={classes.searchContainer}>
          <h2 className={classes.searchHeading}>Search Criteria</h2>
          <div className={classes.inputButtonWrapper}>
            <div className={classes.inputWrapper}>
              <Input
                type={"input with label"}
                placeholder={"For Ex:- Customer Name,Customer Mobile, Model, Variant, Colour, Fuel Type, ASM,"}
                inputType={"search"}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => callApi(e)}
              />
            </div>
            <div className="mt-1">
              <Button
                buttonText={"Search"}
                type={"SearchButton"}
                onClick={() => searchApi()}
              />
            </div>
          </div>
        </div>
        {bookingList.length !== 0 && (
          <div className=" flex px-4  justify-between items-center text-gray-500 ">
            <div className="flex   items-center ">
              <div className=" mr-1 bg-green-600 w-2 h-2 "></div>
              <span className=" text-xs"> Allocated Cars</span>
            </div>

            <div className="my-2 text-end">
              <p className="text-sm italic text-gray-500 mr-1">
                {bookingList.length} of {count} results
              </p>
            </div>
          </div>
        )}

        <div className="bg-white overflow-x-auto">
          {loading ? (
            <div className={classes.loader}>
              <img src="../assets/loadingGif.gif" alt="loading" />
            </div>
          ) : (
            <div className="bg-white w-auto min-w-[1079px]">
              {bookingList.length === 0 ? (
                <DataNotFound />
              ) : (
                <div className={classes.stackListContainer}>
                  <div role="list" className={classes.stackListWrapper}>
                    {bookingList.map((item, index) => (
                      <StackedList
                        type="update booking"
                        item={item}
                        key={index}
                        id={index}
                        setOpen={setOpen}
                        BookingDetail={BookingDetail}
                        onClick={() => {
                          item.status === "Cancelled"
                            ? console.log("cancelled booking can not be update")
                            : navigate("/sm-update-booking/" + item.BookingID);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {maxPages > 1 && <Pagination maxPages={maxPages} />}
      </div>
    </SmWrapper>
  );
};

export default SearchUpdate;
