import React, { useState } from "react";
import Heading from "../../tailwindUI/Heading";
import StackedList from "../../tailwindUI/StackedList";
import Pagination from "../../tailwindUI/Pagination";
import Tab from "../../tailwindUI/Tab";
import classes from "../../module.css/PendingBookings.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import SmWrapper from "../../Components/SmWrapper";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const PendingBookings = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [tabType, setTabType] = useState("All");
  const [loading, setLoading] = useState(true);
  const UserToken = localStorage.getItem("UserToken");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
  const navigate = useNavigate();
  useEffect(() => {
    BookingList();
  }, [page]);
  const tabs = [

    { name: "All", current: true },
    { name: "Cars Allocated", current: false },
    { name: "Not Allocated", current: false },

  ];
  const BreadCrumb = {
    pagename: "Pending bookings",
    pagePath: "/sm-pending-bookings",
    pagename1: "",
    pagePath1: "",
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate('/login', { replace: true })
  }
  const BookingDetail = (bookingId) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: bookingId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        }
        if (result.status === "Success" && result.status_code === 200) {
          setBookingData(result.data);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
  };

  const BookingList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/stockone/srm/SRMBookingsGetPending",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut()
        }
        if (result.status_code == 200 && result.status == "Success") {
          setData(result.data);
        }
        else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !")
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const FilteredBooking = () => {
    if (tabType === "All") {
      const filterData = data.all_bookings;
      return filterData;
    }
    if (tabType === "Not Allocated") {
      const filterData = data.unalocated_bookings;
      return filterData;
    }
    if (tabType === "Cars Allocated") {
      const filterData = data.alocated_bookings;
      return filterData;
    }
  };

  const FilteredBookingCount = () => {
    if (tabType === "All") {
      return data.all_bookings_count;
    }
    if (tabType === "Not Allocated") {
      return data.unalocated_bookings_count;
    }
    if (tabType === "Cars Allocated") {
      return data.alocated_bookings_count;

    }
  };

  const maxPage = Math.ceil(FilteredBookingCount() / 20);

  return (
    <SmWrapper>
      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"Pending Booking"}
        subheadingText={localStorage.getItem("Location")}

      />
      <div className={classes.mainContainer}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {open && (
          <FollowUpRemarks
            bookingData={bookingData}
            setOpen={setOpen}
            open={open}
          />
        )}
        <div className={classes.innerContainer}>
          <div className="text-end pt-2 pr-4 text-gray-500">
            {" "}
            {FilteredBooking() ? FilteredBooking().length : 0} of{" "}
            {FilteredBookingCount()}
          </div>
          {/* <div className={classes.wrapper}> */}
          <div>
            <Tab
              setTabType={setTabType}
              DataCount={data}
              type={"threeTabs"}
              tabs={tabs}
            />
            {loading ? (
              <div className={classes.loader}>
                {" "}
                <img src="assets/loadingGif.gif" alt="loading" />
              </div>
            ) : (
              <>
                {" "}
                <div className={classes.list}>
                  <div className={classes.stackListContainer}>
                    <div role="list" className={classes.stackListWrapper}>
                      <StackedList

                        data={FilteredBooking()}
                        BookingDetail={BookingDetail}
                        setOpen={setOpen}
                        type="PendingBookings"
                      />
                    </div>
                  </div>
                </div>
                {maxPage > 1 ? <Pagination maxPages={maxPage} /> : <></>}
              </>
            )}
          </div>
        </div>
      </div>
    </SmWrapper>
  );
};

export default PendingBookings;
